function browserFormatDate(date: Date): string {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const monthString = `${month}`.padStart(2, '0');
  const day = date.getDate();
  const dayString = `${day}`.padStart(2, '0');

  return `${year}-${monthString}-${dayString}`;
}

function daysAgo(days: number): Date {
  const date = new Date();

  date.setDate(date.getDate() - days);

  return date;
}

export { browserFormatDate, daysAgo };
