// @ts-strict-ignore
import uniqueId from 'lodash/uniqueId';
import { MathNode, OperatorNode, SymbolNode } from 'mathjs';

import math from 'src/config/math';
import { FORMULA_FIELD_REGEX, FORMULA_OPERATORS } from 'src/constants';
import FormulaDecorator from 'src/decorators/formula';
import {
  dependsOnLaterSteps,
  getDependencyFields,
  missingDependencyFields,
} from 'src/doc_editor/fields/helpers/formula_validator/shared_helpers';
import { isValidNumber, sanitizeNumber } from 'src/helpers/number';

const NumberFormulaValidator = {
  validate(
    formulaField: Field,
    fieldsByNumber: FieldsByNumber,
    stepsById: IndexedSteps,
  ): string[] {
    const errors = new Set<string>();
    const { content: formulaString } = formulaField;
    const formula = new FormulaDecorator(formulaString);
    const dependencyVariables = formula.getVariables();

    if (dependencyVariables.length === 0) {
      errors.add('You need to enter a formula.');
      return Array.from(errors);
    }

    const missingFields = missingDependencyFields(formulaField, fieldsByNumber);

    if (missingFields.length > 0) {
      const fieldsString = missingFields.join(', ');

      errors.add(
        `Referencing a field that is invalid or missing - ${fieldsString}`,
      );
    }

    const invalidContent =
      dependsOnFieldWithNonNumbers(formulaField, fieldsByNumber);

    if (invalidContent.length > 0) {
      const message = `must contain only numbers: ${invalidContent.join(' ')}`;

      errors.add(message);
    }

    if (dependsOnSelf(dependencyVariables, formulaField.number)) {
      errors.add('Formula may not reference itself.');
    }

    const dependencyFields = getDependencyFields(formulaField, fieldsByNumber);

    if (dependsOnInvalidFieldType(dependencyFields)) {
      errors.add('Formula depends on an unsupported field type.');
    }

    if (dependsOnLaterSteps(formulaField, fieldsByNumber, stepsById)) {
      errors.add('Formula depends on fields from later steps.');
    }

    validateDecimals(formulaField, errors);

    try {
      validateFormulaStructure(formulaField, errors);

      if (errors.size > 0) { return Array.from(errors); }

      const variableMapping = mapVariablesToExampleValues(dependencyVariables);
      const result = math.evaluate(formulaString, variableMapping);

      if (!isFinite(result)) {
        errors.add('This expression may be trying to divide by 0.');
      }
    } catch (_error) {
      errors.add('This looks like an invalid expression.');
    }

    return Array.from(errors);
  },
};

// private

function dependsOnFieldWithNonNumbers(
  formulaField: Field,
  fieldsByNumber: FieldsByNumber,
): string[] {
  const invalidContents = [];
  const dependencyFields = getDependencyFields(formulaField, fieldsByNumber);

  dependencyFields.forEach((field) => {
    if (field.type === 'StaticText') {
      if (!isValidNumber(sanitizeNumber(field.content))) {
        invalidContents.push(`F${field.number}: ${field.content}`);
      }
    }
  });
  return invalidContents;
}

function dependsOnSelf(dependencies, number: number): boolean {
  return dependencies.some((dependency) => {
    return parseInt(dependency.slice(1), 10) === number;
  });
}

function dependsOnInvalidFieldType(dependencyFields: Field[]): boolean {
  return dependencyFields.some((field) => {
    return !field.allowedInNumberFormula;
  });
}

function mapVariablesToExampleValues(variables): { [key: string]: number } {
  const formulaScope = {};

  variables.forEach((variable) => {
    formulaScope[variable] = parseInt(uniqueId(), 10) + 1049;
  });

  return formulaScope;
}

function validateDecimals(formulaField: Field, errors: Set<string>): void {
  const { content: formulaString } = formulaField;
  const periodNotFollowedByNumberRegex = /\.(\D|$)/u;
  const match = periodNotFollowedByNumberRegex.exec(formulaString);

  if (match) {
    errors.add('All periods must be followed by a number.');
  }
}

function validateFormulaStructure(formulaField: Field, errors: Set<string>): void {
  const { content: formulaString } = formulaField;
  const root = math.parse(formulaString);

  validateExplicitMultiplication(formulaString, root, errors);
  validateExpressionTree(root, errors);
}

function validateExplicitMultiplication(
  formulaString: string,
  rootNode: MathNode,
  errors: Set<string>,
): void {
  const explicitMultiplyCount = (formulaString.match(/\*/ug) || []).length;
  const allMultiplyOperators = rootNode.toString({ implicit: 'show' })
    .match(/\*/ug) || [];
  const totalMultiplyCount = allMultiplyOperators.length;
  const invalid = totalMultiplyCount > explicitMultiplyCount;

  if (invalid) {
    errors.add(
      'Expression must have operators between every variable ' +
      '(no implicit multiplication).',
    );
  }
}

function validateExpressionTree(root: MathNode, errors: Set<string>): void {
  root.traverse((node) => {
    switch (node.type) {
      case 'AssignmentNode': {
        validateAssignment(errors);
        break;
      }
      case 'OperatorNode': {
        validateOperator(node as OperatorNode, errors);
        break;
      }
      case 'SymbolNode': {
        validateSymbol(node as SymbolNode, errors);
        break;
      }
      case 'ConstantNode':
      case 'ParenthesisNode':
      // Nothing to validate, it is sufficient that these parsed successfully.
        break;
      default:
        errors.add('This looks like an invalid expression.');
    }
  });
}

function validateAssignment(errors: Set<string>): void {
  errors.add("The equals sign '=' is not supported.");
}

function validateOperator(node: OperatorNode, errors: Set<string>): void {
  const operator = node.op as string;

  if (!FORMULA_OPERATORS.includes(operator)) {
    errors.add(`The operator '${operator}' is not supported.`);
  }
}

function validateSymbol(node: SymbolNode, errors: Set<string>): void {
  const symbol = node.name;
  const match = symbol.match(FORMULA_FIELD_REGEX);
  // Validate that the match is for the exact string.
  const valid = match && symbol === match[0];

  if (!valid) {
    errors.add(`The symbol '${symbol}' does not correspond to a field.`);
  }
}

export default NumberFormulaValidator;
