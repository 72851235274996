const colors = [
  '#000000', '#72c4de', '#65759e', '#6aa556', '#0000A6', '#770FF7', '#FA48E2',
  '#FF2F80', '#9C9C9C', '#A16A93', '#63FFAC', '#B79762', '#004D43', '#8FB0FF',
  '#5A0007', '#809693', '#FEFFE6', '#1B4400', '#4FC601', '#3B5DFF', '#4A3B53',
  '#61615A', '#BA0900', '#6B7900', '#00C2A0', '#FFAA92', '#FF90C9', '#B903AA',
  '#DDEFFF', '#000035', '#7B4F4B', '#A1C299', '#300018', '#0AA6D8', '#013349',
  '#372101', '#FFB500', '#C2FFED', '#A079BF', '#CC0744', '#C0B9B2', '#C2FF99',
  '#00489C', '#6F0062', '#0CBD66', '#EEC3FF', '#456D75', '#B77B68', '#7A87A1',
  '#885578', '#FAD09F', '#FF8A9A', '#D157A0', '#BEC459', '#456648', '#0086ED',
  '#34362D', '#B4A8BD', '#D16100', '#452C2C', '#636375', '#A3C8C9', '#FF913F',
  '#575329', '#00FECF', '#B05B6F', '#8CD0FF', '#3B9700', '#04F757', '#C8A1A1',
  '#7900D7', '#A77500', '#6367A9', '#A05837', '#6B002C', '#772600', '#D790FF',
  '#549E79', '#FFF69F', '#201625', '#72418F', '#BC23FF', '#99ADC0', '#3A2465',
  '#5B4534', '#FDE8DC', '#404E55', '#0089A3', '#CB7E98', '#A4E804', '#324E72',
  '#83AB58', '#001C1E', '#D1F7CE', '#004B28', '#C8D0F6', '#A3A489', '#806C66',
  '#BF5650', '#E83000', '#66796D', '#DA007C', '#FF1A59', '#8ADBB4', '#1E0200',
  '#C895C5', '#320033', '#FF6832', '#66E1D3', '#CFCDAC', '#D0AC94', '#7ED379',
  '#A30059', '#997D87', '#012C58', '#5B4E51', '#222800', '#6A3A4C', '#922329',
  '#9B9700', '#1E6E00', '#938A81', '#886F4C', '#788D66', '#001E09', '#00846F',
  '#FFFF00', '#1CE6FF',
];

export default function pickColor(index: number): string {
  return colors[index % colors.length];
}
