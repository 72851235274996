import {
  addDependencies,
  BigNumberDependencies,
  create,
  divideDependencies,
  evaluateDependencies,
  multiplyDependencies,
  subtractDependencies,
} from 'mathjs';

// http://mathjs.org/docs/custom_bundling.html

const math = create({
  addDependencies,
  BigNumberDependencies,
  divideDependencies,
  evaluateDependencies,
  multiplyDependencies,
  subtractDependencies,
});

export default math;
