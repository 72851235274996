import React from 'react';
import ReactDOM from 'react-dom';

import ContextMenuWrapper, { Options } from 'src/doc_editor/context_menu_wrapper';
import TimeContextMenu from 'src/doc_editor/fields/components/context_menus/time';

type TimeOptions = Options & {
  interval: number | string;
};

class TimeContextMenuWrapper extends ContextMenuWrapper {
  constructor(opts: TimeOptions) {
    super(opts);

    this.updateDisplay = this.updateDisplay.bind(this);
    this.updateDisplay(opts);
  }

  updateDisplay(opts: TimeOptions): void {
    super.updateDisplay(opts);
    const { interval, number } = opts;

    ReactDOM.render(
      <React.StrictMode>
        <TimeContextMenu
          interval={interval.toString()}
          number={number}
          updateFields={this.updateFields}
        />
      </React.StrictMode>,
      this.fieldSpecificContainer,
    );
  }
}

export type { TimeOptions };
export default TimeContextMenuWrapper;
