import { Controller } from '@hotwired/stimulus';

import { assert } from 'src/helpers/assertion';
import Dialog from 'src/helpers/dialog';

class AddRemoveRowController extends Controller {
  static targets = ['rowsContainer', 'row', 'template'];

  rowsContainerTarget!: HTMLElement;
  rowTargets!: HTMLElement[];
  templateTarget!: HTMLTemplateElement;

  connect(): void {
    this.addRow = this.addRow.bind(this);
    this.removeRow = this.removeRow.bind(this);

    if (this.rowTargets.length === 0) {
      this._addRow();
    }
  }

  addRow(event: DOMEvent): void {
    event.preventDefault();
    this._addRow();
  }

  _addRow(): void {
    const template = assert(this.templateTarget.content.firstElementChild);
    const newRow = template.cloneNode(true);

    this.rowsContainerTarget.appendChild(newRow);
    if (this.rowsContainerTarget.classList.contains('sortable-list')) {
      newRow.dispatchEvent(new Event('draggableItemAdded', { bubbles: true }));
    }
  }

  removeRow(event: ActionEvent<{ type: string }>): void {
    event.preventDefault();

    const rowType = event.params.type || 'row';

    if (this.rowTargets.length === 1) {
      Dialog.alert(`Cannot delete the last ${rowType}`);
    } else {
      assert(event.currentTarget.closest('tr')).remove();
    }
  }
}

export default AddRemoveRowController;
