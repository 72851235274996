// @ts-strict-ignore
import debounce from 'lodash/debounce';

import Field from 'src/form_filler/fields/field';
import { findEl } from 'src/helpers/finders';
import { parseTime, roundAndFormatTime } from 'src/helpers/time';
import { removeAllTooltips, removeTooltip, setTooltip } from 'src/helpers/tooltip';
import valToString from 'src/helpers/val_to_string';

class TimeField extends Field {
  $requestedField: JQuery;
  debouncedStoreValue: any;

  constructor(element: HTMLElement, data) {
    super(element, data);

    if (this.element.classList.contains('locked-field')) { return; }

    this.$requestedField = $(this.requestedField);
    this.debouncedStoreValue = debounce(this.storeValue, 500);
    this.debouncedStoreValue.bind(this);
    this.sanitizeAndStoreValue = this.sanitizeAndStoreValue.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.toggleRequiredBorder();
    this.sanitizeAndStoreValue();
    this.$requestedField
      .on('blur', this.sanitizeAndStoreValue)
      .on('change', this.handleChange)
      .on('keyup', this.debouncedStoreValue);
  }

  get requestedField(): HTMLElement {
    return findEl(this.element, 'input', '.requested-field');
  }

  get interval(): number { return this.data.interval; }

  getValue(): string[] | number | string {
    return this.$requestedField.val();
  }

  toggleRequiredBorder(): void {
    const value = this.$requestedField.val();

    this.$requestedField.toggleClass('empty', !value && this.isRequired());
    if (value) {
      removeTooltip(this.$requestedField, 'Please complete this field.');
    }
  }

  handleChange(): void {
    this.toggleRequiredBorder();
    this.debouncedStoreValue();
  }

  sanitizeAndStoreValue(): void {
    const text = valToString(this.$requestedField.val());

    if (!text) {
      removeAllTooltips();
      return;
    }
    const parsedTime = parseTime(text);

    if ('error' in parsedTime) {
      removeAllTooltips();
      setTooltip(this.$requestedField, parsedTime.error);
      return;
    }
    const timeString = roundAndFormatTime({
      ...parsedTime,
      interval: this.data.interval,
    });

    this.$requestedField.val(timeString);
    removeAllTooltips();
    this.debouncedStoreValue();
  }
}

export default TimeField;
