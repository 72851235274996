// @ts-strict-ignore
import Field from 'src/form_filler/fields/field';

class CommonField extends Field {
  $element: JQuery;

  getValue(): string {
    return this.$element.text();
  }
}

export default CommonField;
