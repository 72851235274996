// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';

import { fetchGet } from 'src/helpers/fetch';
import Location from 'src/helpers/location';
import { setContext } from 'src/helpers/notify';

const MAX_FAILURES = 50;

class PdfDownloaderController extends Controller<HTMLElement> {
  $element: JQuery;
  numFailures: number;

  connect(): void {
    this.$element = $(this.element);
    this.numFailures = 0;

    this.fetchData = this.fetchData.bind(this);
    this.processResult = this.processResult.bind(this);
    this.showError = this.showError.bind(this);

    this.fetchData();
  }

  displayPdf(url: string): void {
    this.$element.find('.loading-message').addClass('hidden');
    this.$element.find('.success-message').removeClass('hidden');
    Location.navigateTo(url);
  }

  showError(): void {
    this.$element.find('.loading-message').addClass('hidden');
    this.$element.find('.error-message').removeClass('hidden');
  }

  fetchData(): void {
    const path = this.$element.data('path');

    fetchGet(path).then(this.processResult, this.showError);
  }

  processResult({ data: object }): void {
    setContext({ pdfDownloaderContext: { responseData: object } });

    let pdfAttribute = 'pdfUrl';

    if (this.element.dataset.includeAttachments === 'true') {
      pdfAttribute = 'pdfWithAttachmentsUrl';
    }

    this.numFailures += 1;

    if (object[pdfAttribute]) {
      this.displayPdf(object[pdfAttribute]);
    } else if (this.numFailures < MAX_FAILURES) {
      window.setTimeout(this.fetchData, 5000);
    } else {
      this.showError();
    }
  }
}

export default PdfDownloaderController;
