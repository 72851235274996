import React, { ReactNode } from 'react';

import ResponsesActions from 'src/responses/components/actions';
import ResponsesAppliedFiltersContainer
  from 'src/responses/containers/applied_filters';
import PageMessage from 'src/common/components/page_message';
import iconClasses from 'src/helpers/icon';

declare global {
  namespace React.JSX {
    interface IntrinsicElements {
      'turbo-frame': DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

type Props = {
  campaign: Campaign;
  headers: Responses.TableHeader[];
  pagination: Pagination;
  queryParams: ResponsesQueryParams;
};

class ResponsesIndex extends React.Component<Props, never> {
  render(): ReactNode {
    return (
      <div className='responses-content'>
        <div className='responses-main'>
          {this.responsesTable()}
        </div>
      </div>
    );
  }

  responsesTable(): React.JSX.Element {
    const { campaign, headers, pagination, queryParams } = this.props;
    const { formGroup } = queryParams;

    return (
      <div>
        <ResponsesActions
          campaign={campaign}
          formGroup={formGroup}
          headers={headers}
          pagination={pagination}
          userFormGroupDescriptions={campaign.userFormGroupDescriptions}
        />
        <ResponsesAppliedFiltersContainer campaign={campaign} headers={headers} />
        {this.loadingMessage()}
        <div
          className='responses-data'
          data-responses-index-target='responsesTable'
        >
          <turbo-frame
            data-action={this.turboFrameActionList()}
            data-responses-index-target='responsesFrame'
            id='responses-table-container'
          >
          </turbo-frame>
        </div>
      </div>
    );
  }

  loadingMessage(): React.JSX.Element | null {
    return (
      <div
        className='responses-loading'
        data-responses-index-target='responsesLoader'
      >
        <PageMessage
          icon={`${iconClasses('circleNotch')} fa-2x fa-spin`}
          title='Loading...'
        />
      </div>
    );
  }

  turboFrameActionList(): string {
    return [
      'turbo:before-fetch-request->responses-index#showLoader',
      'turbo:before-fetch-request->responses-selection#toggleFiltersEnabled',
      'turbo:frame-load->responses-index#hideLoader',
      'turbo:frame-load->responses-selection#toggleFiltersEnabled',
    ].join(' ');
  }
}

export type { Props };
export default ResponsesIndex;
