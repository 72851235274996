// @ts-strict-ignore
import { connect } from 'react-redux';

import ResponsesBulkActions from 'src/responses/components/bulk_actions';
import { canGeneratePdf } from 'src/responses/selectors';

function mapStateToProps(state: State) {
  const { responses } = state;

  return {
    canGeneratePdf: canGeneratePdf(state),
    responses,
    statusGroup: responses.meta.queryParams.filters.statusGroup,
    totalCount: responses.meta.pagination.totalCount,
    totalPages: responses.meta.pagination.totalPages,
  };
}

export default connect(mapStateToProps)(ResponsesBulkActions);
