// @ts-strict-ignore
import Field from 'src/doc_editor/field';
import $template from 'src/helpers/dollar_template';
import iconClasses from 'src/helpers/icon';

class Attachment extends Field {
  $preview(): JQuery {
    const $previewElement = $template(
      'attachment-field-preview-template',
      { required: this.model.get('required') },
    );

    $previewElement.css({
      height: this.$element.height,
      left: this.$element.position().left,
      top: this.$element.position().top,
      width: this.$element.width,
    });

    const downloadIconClasses = iconClasses('arrowUpFromBracket');
    const label = this.model.get('label');
    const previewHtml = `<i class="${downloadIconClasses}"></i> ${label}`;
    $previewElement.find('.text-input').html(previewHtml);

    return $previewElement;
  }
}

export default Attachment;
