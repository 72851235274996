// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';

class SearchController extends Controller<HTMLFormElement> {
  timeout: ReturnType<typeof setTimeout>;

  search(): void {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => { this.element.requestSubmit(); }, 500);
  }
}

export default SearchController;
