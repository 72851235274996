// @ts-strict-ignore
import bindAll from 'lodash/bindAll';
import pick from 'lodash/pick';
import React from 'react';
import ReactDOM from 'react-dom';

import 'src/extensions/jquery-ui/draggable';
import DocEditorSingleStore, {
  BoundAllFieldsLoaded,
  BoundUpdateDocEditor,
  getActiveNumbers,
} from 'src/chux/doc_editor/single_store';
import { BoundGetFields, BoundRemoveFields, BoundUpdateFields }
  from 'src/chux/editable_fields/store';
import ContextMenuWrapper from 'src/doc_editor/context_menu_wrapper';
import { SetActiveFields } from 'src/doc_editor/doc';
import Field from 'src/doc_editor/fields/components/field';
import FieldConfigs, { FieldConfig } from 'src/doc_editor/fields/config/defaults';
import ContextMenus from 'src/doc_editor/fields/context_menus/index';
import autoLabel from 'src/doc_editor/fields/helpers/auto_label';
import fontSizeCss from 'src/doc_editor/fields/helpers/font_size_css';
import { fieldHeight, fieldWidth } from 'src/doc_editor/fields/helpers/sizing';
import validateField from 'src/doc_editor/fields/helpers/validate_field';
import FieldModel from 'src/doc_editor/fields/models/field';
import { fieldFormats } from 'src/fields/config/formats';
import { assert } from 'src/helpers/assertion';
import $template from 'src/helpers/dollar_template';
import Feature from 'src/helpers/feature';
import { events, publish } from 'src/helpers/pub_sub';

type Coordinates = { xPos: number; yPos: number };

type Options = {
  allFieldsLoaded?: BoundAllFieldsLoaded;
  dataSource?: DataSource | null;
  getFields?: BoundGetFields;
  initCallbacks: boolean;
  model: FieldModel;
  removeFields?: BoundRemoveFields;
  setActiveFields?: SetActiveFields;
  steps: Step[] | null;
  store: typeof DocEditorSingleStore;
  updateDocEditor?: BoundUpdateDocEditor;
  updateFields?: BoundUpdateFields;
};

type PageData = {
  pageNum: number;
  pageWidth: number;
  pageHeight: number;
};

type Position = { left: number; top: number };

type RenderData = {
  $parent: JQuery;
  allowEdits: boolean;
  allowedInNumberFormula: boolean;
  config: FieldConfig;
  content: string;
  dataSourceColumn: string;
  editableBySpecialApprover: boolean;
  hasFieldRule: boolean;
  label: string;
  mode?: FieldMode;
  number: number;
  selected: boolean;
  stepId: number;
  stepSeq: number | null;
  type: FieldType;
  width: number;
  xPos: number;
  yPos: number;
};

const SILENT_ATTRIBUTES = new Set([
  'formatLocked',
  'pageNum',
  'pageWidth',
  'pageHeight',
  'selected',
]);

abstract class FieldComponent {
  $element: JQuery;
  element: HTMLElement;
  model: FieldModel;
  updateDocEditor: BoundUpdateDocEditor;
  updateFields: BoundUpdateFields;
  removeFields: BoundRemoveFields;
  steps: Step[] | null;
  dataSource: DataSource;
  allFieldsLoaded: BoundAllFieldsLoaded;
  getFields: BoundGetFields;
  setActiveFields: SetActiveFields;
  store: typeof DocEditorSingleStore;
  contextMenu: ContextMenuWrapper;

  constructor(opts: Options) {
    bindAll(
      this,
      'setContent',
      'dragStop',
      'dragStart',
      'showContextMenu',
      'updatePosition',
      'updatePositionAfterMove',
      'remove',
      'render',
      'setActiveField',
      'unsetActiveField',
      'refreshContextMenu',
      'hide',
      'show',
      'dragRevert',
      'updateFieldSize',
    );

    this.model = opts.model;
    this.updateDocEditor = opts.updateDocEditor;
    this.allFieldsLoaded = opts.allFieldsLoaded;
    this.updateFields = opts.updateFields;
    this.removeFields = opts.removeFields;
    this.getFields = opts.getFields;
    this.setActiveFields = opts.setActiveFields;
    this.steps = opts.steps;
    this.dataSource = opts.dataSource;
    this.store = opts.store;
    this.$element = $template('field-template', { type: this.model.get('type') });
    this.element = this.$element.get(0);
    this.$element.data('field', this);

    this.render({
      ...this.model.toJSON(),
      dataSource: this.dataSource,
      steps: this.steps,
      store: this.store,
    });

    if (!opts.initCallbacks) { return; }

    this.initView({ fontSize: this.model.get('fontSize') });

    const contextMenuTriggers = '.field-container, .field-badge';

    this.$element.find(contextMenuTriggers).on('click', this.showContextMenu);

    if (Feature.isActive('doc_editor/hotkeys')) {
      const setActiveTriggers = '.field-container, .field-badge, .draggable-tab';

      this.$element.find(setActiveTriggers).on('click', this.setActiveField);
    }

    this.model.on('remove', this.remove)
      .on('change:format change:fontSize', this.updateFieldSize)
      .on('change:xPos change:yPos', this.updatePositionAfterMove)
      .on('change', () => {
        const changedAttributes = Object.keys(this.model.changedAttributes());
        const isPublishableChange = changedAttributes.some((attribute) => {
          return !SILENT_ATTRIBUTES.has(attribute);
        });

        if (isPublishableChange) { publish(events.FIELD_CHANGED); }

        this.rerender();
      });

    publish(events.FIELD_ADDED, this);
  }

  hide(): void {
    this.$element.hide();
  }

  show(): void {
    this.$element.show();
  }

  remove(): void {
    this.$element.tooltip('hide');
    publish(events.FIELD_REMOVED, this);
    this.$element.remove();
    this.unsetActiveField();
  }

  initContextMenu(): void {
    const viewAttrs = this.model.toJSON();
    const contextMenu = new ContextMenus[viewAttrs.type]({
      allFieldsLoaded: this.allFieldsLoaded,
      dataSource: this.dataSource,
      getFields: this.getFields,
      updateDocEditor: this.updateDocEditor,
      updateFields: this.updateFields,
      ...viewAttrs,
      errors: validateField(viewAttrs),
      label: viewAttrs.label || autoLabel(this.model),
      setContent: this.setContent,
      steps: this.steps,
    });

    this.contextMenu = contextMenu;
  }

  refreshContextMenu(): void {
    const { type } = this.model.toJSON();

    if (!this.contextMenu) { return; }

    if (FieldConfigs[type].stepSelectable) {
      this.contextMenu.renderStepSelector();
    }

    if (FieldConfigs[type].prefillable) {
      this.contextMenu.renderPrefill();
    }
  }

  showContextMenu(): void {
    if (this.model.get('dragging')) { return; }
    if (!this.contextMenu) { this.initContextMenu(); }

    const data = { ...this.model.toJSON(), $parent: this.$element.parent() };

    this.contextMenu.render(data);
  }

  setContent(content: string): void {
    if (this.model.get('type') === 'Prefill' && content) {
      this.model.set({ content, label: content });
    } else {
      this.model.set({ content });
    }
  }

  initView({ fontSize }: { fontSize: number }): void {
    this.updatePosition();
    this.makeDraggable();
    this.setFontSize(fontSize);
  }

  updatePositionAfterMove(): void {
    this.updatePosition();
    publish(events.FIELD_MOVED, this);
  }

  updatePosition(): void {
    this.$element.css(this.position());
  }

  position(): Position {
    return { left: this.model.get('xPos'), top: this.model.get('yPos') };
  }

  shouldShowContextMenuOnInit(): boolean { return false; }

  previewOffset(): Coordinate {
    const width = assert(this.$element.width());
    const height = assert(this.$element.height());

    return { x: width / 2, y: height / 2 };
  }

  makeDraggable(): void {
    // draggable assigns relative position if $element is not yet in DOM
    this.$element.css('position', 'absolute');

    this.$element.draggable({
      cancel: '.text-input,input,textarea,.remove-icon',
      revert: this.dragRevert,
      start: this.dragStart,
      stop: this.dragStop,
    });
  }

  dragStop(): void {
    this.model.set('dragging', false);
  }

  dragStart(): void {
    this.model.set('dragging', true);
    publish(events.FIELD_DRAG_STARTED, this);
  }

  dragRevert(droppable: HTMLElement | null): boolean {
    if (!droppable) {
      publish(events.FIELD_DRAG_REVERTED, this);
      return true;
    }

    return false;
  }

  focus(): void { /* optionally overridden in sub-class */ }

  setFontSize(val: number): void {
    this.model.set('fontSize', val);
    this.$element.find('.adjustable-font').css(fontSizeCss(val));
  }

  setPageData(data: PageData): void {
    this.model.set(data);
  }

  setCoordinates(coords: Partial<Coordinates>): void {
    this.model.set(coords);
  }

  setFieldSize({ height, width }: { height: number; width: number }): void {
    this.model.set({ height, width });
  }

  serialize(): SerializedField {
    const modelAttrs = this.modelAttrs();

    if (modelAttrs.type === 'StaticText') {
      modelAttrs.content = modelAttrs.content.replace(/\n/ug, '<br>');
    }

    const result = {
      ...modelAttrs,
      coorX: (this.model.get('xPos') / this.model.get('pageWidth')) * 100,
      coorY: (this.model.get('yPos') / this.model.get('pageHeight')) * 100,
      label: this.model.get('label') || autoLabel(this.model),
    };

    if (modelAttrs.dropdownId) {
      result.content = '';
    }

    return result;
  }

  modelAttrs(): FieldModelAttrs {
    return pick(this.model.toJSON(), [
      'type', 'fontSize', 'width', 'height', 'content', 'stepId', 'required',
      'pageNum', 'link', 'number', 'allowEdits',
      'editableBySpecialApprover', 'format', 'referencePrefix',
      'startingReferenceNumber', 'sortDropdownChoices', 'showSecurityMetadata',
      'mode', 'precision', 'interval', 'dropdownId', 'dataSourceColumn',
    ]);
  }

  render(opts: RenderData): void {
    const { steps } = this;
    const step = steps && steps.find((campaignStep) => {
      return campaignStep.id === opts.stepId;
    });

    opts.stepSeq = step ? step.seq : null;
    if (this.contextMenu) { this.contextMenu.updateDisplay(opts); }

    ReactDOM.render(
      <React.StrictMode>
        <Field
          {...opts}
          removeFields={this.removeFields}
          store={this.store}
          updateFields={this.updateFields}
        />
      </React.StrictMode>,
      this.element,
    );
  }

  rerender(): void {
    const changedViewAttrs = this.model.toJSON();

    this.render({
      ...changedViewAttrs,
      errors: validateField(changedViewAttrs),
    });
  }

  setActiveField(): void {
    this.setActiveFields(new Set([this.model.get('number')]));
  }

  unsetActiveField(): void {
    const number = this.model.get('number');

    if (getActiveNumbers().includes(number)) {
      const activeNumbers = new Set(getActiveNumbers());

      activeNumbers.delete(number);

      this.setActiveFields(activeNumbers);
    }
  }

  getNumber(): number {
    return this.model.get('number');
  }

  getPageNum(): number {
    return this.model.get('pageNum');
  }

  getType(): FieldType {
    return this.model.get('type');
  }

  getMinWidth(): number {
    return this.model.get('minWidth');
  }

  getMinHeight(): number {
    return this.model.get('minHeight');
  }

  isValid(): boolean {
    return this.model.isValid();
  }

  updateFieldSize(): void {
    if (!this.model.has('format')) { return; }

    const formatField = fieldFormats[this.model.get('format')];

    if (!(formatField && formatField.fixedLength())) { return; }

    const fontSize = this.model.get('fontSize');
    const newWidth = fieldWidth(fontSize, formatField.validExample);
    const newHeight = fieldHeight(fontSize);

    this.model.set('width', newWidth);
    this.model.set('height', newHeight);
  }

  abstract $preview(): JQuery;
}

type SetContent = FieldComponent['setContent'];
export type { Coordinates, Options, SetContent };

export default FieldComponent;
