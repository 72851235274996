import { Controller } from '@hotwired/stimulus';

import { supportEmail } from 'src/constants';
import { assert } from 'src/helpers/assertion';
import { fetchGet } from 'src/helpers/fetch';
import { findEl } from 'src/helpers/finders';
import Location from 'src/helpers/location';
import notify from 'src/helpers/notify';
import { hide } from 'src/helpers/visibility';

class GroupLoaderController extends Controller {
  connect(): void {
    //  1) get group ID
    const element = findEl(document.body, 'div', '.group-ajax-loader');
    const groupId = assert(element.id).replace('group_', '');

    //  2) ask server if the group is ready
    let triesSoFar = 0;
    const triesUntilFail = 35;

    const intervalId = window.setInterval(() => {
      const url = `/groups/status?id=${groupId}`;

      fetchGet(url).then(({ data: group }) => {
        triesSoFar += 1;
        if (!group.isProcessing) {
          Location.reload();
          window.clearInterval(intervalId);
        } else if (triesSoFar === triesUntilFail) {
          const html = [
            '<h4>Your group file is taking longer than usual to upload. ',
            'This may be because your file size is very large.<br/>',
            '<strong>Please wait a few minutes and ',
            'refresh the page.</strong><br/><br/>',
            'If you encounter this message again after refreshing, there may ',
            'have been an error processing your file.<br/>',
            `Then please contact <strong>${supportEmail}</strong> `,
            'for assistance.</h4>',
          ].join('');

          hide(findEl(element, 'img'));

          const alertElement = findEl(document.body, 'div', '.alert-info');

          alertElement.classList.remove('alert-info');
          alertElement.classList.add('alert-danger');
          alertElement.innerHTML = html;
        }
      }, notify);
    }, 1000);
  }
}

export default GroupLoaderController;
