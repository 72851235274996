// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';

import { onClick } from 'src/helpers/event';
import { fetchDelete } from 'src/helpers/fetch';
import { findEl } from 'src/helpers/finders';

class GroupDeleterController extends Controller<HTMLElement> {
  groupElementToDelete: HTMLElement;

  connect(): void {
    const deleteModal = findEl(this.element, 'div', '#group-delete-modal');

    onClick(this.element, '.delete-group', this.setGroupToDelete.bind(this));
    onClick(
      findEl(deleteModal, 'button', '.wb-button--primary'),
      this.deleteGroup.bind(this),
    );
  }

  setGroupToDelete(event: DOMEvent): void {
    this.groupElementToDelete = event.target.closest('.group-select');
  }

  deleteGroup(): void {
    const { groupId } = this.groupElementToDelete.dataset;
    const url = `/groups/${groupId}`;

    removeDataAndRow(url, this.groupElementToDelete);
  }
}

// private

function removeDataAndRow(url: string, elementToDelete: HTMLElement): void {
  fetchDelete(url).then(() => { elementToDelete.remove(); });
}

export default GroupDeleterController;
