import Honeybadger from 'src/config/honeybadger';
import { TrackJS } from 'src/config/trackjs';

let callCount = 0;

type Context = { [key: string]: unknown };

function notify(error: Error | string, context?: Context): void {
  callCount += 1;

  Honeybadger.notify(error, { context: { ...context, callCount } });
  TrackJS.console.debug({ ...context, callCount });
  TrackJS.track(error);
}

function setContext(context: { [key: string]: unknown }): void {
  Honeybadger.setContext(context);
  TrackJS.console.debug(context);
}

function resetCount(): void {
  callCount = 0;
}

export { resetCount, setContext };
export default notify;
