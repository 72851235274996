// @ts-strict-ignore
import FieldModel from 'src/doc_editor/fields/models/field';

const DEFAULTS = {
  content: 'X',
  required: false,
};

class Checkbox extends FieldModel {
  constructor(opts) {
    super({ ...DEFAULTS, ...opts });
  }
}

export default Checkbox;
