import { isValidNumber } from 'src/helpers/number';

class HotKey {
  key: string;
  shiftKey: boolean;

  constructor(event: KeyboardEvent) {
    this.key = event.key;
    this.shiftKey = event.shiftKey;
  }

  isShiftActive(): boolean {
    return this.shiftKey;
  }

  isNumber(): boolean {
    return isValidNumber(this.key);
  }

  isKey(key: string): boolean {
    return this.key === key;
  }

  isBackspace(): boolean {
    return this.key === 'Backspace';
  }

  isArrow(): boolean {
    return this.isLeftArrow() ||
      this.isRightArrow() ||
      this.isUpArrow() ||
      this.isDownArrow();
  }

  isLeftArrow(): boolean {
    return this.key === 'ArrowLeft';
  }

  isRightArrow(): boolean {
    return this.key === 'ArrowRight';
  }

  isUpArrow(): boolean {
    return this.key === 'ArrowUp';
  }

  isDownArrow(): boolean {
    return this.key === 'ArrowDown';
  }
}

export default HotKey;
