import React from 'react';

import Icon from 'src/common/components/icon';
import RailsForm from 'src/common/components/rails_form';

type Props = {
  formId: number;
  linkText: string;
  messageText: string;
  onClick?: Callback;
};

function ActionableMessage(props: Props): React.JSX.Element {
  const { messageText } = props;

  let actionElement: React.JSX.Element | null;
  if (messageText === 'Not delivered') {
    actionElement = generateSendNowForm(props);
  } else {
    actionElement = generateOnclickButton(props);
  }

  return (
    <div className='wb-l-media'>
      <div className='wb-l-media__figure wb-u-margin-t-0 wb-u-margin-r-1'>
        <Icon name='exclamationCircle' />
      </div>
      <div className='wb-l-media__body text-danger'>
        {messageText}
        <br />
        {actionElement}
      </div>
    </div>
  );
}

// private

function generateSendNowForm(props: Props): React.JSX.Element {
  const { formId, linkText } = props;
  const url = `/forms/${formId}/deliveries`;

  return (
    <RailsForm action={url} method='POST'>
      <button
        className='link-text underline text-left'
        type='submit'
      >
        {linkText}
      </button>
    </RailsForm>
  );
}

function generateOnclickButton(props: Props): React.JSX.Element | null {
  const { onClick, linkText } = props;

  if (!onClick) { return null; }

  return (
    <button
      className='link-text underline text-left'
      type='button'
      onClick={(event): void => {
        event.stopPropagation();
        onClick();
      }}
    >
      {linkText}
    </button>
  );
}

export default ActionableMessage;
export type { Props };
