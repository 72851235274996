import { applyMiddleware, createStore } from 'redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';

import campaignReducer from 'src/campaign/reducer';
import createMergedReducer from 'src/common/merged_reducer';
import feedbacksReducer from 'src/feedbacks/reducer';
import flashesReducer from 'src/flashes/reducer';
import recipientsReducer from 'src/recipients/reducer';
import responsesReducer from 'src/responses/reducer';
import stepsReducer from 'src/steps/reducer';

const reducer = createMergedReducer({
  campaign: campaignReducer,
  feedbacks: feedbacksReducer,
  flashes: flashesReducer,
  recipients: recipientsReducer,
  responses: responsesReducer,
  steps: stepsReducer,
});

// try to remove 'as ThunkMiddleware' after upgrading to TS3
export default createStore(reducer, applyMiddleware(thunk as ThunkMiddleware));
