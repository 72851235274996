// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';

import { triggerEvent } from 'src/helpers/event';

class SortDropdownChoicesToggleController extends Controller {
  static targets = ['form', 'hidden', 'select'];

  formTarget: HTMLElement;
  hiddenTarget: HTMLInputElement;
  selectTarget: HTMLSelectElement;

  toggle(): void {
    this.selectTarget.disabled = true;
    const isAlphaOrdered =
      this.selectTarget.options[this.selectTarget.selectedIndex].value;

    this.hiddenTarget.value = isAlphaOrdered;

    triggerEvent(this.formTarget, 'submit');
  }
}

export default SortDropdownChoicesToggleController;
