import React from 'react';

import Icon from 'src/common/components/icon';

function FieldRuleLabel(): React.JSX.Element {
  const linkEl = document.getElementById('field_logic_path') as
    HTMLAnchorElement | null;
  const href = linkEl ? linkEl.href : '#';

  return (
    <div className='field-rule-label context-menu-steps wb-l-split'>
      <Icon name='shareAlt' />
      <div>
        <div>This field has field logic.</div>
        <a className='wb-link--muted' href={href}>View logic.</a>
      </div>
    </div>
  );
}

export default FieldRuleLabel;
