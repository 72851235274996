import classNames from 'classnames';
import React from 'react';

import Icon from 'src/common/components/icon';

type Props = {
  selected: boolean;
};

function Checkbox(props: Props): React.JSX.Element {
  const { selected } = props;

  return (
    <div className={classNames('tag_checkbox', 'field-container', { selected })}>
      <Icon name='check' />
    </div>
  );
}

export default Checkbox;
export type { Props };
