import type { TemplateExecutor } from 'lodash';
import unescape from 'lodash/unescape';

import { templateWithSettings } from 'src/helpers/template';

const templates: { [key: string]: TemplateExecutor } = {};

type Opts = { [key: string]: unknown };

function $template(id: string, opts: Opts = {}): any {
  // cache template if it doesn't exist yet
  templates[id] ||= loadTemplate(id);

  return $(templates[id](opts));
}

function loadTemplate(id: string): TemplateExecutor {
  const $emptyTemplate = $(`#${id}`);

  if (!$emptyTemplate.length) {
    throw new Error(`Template <${id}> does not exist!`);
  }

  return templateWithSettings(unescape($emptyTemplate.html()));
}

export default $template;
