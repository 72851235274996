import classNames from 'classnames';
import React from 'react';

type Props = {
  selected: boolean;
};

function ReferenceNumber(props: Props): React.JSX.Element {
  const { selected } = props;

  return (
    <div className={classNames('adjustable-font', 'field-container', { selected })}>
      Reference #
    </div>
  );
}

export default ReferenceNumber;
