// @ts-strict-ignore
import bindAll from 'lodash/bindAll';

import { assert } from 'src/helpers/assertion';
import BoundingBox from 'src/helpers/bounding_box';

type ConstructorObject = {
  onSelectionChange: (BoundingBox: BoundingBox) => void;
  onFinalSelection: (BoundingBox: BoundingBox) => void;
};

class MouseSelectionTracker {
  $element: JQuery;
  element: HTMLElement;
  selectedArea: BoundingBox;
  onSelectionChange: (BoundingBox: BoundingBox) => void;
  onFinalSelection: (BoundingBox: BoundingBox) => void;
  elementOffset: { top: number; left: number };
  elementWidth: number;
  elementHeight: number;
  trackingSelection: boolean;

  constructor(
    element: HTMLElement,
    { onSelectionChange, onFinalSelection }: ConstructorObject,
  ) {
    bindAll(
      this,
      'trackMouseStartingPosition',
      'handleMousemove',
      'notifyOfFinalSelection',
      'memoizeElementPositionAttrs',
    );

    this.$element = $(element);
    this.element = element;
    this.memoizeElementPositionAttrs();
    this.selectedArea = new BoundingBox();
    this.onSelectionChange = onSelectionChange;
    this.onFinalSelection = onFinalSelection;

    this.$element.on('mousedown', this.trackMouseStartingPosition);
    $(window).on('mousemove', this.handleMousemove)
      .on('resize', this.memoizeElementPositionAttrs)
      .on('mouseup', this.notifyOfFinalSelection);
  }

  memoizeElementPositionAttrs(): void {
    const offset = assert(this.$element.offset());
    const width = assert(this.$element.width());
    const height = assert(this.$element.height());

    this.elementOffset = offset;
    this.elementWidth = width;
    this.elementHeight = height;
  }

  trackMouseStartingPosition(event: JQueryEventObject): void {
    const userClickedDirectlyOn$element = event.target === this.element;

    this.trackingSelection = userClickedDirectlyOn$element;

    const coordinate = this.buildCoordinate(event);

    this.selectedArea.setCoordinate1(coordinate);
  }

  trackCurrentMousePosition(event: JQueryEventObject): void {
    const coordinate = this.buildCoordinate(event);

    this.selectedArea.setCoordinate2(coordinate);
  }

  buildCoordinate(event: JQueryEventObject): Coordinate {
    let relativeX = event.pageX - this.elementOffset.left;

    relativeX = Math.min(relativeX, this.elementWidth);
    relativeX = Math.max(relativeX, 0);

    let relativeY = event.pageY - this.elementOffset.top;

    relativeY = Math.min(relativeY, this.elementHeight);
    relativeY = Math.max(relativeY, 0);

    return { x: relativeX, y: relativeY };
  }

  handleMousemove(event: JQueryEventObject): void {
    if (!this.trackingSelection) { return; }

    this.trackCurrentMousePosition(event);
    this.onSelectionChange(this.selectedArea);
  }

  notifyOfFinalSelection(event: JQueryEventObject): void {
    if (this.trackingSelection) {
      this.trackCurrentMousePosition(event);
      this.onFinalSelection(this.selectedArea);
    }

    this.trackingSelection = false;
  }
}

export default MouseSelectionTracker;
