import { closestEl, findEl } from 'src/helpers/finders';
import { narrow } from 'src/helpers/assertion';
import template from 'src/helpers/template';
import FormFillerAttachmentUploaderController, { UploadResult }
  from './form_filler_attachment_uploader_controller';

type RemoveFileEvent = { detail: { fieldId: number; blobId: string } };

class AttachmentFieldController extends FormFillerAttachmentUploaderController {
  static values = { fieldId: Number, fieldLabel: String };
  static targets = ['dialogButton', 'dialog'];

  fieldIdValue!: number;
  fieldLabelValue!: string;
  dialogButtonTarget!: HTMLElement;
  dialogTarget!: HTMLElement;

  get attachmentScope(): Element {
    return this.element;
  }

  private get doneButton(): HTMLButtonElement {
    return findEl(this.element, 'button', '.done-button');
  }

  private get modalOverlay(): HTMLElement {
    return findEl(this.element, 'div', '.dialog--overlay');
  }

  connect(): void {
    super.connect();
    this.dialogTarget.addEventListener('show', () => { this.updateUIElements(); });

    const observer = new MutationObserver(this.fieldVisibilityChanged);
    observer.observe(
      this.element,
      {
        attributeFilter: ['hidden'],
        attributeOldValue: true,
        attributes: true,
      },
    );
  }

  blobRowInputName(): string {
    return `form_request[attachment_fields][${this.fieldIdValue}][blob_ids][]`;
  }

  handleMirrorFileRemoved({ detail: { fieldId, blobId } }: RemoveFileEvent): void {
    if (fieldId === this.fieldIdValue) {
      const hiddenField = findEl(this.element, 'input', `[value="${blobId}"]`);
      closestEl(hiddenField, 'div', '.uploader-file-select').remove();
      this.updateFieldLabel();
    }
  }

  handleMirrorSavedFileRemoved(
    { detail: { fieldId, blobId } }: RemoveFileEvent,
  ): void {
    if (fieldId === this.fieldIdValue) {
      const hiddenField = findEl(this.element, 'input', `[value="${blobId}"]`);
      closestEl(hiddenField, 'tr', '').remove();
      this.updateFieldLabel();
    }
  }

  removeFile(event: DOMEvent): void {
    const parent = closestEl(event.currentTarget, 'div', '.uploader-file-select');
    const input = findEl(parent, 'input', `[name="${this.blobRowInputName()}"]`);
    const blobId = input.value;
    this.dispatch(
      'fieldFileRemoved',
      { detail: { blobId, fieldId: this.fieldIdValue } },
    );

    super.removeFile(event);
  }

  removeSavedFile(event: DOMEvent): void {
    const row = closestEl(event.currentTarget, 'tr', '');
    const input = findEl(row, 'input', `[name="${this.blobRowInputName()}"]`);
    const blobId = input.value;
    this.dispatch(
      'savedFieldFileRemoved',
      { detail: { blobId, fieldId: this.fieldIdValue } },
    );

    row.remove();
    this.updateUIElements();
  }

  fileAddStarted(): void {
    super.fileAddStarted();

    this.doneButton.disabled = true;
    this.doneButton.innerText = 'Uploading...';

    delete this.modalOverlay.dataset.a11yDialogHide;
  }

  appendBlobRows($fileSelect: JQuery, successfulUploads: UploadResult[]): void {
    super.appendBlobRows($fileSelect, successfulUploads);
    const blobRowsTemplate = template(
      'blob-mirror-template',
      {
        fieldId: this.fieldIdValue,
        fieldLabel: this.fieldLabelValue,
        successfulUploads,
      },
    );
    const containerSelector = `#field-${this.fieldIdValue}-attachments-mirror`;
    const container = findEl(document, 'div', containerSelector);
    container.innerHTML += blobRowsTemplate;
  }

  fileAddEnded(): void {
    super.fileAddEnded();

    this.doneButton.disabled = false;
    this.doneButton.innerText = 'Done';

    this.modalOverlay.dataset.a11yDialogHide = 'true';
  }

  updateUIElements(): void {
    this.updateFieldLabel();
    super.updateUIElements();
  }

  private updateFieldLabel(): void {
    const count = this.numFiles();
    const iconElement = findEl(this.dialogButtonTarget, 'i', '');
    const countElement =
      findEl(this.dialogButtonTarget, 'span', '.attachment-field-count');

    if (count > 0) {
      iconElement.classList.replace('fa-arrow-up-from-bracket', 'fa-paperclip');
      countElement.textContent = count.toString();
      this.dialogButtonTarget.classList.toggle('empty', false);
    } else {
      iconElement.classList.replace('fa-paperclip', 'fa-arrow-up-from-bracket');
      this.dialogButtonTarget.classList.toggle('empty', true);
      countElement.textContent = '';
    }
  }

  private fieldVisibilityChanged(mutationList: MutationRecord[]): void {
    mutationList.forEach((mutation) => {
      if (mutation.type === 'attributes') {
        const target = narrow(mutation.target, Element);
        const targetId = target.getAttribute('data-field-id');
        const hiddenValue = target.getAttribute('hidden');

        if (hiddenValue !== mutation.oldValue) {
          const containerSelector = `#field-${targetId}-attachments-mirror`;
          const container = findEl(document, 'div', containerSelector);
          container.hidden = hiddenValue === '';
        }
      }
    });
  }
}

export default AttachmentFieldController;
export type { RemoveFileEvent };
