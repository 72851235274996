// @ts-strict-ignore
import update from 'immutability-helper';

import createBasicReducer from 'src/common/basic_reducer';

const operations = {
  '@@INIT': initStore,
  'feedbacks/SET': setFeedbacks,
  'feedbacks/UPDATE_META': updateMeta,
};

function initStore() {
  return { byId: {}, meta: {}, orderedIds: [] };
}

function setFeedbacks(previousState, { byId, orderedIds }) {
  return { byId, meta: previousState.meta, orderedIds };
}

function updateMeta(previousState, meta) {
  return update(previousState, { meta: { $merge: meta } });
}

export default createBasicReducer(operations);
