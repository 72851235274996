// @ts-strict-ignore
import 'src/extensions/jquery-ui/resizable';

import Field from 'src/doc_editor/field';
import isFieldResizable from 'src/doc_editor/helpers/is_field_resizable';
import { assert } from 'src/helpers/assertion';
import $template from 'src/helpers/dollar_template';
import sanitizeInput from 'src/helpers/sanitize_input';

class Input extends Field {
  config = {
    dragToleranceX: 0,
    dragToleranceY: 20,
    stampPreviewXOffset: -8,
    stampPreviewYOffset: -5,
  };

  $element: JQuery;

  constructor(opts) {
    super(opts);

    this.setResizable = this.setResizable.bind(this);

    if (!opts.initCallbacks) { return; }

    this.setResizable();
    sanitizeInput(this.$element.find('.text-input'));
    this.model.on('change:format', this.setResizable);
  }

  get $textInput(): JQuery {
    return this.$element.find('.text-input');
  }

  $preview(): JQuery {
    const $previewElement = $template(
      'input-field-preview-template',
      { required: this.model.get('required') },
    );

    const $input = $previewElement.find('.text-input');

    $previewElement.css(this.$element.position());
    $input.css({
      height: this.$element.find('.requested-field').height(),
      width: this.$element.find('.requested-field').width(),
    }).html(this.$element.find('.text-input').html());

    return $previewElement;
  }

  previewOffset(): Coordinate {
    return {
      x: this.$element.outerWidth() + this.config.stampPreviewXOffset,
      y: this.$element.outerHeight() + this.config.stampPreviewYOffset,
    };
  }

  setResizable(): void {
    if (!isFieldResizable(this.model.toJSON())) {
      if (this.$element.resizable('instance')) {
        this.$element.resizable('destroy').css({ height: '', width: '' });
      }
      return;
    }

    this.$element.resizable({
      alsoResize: this.$element.find('.text-input'),
      minHeight: this.getMinHeight(),
      minWidth: this.getMinWidth(),
    });
    this.$element.on('resizestop', () => {
      this.element.style.removeProperty('width');
      this.element.style.removeProperty('height');

      const outerWidth = assert(this.$textInput.outerWidth());
      const outerHeight = assert(this.$textInput.outerHeight());

      this.setFieldSize({ height: outerHeight, width: outerWidth + 2.0 });
    });
  }
}

export default Input;
