import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

import Location from 'src/helpers/location';

class EventsController extends Controller {
  connect(): void {
    if (Location.isLocalFile()) {
      Location.navigateTo('https://www.informedk12.com');
    } else {
      window.addEventListener('load', () => {
        Rails.start();
      });
    }
  }

  blurNumberInput(): void {
    // https://stackoverflow.com/questions/9712295/disable-scrolling-on-input-type-number
    const element = document.activeElement;

    if (!(element instanceof HTMLInputElement)) { return; }
    if (element.type !== 'number') { return; }

    element.blur();
  }

  stopPropagation(event: Event): void {
    event.stopPropagation();
  }

  preventDefault(event: Event): void {
    event.preventDefault();
  }
}

export default EventsController;
