// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';

import { hide, show } from 'src/helpers/visibility';

class CheckboxDropdown extends Controller {
  static targets = [
    'checkbox',
    'checkboxAll',
    'error',
    'selectionText',
    'summary',
  ];

  defaultSelectText: string;
  hasError: boolean;

  checkboxAllTarget: HTMLInputElement;
  checkboxTargets: HTMLInputElement[];
  errorTarget: HTMLElement;
  selectionTextTarget: HTMLElement;
  summaryTarget: HTMLElement;

  get allSelectedText(): string {
    return this.checkboxAllTarget.parentNode.textContent.trim();
  }

  get selectionText(): string {
    return this.selectionTextTarget.textContent.trim();
  }

  set selectionText(selectionText: string) {
    this.selectionTextTarget.textContent = selectionText;
  }

  connect(): void {
    this.updateSummaryText = this.updateSummaryText.bind(this);
    this.updateRequiredText = this.updateRequiredText.bind(this);
    this.setErrorState = this.setErrorState.bind(this);
    this.clearErrorState = this.clearErrorState.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.toggleAllCheckboxes = this.toggleAllCheckboxes.bind(this);
    this.toggleCheckbox = this.toggleCheckbox.bind(this);

    this.defaultSelectText = this.selectionText;

    this.updateSummaryText();

    this.addEventListeners();
  }

  addEventListeners(): void {
    const form = this.element.closest('form');

    if (form !== null) {
      form.addEventListener('submit', this.validateForm);
    }
  }

  toggleAllCheckboxes(): void {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = this.checkboxAllTarget.checked;
    });
    this.updateSummaryText();
    this.updateRequiredText();
  }

  toggleCheckbox(): void {
    this.checkboxAllTarget.checked = this.areAllChecked();

    this.updateSummaryText();
    this.updateRequiredText();
  }

  updateSummaryText(): void {
    if (this.checkboxAllTarget.checked) {
      this.selectionText = this.allSelectedText;
    } else if (this.areNoneChecked()) {
      this.selectionText = this.defaultSelectText;
    } else {
      const selectedText = this.checkedCheckboxes().map((checkbox) => {
        return checkbox.parentNode.textContent.trim();
      });

      this.selectionText = selectedText.join(', ');
    }
  }

  updateRequiredText(): void {
    this.areNoneChecked() ? this.setErrorState() : this.clearErrorState();
  }

  validateForm(event: Event): void {
    if (this.areNoneChecked()) {
      event.stopPropagation(); // prevents button from becoming disabled.
      event.preventDefault();
      this.setErrorState();
    }
  }

  clearErrorState(): void {
    if (this.hasError) {
      this.summaryTarget.classList.remove('wb-field--error');
      hide(this.errorTarget);
      this.hasError = false;
    }
  }

  setErrorState(): void {
    if (!this.hasError) {
      this.summaryTarget.classList.add('wb-field--error');
      show(this.errorTarget);
      this.hasError = true;
    }
  }

  checkedCheckboxes(): HTMLInputElement[] {
    return this.checkboxTargets.filter((checkbox) => { return checkbox.checked; });
  }

  checkedCount(): number {
    return this.checkedCheckboxes().length;
  }

  areAllChecked(): boolean {
    return this.checkedCount() === this.checkboxTargets.length;
  }

  areNoneChecked(): boolean {
    return this.checkedCount() === 0;
  }
}

export default CheckboxDropdown;
