// @ts-strict-ignore

function warningMessage(
  isSubsetField: boolean,
  isTriggerField: boolean,
  isFilterField: boolean,
): string {
  let warning = '';

  if (isSubsetField) {
    warning += 'CAREFUL! If you remove this field, users will never be ' +
    'able to access the forms that have been completed with the choices ' +
    'listed in the dropdown. \n\n';
  }
  if (isTriggerField) {
    warning += 'CAREFUL! This field is used in field logic and removing ' +
    'it would affect what fields people can fill out. \n\n';
  }
  if (isFilterField) {
    warning += 'CAREFUL! This field is used by others to filter submissions ' +
    'on the Responses \n\n';
  }

  if (warning) {
    warning += 'Are you sure you want to delete this field?';
  }

  return warning;
}

export default warningMessage;


