// @ts-strict-ignore
import React, { ReactNode } from 'react';

import checkFileHelper from 'src/helpers/check_file';
import Dialog from 'src/helpers/dialog';

type Props = {
  confirmation: string;
  id: string;
  submitText: string;
  validExtensions: string[];
};

type State = {
  disabled: boolean;
};

class ValidatedFileInput extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = { disabled: true };
  }

  render(): ReactNode {
    const { id, submitText, confirmation } = this.props;
    const { disabled } = this.state;

    return (
      <div>
        <input
          aria-label='Choose file'
          id={id}
          name={id}
          type='file'
          onChange={this.handleChange}
        />
        <br />
        <input
          className='wb-button wb-button--primary'
          data-confirm={confirmation || ''}
          disabled={disabled}
          name='commit'
          type='submit'
          value={submitText || 'Submit'}
        />
      </div>
    );
  }

  handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const validFile = this.fileValid(event.target.value);

    this.setState({ disabled: !validFile });

    if (validFile) { return; }

    const formatsString = this.props.validExtensions.join(', ');

    Dialog.alert(`Invalid file. Accepted formats are ${formatsString}`);
  }

  fileValid(filename: string): boolean {
    return checkFileHelper(this.props.validExtensions, filename);
  }
}

export default ValidatedFileInput;
export type { Props };
