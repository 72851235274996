import FormulaDecorator from 'src/decorators/formula';

function dependsOnLaterSteps(
  formulaField: Field,
  fieldsByNumber: FieldsByNumber,
  stepsById: IndexedSteps,
): boolean {
  if (!formulaField.stepId) { return false; }

  const formulaStep = stepsById[formulaField.stepId];

  if (formulaStep === undefined) { return false; }

  const formula = new FormulaDecorator(formulaField.content);
  const dependencyNumbers = formula.getFieldNumbers();

  return dependencyNumbers.some((number) => {
    const otherField = fieldsByNumber[number];

    if (!otherField || !otherField.stepId) { return false; }

    const dependencyStep = stepsById[otherField.stepId];

    if (dependencyStep === undefined) { return false; }

    return dependencyStep.seq > formulaStep.seq;
  });
}

function getDependencyFields(
  formulaField: Field,
  fieldsByNumber: FieldsByNumber,
): Field[] {
  const formula = new FormulaDecorator(formulaField.content);
  const dependencyFields: Field[] = [];

  formula.getFieldNumbers().forEach((dependencyNumber) => {
    const field = fieldsByNumber[dependencyNumber];

    if (field) { dependencyFields.push(field); }
  });

  return dependencyFields;
}

function missingDependencyFields(
  formulaField: Field,
  fieldsByNumber: FieldsByNumber,
): string[] {
  const formula = new FormulaDecorator(formulaField.content);

  return formula.getFieldNumbers()
    .filter((number) => { return !fieldsByNumber[number]; })
    .map((number) => { return `F${number}`; });
}

export { dependsOnLaterSteps, getDependencyFields, missingDependencyFields };
