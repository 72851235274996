import Attachment from 'src/doc_editor/fields/models/attachment';
import Checkbox from 'src/doc_editor/fields/models/checkbox';
import Date from 'src/doc_editor/fields/models/date';
import Dropdown from 'src/doc_editor/fields/models/dropdown';
import Formula from 'src/doc_editor/fields/models/formula';
import Input from 'src/doc_editor/fields/models/input';
import Prefill from 'src/doc_editor/fields/models/prefill';
import ReferenceNumber from 'src/doc_editor/fields/models/reference_number';
import Signature from 'src/doc_editor/fields/models/signature';
import StaticText from 'src/doc_editor/fields/models/static_text';
import Time from 'src/doc_editor/fields/models/time';

const Models = {
  Attachment,
  Checkbox,
  Date,
  Dropdown,
  Formula,
  Input,
  Prefill,
  ReferenceNumber,
  Signature,
  StaticText,
  Time,
};

export default Models;
