import React, { ReactNode } from 'react';

type Props = {
  title: string;
  icon?: string;
  subtitle?: ReactNode;
};

function PageMessage(props: Props): React.JSX.Element {
  const { title, icon, subtitle } = props;

  return (
    <div className='page-message text-center'>
      {
        icon &&
        <div aria-hidden='true' className='page-message__icon'>
          <i className={icon}></i>
        </div>
      }
      <h2>{title}</h2>
      {subtitle && <p className='text-md'>{subtitle}</p>}
    </div>
  );
}

export default PageMessage;
