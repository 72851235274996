import React, { ReactNode } from 'react';

import Icon from 'src/common/components/icon';

type Props = {
  editableBySpecialApprover?: boolean;
};

class EditableBadge extends React.Component<Props, never> {
  render(): ReactNode {
    if (!this.props.editableBySpecialApprover) { return null; }

    return (
      <div className='field-badge editable-badge'>
        <Icon name='pencil' />
      </div>
    );
  }
}

export default EditableBadge;
export type { Props };
