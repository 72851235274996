// @ts-strict-ignore
const ARRAY_ATTRIBUTES = new Set([
  'form_request[attachments][][field_id]',
  'form_request[vals][][field_id]',
  'form_request[vals][][content]',
  'form_request[signatures][][name]',
  'form_request[signatures][][field_id]',
  'form_request[signatures][][output]',
  'save_signature',
]);

type PrettifiedParams = {
  action: any;
  eventCcEmails?: string[];
  savedSignature?: any;
  clearSavedSignature?: boolean;
  routing?: any;
  formRequest?: any;
};

function prettify($form: JQuery): PrettifiedParams {
  const $formData = $form.find(
    'input:not([disabled]), select:not([disabled]), textarea:not([disabled])',
  );
  const formData = new Map<string, any>();

  ARRAY_ATTRIBUTES.forEach((attribute) => {
    formData.set(attribute, []);
  });

  $formData.each((_, field) => {
    const $field = $(field);

    if ($field.attr('name') === undefined) { return; }

    setFormDataForField($field, formData);
  });

  setAttachmentFieldFormData($form, formData);

  return generatePrettyObject(formData);
}

// private
function generatePrettyObject(formData: Map<string, any>): PrettifiedParams {
  const prettyObject = generateOuterKeys(formData);

  if (formData.get('save_signature').some((value) => { return value; })) {
    prettyObject.savedSignature = generateSavedSignatureObject(formData);
  }

  prettyObject.clearSavedSignature =
    formData.get('clear_saved_signature') === 'true';

  prettyObject.formRequest = generateFormRequestObject(formData);

  return prettyObject;
}

function setFormDataForField(
  $field: JQuery,
  formData: Map<string, any>,
): void {
  const fieldName = $field.attr('name');
  const isCheckbox = $field.attr('type') === 'checkbox';

  if (ARRAY_ATTRIBUTES.has(fieldName)) {
    const mapVal = isCheckbox ? $field.prop('checked') : $field.val();

    formData.get(fieldName).push(mapVal);
  } else if (fieldName.startsWith('form_request[attachment_fields]')) {
    // Skip attachment fields, they'll be handled separately
  } else if (isCheckbox) {
    formData.set(fieldName, $field.prop('checked'));
  } else {
    formData.set(fieldName, $field.val());
  }
}

function generateOuterKeys(formData: Map<string, any>): PrettifiedParams {
  const eventCcEmails = formData.get('event_cc_emails');

  const prettifiedParams: PrettifiedParams = {
    action: formData.get('form_request[action]'),
    ...eventCcEmails !== undefined && { eventCcEmails },
  };

  return prettifiedParams;
}

function generateSavedSignatureObject(formData: Map<string, any>) {
  return {
    hash: formData.get('save_signature_hash'),
    name: formData.get('save_signature_name'),
  };
}

function generateFormRequestObject(formData: Map<string, any>) {
  const docId = formData.get('form_request[doc_id]');
  const fieldsVersion = formData.get('form_request[fields_version]');
  const pagesVersion = formData.get('form_request[pages_version]');
  const rulesVersion = formData.get('form_request[rules_version]');
  const formRequestId = formData.get('form_request[form_request_id]');
  const campaignId = formData.get('form_request[campaign_id]');
  const subjectId = formData.get('form_request[subject_id]');
  const recipientId = formData.get('form_request[recipient_id]');
  const vals = [
    ...generateValArray(formData),
    ...generateSignatureArray(formData),
    ...generateCheckboxArray(formData),
    ...generateAttachmentArray(formData),
  ];

  return {
    docId,
    fieldsVersion,
    pagesVersion,
    rulesVersion,
    vals,
    ...formRequestId && { formRequestId },
    ...campaignId && { campaignId },
    ...subjectId && { subjectId },
    ...recipientId && { recipientId },
  };
}

function generateValArray(formData: Map<string, any>) {
  const fieldIdKey = 'form_request[vals][][field_id]';
  const valKey = 'form_request[vals][][content]';

  const fieldIdValues = formData.get(fieldIdKey);
  const valValues = formData.get(valKey);

  return fieldIdValues.map((fieldIdValue, index) => {
    return {
      content: valValues[index],
      fieldId: fieldIdValue,
    };
  });
}

function generateSignatureArray(formData: Map<string, any>) {
  const signatureFieldIdKey = 'form_request[signatures][][field_id]';
  const signatureNameKey = 'form_request[signatures][][name]';
  const signatureOutputKey = 'form_request[signatures][][output]';

  const signatureFieldIdValues = formData.get(signatureFieldIdKey);
  const signatureNameValues = formData.get(signatureNameKey);
  const signatureOutputValues = formData.get(signatureOutputKey);

  return signatureFieldIdValues.map((signatureFieldIdValue, index) => {
    return {
      fieldId: signatureFieldIdValue,
      name: signatureNameValues[index],
      output: signatureOutputValues[index],
    };
  });
}

function generateCheckboxArray(formData: Map<string, any>) {
  const checkIds = formData.get('form_request[check_id]').split(',');

  if (checkIds.length === 1 && checkIds[0] === '') { return []; }

  const checks = formData.get('form_request[check]').split(',');

  return checkIds.map((id, index) => {
    return { content: checks[index], fieldId: id };
  });
}

function generateAttachmentArray(formData: Map<string, any>) {
  const fieldIdValues = formData.get('form_request[attachments][][field_id]');

  return fieldIdValues.map((fieldIdValue: string) => {
    return {
      blobIds: formData.get(attachmentFieldBlobKey(fieldIdValue)),
      fieldId: fieldIdValue,
    };
  });
}

function setAttachmentFieldFormData(
  $form: JQuery,
  formData: Map<string, any>,
): void {
  defaultAttachmentFieldFormData(formData);

  const formFields: NodeListOf<HTMLInputElement> = $form[0].querySelectorAll(
    `input:not([disabled])[type="hidden"][name^="${attachmentFieldPrefix()}"]`,
  );
  formFields.forEach((field) => {
    formData.get(field.name).push(field.value);
  });
}

function defaultAttachmentFieldFormData(formData: Map<string, any>): void {
  const fieldIdValues = formData.get('form_request[attachments][][field_id]');

  fieldIdValues.forEach((fieldIdValue: string) => {
    formData.set(attachmentFieldBlobKey(fieldIdValue), []);
  });
}

function attachmentFieldBlobKey(fieldId: string): string {
  return `${attachmentFieldPrefix()}[${fieldId}][blob_ids][]`;
}

function attachmentFieldPrefix(): string {
  return 'form_request[attachment_fields]';
}

export default prettify;
