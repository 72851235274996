// @ts-strict-ignore
import Field from 'src/form_filler/fields/field';
import { removeAllTooltips } from 'src/helpers/tooltip';

class UserDataField extends Field {
  constructor(element: HTMLElement, data) {
    super(element, data);

    this.handleKeyUp = this.handleKeyUp.bind(this);

    if (this.$element.hasClass('locked-field')) { return; }

    this.toggleRequiredBorder();
    this.$element.on('keyup', this.handleKeyUp);
  }

  getValue(): string {
    return this.$element.find('.user_data_field').text();
  }

  handleKeyUp(): void {
    this.storeValue();
    this.toggleRequiredBorder();
    removeAllTooltips();
  }

  toggleRequiredBorder(): void {
    const $borderElement = this.getBorderEl();

    $borderElement.toggleClass('required-danger empty', this.validateRequired());
  }

  getBorderEl(): JQuery {
    return this.$element.find('.user_data_field');
  }

  storeValue(): void {
    super.storeValue();

    this.$element.find('[name="form_request[vals][][content]"]')
      .val(this.getValue());
  }
}

export default UserDataField;
