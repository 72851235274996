// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';
import A11yDialog from 'a11y-dialog';
import bindAll from 'lodash/bindAll';
import keyBy from 'lodash/keyBy';
import union from 'lodash/union';

import DocEditorSingleStore from 'src/chux/doc_editor/single_store';
import EditableFieldsStore, { getFields, removeFields, updateFields }
  from 'src/chux/editable_fields/store';
import { supportEmail } from 'src/constants';
import Doc from 'src/doc_editor/doc';
import DocEditorHotKey from 'src/doc_editor/doc_editor_hot_key';
import DocPreview from 'src/doc_editor/doc_preview';
import FieldComponent from 'src/doc_editor/field';
import FormulaValidator, { FormulaErrors }
  from 'src/doc_editor/fields/helpers/formula_validator';
import validateField from 'src/doc_editor/fields/helpers/validate_field';
import FieldModel from 'src/doc_editor/fields/models/field';
import NewVersionChecker from 'src/doc_editor/new_version_checker';
import Toolbar from 'src/doc_editor/toolbar';
import DocEditorValidator from 'src/doc_editor/validator';
import Dialog from 'src/helpers/dialog';
import { fetchPost, fetchPut } from 'src/helpers/fetch';
import { findEl } from 'src/helpers/finders';
import HotKey from 'src/helpers/hot_key';
import Location from 'src/helpers/location';
import notify from 'src/helpers/notify';
import { events, subscribe } from 'src/helpers/pub_sub';
import { hide } from 'src/helpers/visibility';

type ServerData = {
  docId: number;
};

type ErrorResponse = {
  errors: { title: string }[];
};

class DocEditorController extends Controller<HTMLElement> {
  static values = {
    campaign: Object,
    changesMade: Boolean,
    fontSize: Number,
    hasAttachments: Boolean,
    requireAttachments: Boolean,
  };

  campaignValue: { steps: Step[] } | null;
  changesMadeValue: boolean;
  currentTool: string | null;
  fontSizeValue: number;
  hasAttachmentsValue: boolean;
  hasCampaignValue: boolean;
  requireAttachmentsValue: boolean;
  validator: DocEditorValidator;
  doc: Doc;
  toolbar: Toolbar;
  docPreview: DocPreview;

  get contextMenuTargets(): HTMLElement[] {
    return Array.from(this.element.querySelectorAll('div.context-menu'));
  }

  get shareLinkTarget(): HTMLElement {
    return findEl(this.element, 'div', '.doc-editor-share-link');
  }

  get hasShareLinkTarget(): boolean {
    return Boolean(this.element.querySelector('.doc-editor-share-link'));
  }

  get continuePathValue(): string | null {
    return this.element.dataset.continuePath;
  }

  get steps(): Step[] | null {
    return this.hasCampaignValue ? this.campaignValue.steps : null;
  }

  get testingValue(): boolean {
    return this.element.dataset.testing === 'true';
  }

  get changesMade(): boolean {
    return DocEditorSingleStore.getState().changesMade;
  }

  set changesMade(changesMade: boolean) {
    DocEditorSingleStore.update({ changesMade });
  }

  connect(): void {
    this.setStoreValues();

    this.currentTool = null;

    bindAll(
      this,
      'preview',
      'hideMenus',
      'saveSuccess',
      'ajaxSave',
      'copyFields',
      'clearFields',
      'duplicateFields',
      'setActiveFields',
      'getCurrentTool',
      'setCurrentTool',
      'update',
    );

    this.validator = new DocEditorValidator();

    this.doc = new Doc(findEl(this.element, 'div', '#doc'), {
      getCurrentTool: this.getCurrentTool,
      setCurrentTool: this.setCurrentTool,
      steps: this.steps,
      testing: this.testingValue,
    });

    this.docPreview = new DocPreview(findEl(this.element, 'div', '#preview-modal'));

    this.toolbar = new Toolbar(findEl(this.element, 'div', '#toolbar'), {
      clearFields: this.clearFields,
      continueOnSave: this.continueOnSave(),
      copyFields: this.copyFields,
      getCurrentTool: this.getCurrentTool,
      preview: this.preview,
      setCurrentTool: this.setCurrentTool,
    });

    subscribe(events.FIELD_ADDED, this.handleFieldAdded.bind(this));
    subscribe(events.FIELD_REMOVED, this.handleFieldRemoved.bind(this));
    subscribe(events.FIELD_DRAG_STARTED, this.hideMenus.bind(this));
    subscribe(events.FIELD_CHANGED, this.logChange.bind(this));

    DocEditorSingleStore.subscribe(this.update.bind(this));
  }

  setStoreValues(): void {
    DocEditorSingleStore.update({
      changesMade: this.changesMadeValue,
      fontSize: this.fontSizeValue,
      hasAttachments: this.hasAttachmentsValue,
      requireAttachments: this.requireAttachmentsValue,
    });
  }

  handleFieldAdded(field: FieldComponent): void {
    if (field.getType() === 'ReferenceNumber') {
      this.toolbar.toggleRefnumTool(true); // `true` means disable
    }
  }

  handleFieldRemoved(field: FieldComponent): void {
    if (field.getType() === 'ReferenceNumber') {
      this.toolbar.toggleRefnumTool(false); // `false` means enable
    }
    this.hideMenus();
    this.logChange();
  }

  requireSave(event: Event): void {
    if (!this.changesMadeValue) { return; }
    event.preventDefault();
    event.stopImmediatePropagation();
    Dialog.alert('You must save your changes before you can do this action.');
  }

  async save(): Promise<void> {
    if (this.invalidFields()) { return; }

    const fieldDatas = Object.values(EditableFieldsStore.getState());

    await this.validator.validate(fieldDatas);

    this.ajaxSave();
  }

  ajaxSave(): void {
    const storeData = DocEditorSingleStore.getState();

    const serverData = {
      commit: 'Save',
      groupId: this.toolbar.selectedGroupId,
      ...this.toolbar.getSubmitParams(),
      ...this.doc.getSubmitParams(),
    };

    serverData.doc.fontSize = storeData.fontSize;

    this.toolbar.save();
    this.postToBatchFields(serverData);
    this.updateFieldsAtLastSave();

    if (!this.testingValue) {
      this.makeReferenceFieldUneditable();
    }
  }

  invalidFields(): boolean {
    let errorMessages: string[] = [];
    const fieldsByNumber = EditableFieldsStore.getState();
    const stepsById = keyBy(this.steps, 'id');

    const allFormulaErrors: FormulaErrors[] = FormulaValidator
      .validateAll(fieldsByNumber, stepsById);

    allFormulaErrors.forEach((formulaErrors) => { updateFields(formulaErrors); });

    const cycleMessage = FormulaValidator.detectCycle(fieldsByNumber);

    if (cycleMessage) { errorMessages.push(cycleMessage); }

    errorMessages = union(errorMessages, getFieldErrors());

    if (errorMessages.length === 0) { return false; }

    this.displayInvalidFieldsMessage(errorMessages);
    return true;
  }

  displayInvalidFieldsMessage(errorMessages: string[]): void {
    const invalidFieldsModalEl =
      findEl(document.body, 'div', '#invalid-fields-modal');
    const invalidFieldsDialog = new A11yDialog(invalidFieldsModalEl);

    const errorsEl = findEl(invalidFieldsModalEl, 'p', '.error-content');

    errorsEl.innerHTML = errorMessages.join('<br/><br/>');

    invalidFieldsDialog.show();
  }

  warnUnsaved(event: Event): string | null {
    if (!this.changesMadeValue) { return null; }

    return Location.confirmPageLeave(event);
  }

  hideMenus(): void {
    this.contextMenuTargets.forEach(hide);
  }

  logChange(): void {
    if (this.changesMade) { return; }

    this.changesMade = true;
  }

  toolbarClick(): void {
    this.doc.removeInvalidFields();
    this.hideMenus();
  }

  setCurrentTool(currentTool: string | null): void {
    this.currentTool = currentTool;
    this.doc.refreshCurrentTool();
    this.toolbar.updateToolbarDisplay();
  }

  getCurrentTool(): string | null {
    return this.currentTool;
  }

  continueOnSave(): boolean {
    return Boolean(this.continuePathValue);
  }

  // track whether the document has been modified, and adjust save button
  updateSaveBtn(): void {
    if (this.changesMade || this.continueOnSave()) {
      this.toolbar.enableSave();
    } else {
      this.toolbar.disableSave();
    }
  }

  saveSuccess(): void {
    this.changesMade = false;

    if (this.continueOnSave()) {
      Location.navigateTo(this.continuePathValue);
    } else if (this.hasShareLinkTarget) {
      this.shareLinkTarget.classList.remove('hide-for-now');
    }
  }

  updateFieldsAtLastSave(): void {
    const currentFields = EditableFieldsStore.getState();
    const payload = { fieldsAtLastSave: currentFields };

    DocEditorSingleStore.update(payload);
  }

  shouldCreateNewFields(): boolean {
    const storeState = DocEditorSingleStore.getState();
    const oldFields = storeState.fieldsAtLastSave;
    const newFields = EditableFieldsStore.getState();

    return NewVersionChecker.run({
      newFieldsData: newFields,
      oldFieldsData: oldFields,
    });
  }

  postToBatchFields(data: ServerData): void {
    const fetcher = this.shouldCreateNewFields() ? fetchPost : fetchPut;

    const url = `/docs/${this.doc.getSubmitParams().docId}/batch_fields`;

    fetcher(url, data).then(this.saveSuccess).catch(this.notifyOfError);
  }

  notifyOfError(data: ErrorResponse): void {
    if (data && data.errors) {
      Dialog.alert(data.errors[0].title);
    } else {
      notify(new Error('error saving on doc editor'), { data });

      Dialog.alert('There was an issue saving your doc, please contact ' +
        `${supportEmail} or try reloading the page.`);
    }
  }

  reset(): void {
    this.toolbar.reset();
    this.doc.reset();
    this.hideMenus();
  }

  makeReferenceFieldUneditable(): void {
    const fields = EditableFieldsStore.getState();
    const referenceNumberFields = Object.values(fields).filter((field) => {
      return field.type === 'ReferenceNumber';
    });

    referenceNumberFields.forEach((field) => {
      const payload = { editable: false, number: field.number };

      updateFields(payload);
    });
  }

  // functions below rely on instantiated object within initialize()
  // having these wrapped in functions prevents us from needing to instantiate
  // components (e.g. this.doc, this.toolbar, etc) in a specific order
  clearFields(): void {
    this.doc.clearFields();
  }

  copyFields(docId: number): void {
    this.doc.copyFields(docId);
  }

  duplicateFields(fields: Field[]): FieldModel[] {
    return this.doc.duplicateFields(fields);
  }

  setActiveFields(fieldNumbers: Set<number>): void {
    this.doc.setActiveFields(fieldNumbers);
  }

  preview(): void {
    this.docPreview.show(this.doc.getPages());
  }

  update(): void {
    this.doc.getPages().forEach((page) => {
      page.getFields().forEach((field) => {
        field.refreshContextMenu();
      });
    });

    const data = DocEditorSingleStore.getState();

    this.changesMadeValue = this.changesMade;
    this.updateSaveBtn();
    this.doc.setFontSize(data.fontSize);
  }

  processHotKey(event: KeyboardEvent): void {
    if (isElementFocused()) { return; }

    const hotKey = new HotKey(event);

    const steps = this.steps || [];

    if (DocEditorHotKey.execute(
      hotKey,
      {
        duplicateFields: this.duplicateFields,
        getFields,
        removeFields,
        setActiveFields: this.setActiveFields,
        steps,
        updateFields,
      },
    )) {
      event.preventDefault();
    }
  }

  resetOnClick(event: DOMEvent): void {
    const clickOutsideEditor = !event.target.parentElement.closest('#doc-editor');

    if (clickOutsideEditor) { this.reset(); }
  }

  resetOnEscape(event: KeyboardEvent): void {
    if (event.key === 'Escape') { this.reset(); }
  }

  stopResizing(): void {
    this.doc.stopResizing();
  }
}

function isElementFocused(): boolean {
  return document.activeElement !== document.body;
}

function getFieldErrors(): string[] {
  const fields = EditableFieldsStore.getState();
  const fieldErrors: string[] = [];

  Object.values(fields).forEach((field) => {
    fieldErrors.push(...validateField(field));
  });

  return fieldErrors;
}

type ClearFields = DocEditorController['clearFields'];
type CopyFields = DocEditorController['copyFields'];
type GetCurrentTool = DocEditorController['getCurrentTool'];
type Preview = DocEditorController['preview'];
type SetCurrentTool = DocEditorController['setCurrentTool'];

export default DocEditorController;

export type { ClearFields, CopyFields, GetCurrentTool, Preview, SetCurrentTool };
