// @ts-strict-ignore
import { connect } from 'react-redux';

import ResponsesAppliedFilters from 'src/responses/components/applied_filters';

function mapStateToProps(state) {
  const { filters, searchTerm } = state.responses.meta.queryParams;

  return { filters, searchTerm };
}

export default connect(mapStateToProps)(ResponsesAppliedFilters);
