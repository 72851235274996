// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { fetchCampaign } from 'src/campaign/action_creators';
import store from 'src/common/store';
import notify from 'src/helpers/notify';
import { fetchSteps } from 'src/steps/action_creators';
import StepsIndexContainer from 'src/steps/containers/index';

class StepsIndexController extends Controller<HTMLElement> {
  $element: JQuery;

  connect(): void {
    this.$element = $(this.element);

    const campaignId: number = this.$element.data('campaignId');

    type ThunkType = ThunkDispatch<any, null, AnyAction>;
    (store.dispatch as ThunkType)(fetchCampaign(campaignId)).then(() => {
      store.dispatch(fetchSteps(campaignId));

      ReactDOM.render(wrapper(), this.element);
    }, notify);
  }
}

// private

function wrapper(): React.JSX.Element {
  return (
    <Provider store={store}>
      <React.StrictMode>
        <StepsIndexContainer />
      </React.StrictMode>
    </Provider>
  );
}

export default StepsIndexController;
