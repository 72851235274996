function assertInclusion<T>(array: T[], value: string): T {
  const result = array.find((item) => { return item === value; });

  if (!result) {
    throw new Error(`Invalid value: ${value}`);
  }

  return result;
}

export default assertInclusion;
