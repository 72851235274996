// @ts-strict-ignore
import React, { ReactNode } from 'react';

import { BoundUpdateFields } from 'src/chux/editable_fields/store';
import NumberPrecisionDropdown
  from 'src/doc_editor/fields/components/common/number_precision_dropdown';
import { inputFormatValidationOptions } from 'src/fields/config/formats';

type Props = {
  formatLocked: boolean;
  number: number;
  updateFields: BoundUpdateFields;
  format: string | null;
  precision: number | null;
};

class InputContextMenu extends React.Component<Props, never> {
  constructor(props: Props) {
    super(props);
    this.updateFormat = this.updateFormat.bind(this);
    this.numberPrecisionDropdown = this.numberPrecisionDropdown.bind(this);
  }

  render(): ReactNode {
    const { format, formatLocked } = this.props;

    return (
      <div>
        <div>
          <label>
            Validate format:
            <select
              disabled={formatLocked}
              name='format'
              value={format || ''}
              onChange={this.updateFormat}
            >
              <option value=''>None</option>
              {formatOptions()}
            </select>
          </label>
        </div>
        {format === 'number' && this.numberPrecisionDropdown()}
      </div>
    );
  }

  updateFormat(event: React.ChangeEvent<HTMLSelectElement>): void {
    const { number, updateFields } = this.props;

    const format = event.target.value || null;

    updateFields({ content: format, format, number });
  }

  numberPrecisionDropdown(): React.JSX.Element {
    const { number, precision, updateFields } = this.props;

    return (
      <NumberPrecisionDropdown
        number={number}
        precision={precision}
        showNoRounding
        updateFields={updateFields}
      />
    );
  }
}

// private

function formatOptions(): React.JSX.Element[] {
  return inputFormatValidationOptions.map((format) => {
    const { type, label } = format;

    return <option key={type} value={label} >{type} - {label}</option>;
  });
}

export default InputContextMenu;
export type { Props };
