// @ts-strict-ignore
import orderBy from 'lodash/orderBy';

import createBasicReducer from 'src/common/basic_reducer';

const operations = {
  '@@INIT': initStore,
  'steps/SET': setSteps,
  'steps/UPDATE_META': updateMeta,
};

function initStore() {
  return { byId: {}, meta: {}, orderedIds: [] };
}

function setSteps(_previousState, stepData: Step[]) {
  const orderedObjects = orderBy(Object.values(stepData), 'seq');
  const orderedIds = orderedObjects.map((step: Step) => { return step.id; });

  return { byId: stepData, meta: {}, orderedIds };
}

function updateMeta(previousState, meta) {
  return { ...previousState, meta: { ...previousState.meta, ...meta } };
}

export default createBasicReducer(operations);
