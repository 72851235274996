import { Controller } from '@hotwired/stimulus';

import Dialog from 'src/helpers/dialog';
import { fetchPut } from 'src/helpers/fetch';
import Location from 'src/helpers/location';

type Data = { form: { [key: string]: string } };

class ActionButton extends Controller<HTMLButtonElement> {
  static values = {
    confirm: String,
    paramName: String,
    paramValue: String,
    url: String,
  };

  urlValue!: string;
  confirmValue!: string;
  hasConfirmValue!: boolean;
  paramNameValue!: string;
  paramValueValue!: string;

  submit(): void {
    if (!this.confirmAction()) { return; }

    const data: Data = { form: { [this.paramNameValue]: this.paramValueValue } };

    this.disableButton();
    this.sendRequest(data);
  }

  confirmAction(): boolean {
    return !this.hasConfirmValue || Dialog.confirm(this.confirmValue);
  }

  async sendRequest(data: Data): Promise<void> {
    await fetchPut(this.urlValue, data);

    Location.reload();
  }

  disableButton(): void {
    this.element.disabled = true;
  }
}

export default ActionButton;
