// @ts-strict-ignore
import bindAll from 'lodash/bindAll';

import {
  ClearFields,
  CopyFields,
  GetCurrentTool,
  Preview,
  SetCurrentTool,
} from 'controllers/doc_editor_controller';
import DocEditorSingleStore from 'src/chux/doc_editor/single_store';
import OptionsMenu, { OptionsMenuSubmitParams } from 'src/doc_editor/options_menu';
import { fetchGet } from 'src/helpers/fetch';
import { findEl } from 'src/helpers/finders';
import { events, publish } from 'src/helpers/pub_sub';
import valToString from 'src/helpers/val_to_string';

type ToolbarParams = {
  clearFields: ClearFields;
  copyFields: CopyFields;
  continueOnSave: boolean;
  getCurrentTool: GetCurrentTool;
  preview: Preview;
  setCurrentTool: SetCurrentTool;
};

type ToolbarSubmitParams = OptionsMenuSubmitParams & {
  groupId: string | null;
};

class Toolbar {
  $element: JQuery;
  element: HTMLElement;
  $refnumTool: JQuery;
  $optionsMenu: JQuery;
  $prefillTool: JQuery;
  getCurrentTool: GetCurrentTool;
  setCurrentTool: SetCurrentTool;
  continueOnSave: boolean;
  optionsMenu: OptionsMenu;
  fontSize: string;
  selectedGroupId: string | null = null;

  constructor(element: HTMLElement, params: ToolbarParams) {
    this.$element = $(element);
    this.element = element;

    bindAll(
      this,
      'selectTool',
      'groupChange',
      'reset',
      'updateToolbarDisplay',
      'toggleRefnumTool',
    );

    this.setCurrentTool = params.setCurrentTool;
    this.continueOnSave = params.continueOnSave;
    this.getCurrentTool = params.getCurrentTool;

    this.$optionsMenu = $(this.optionsMenuContainer);
    this.$prefillTool = $(this.prefillTool);
    this.$refnumTool = $(this.refnumTool);

    this.optionsMenu = new OptionsMenu(this.optionsMenuContainer, {
      clearFields: params.clearFields,
      copyFields: params.copyFields,
    });

    this.$element.find('button.tool-item').on('click', this.selectTool);
    this.$element.find('#ik12-group-select').on('change', this.groupChange);
    this.$element.find('#preview').on('click', params.preview);

    this.groupToolUnlock();
  }

  get optionsMenuContainer(): HTMLElement {
    return findEl(this.element, 'div', '.options');
  }

  get prefillTool(): HTMLElement {
    return findEl(this.element, 'button', '.tool-item[data-tool="Prefill"]');
  }

  get refnumTool(): HTMLElement {
    const referenceNumberSelector = '.tool-item[data-tool="ReferenceNumber"]';

    return findEl(this.element, 'button', referenceNumberSelector);
  }

  get $currentTool(): JQuery {
    const currentTool = this.getCurrentTool();
    const currentToolSelector = `.tool-item[data-tool=${currentTool}]`;

    return this.$element.find(currentToolSelector);
  }

  groupToolUnlock(): void {
    if (this.groupSelected()) {
      this.groupSelect(this.$prefillTool.data('last-group'));
    } else {
      this.$prefillTool.addClass('tool-disabled');
      this.$prefillTool.prop('disabled', true);
    }
  }

  async groupSelect(id: string): Promise<void> {
    this.selectedGroupId = id || null;

    if (id) {
      const { data }: { data: Group } = await fetchGet(`/groups/${id}`);

      publish(events.GROUP_SELECTION_CHANGED, data);

      const payload = { groupColumns: data.dataColumns };

      DocEditorSingleStore.update(payload);
    } else {
      publish(
        events.GROUP_SELECTION_CHANGED,
        { dataColumns: [], subjectData: [] },
      );
      DocEditorSingleStore.update({ groupColumns: [] });
    }
  }

  save(): void {
    this.$element.find('#save').text('Saving...');
    this.$element.find('#save').prop('disabled', true);
  }

  enableSave(): void {
    this.$element.find('#save').prop('disabled', false);
    if (!this.continueOnSave) {
      this.$element.find('#save').text('Save');
    }
  }

  disableSave(): void {
    this.$element.find('#save').prop('disabled', true);

    if (!this.continueOnSave) {
      this.$element.find('#save').text('Saved');
    }
  }

  groupSelected(): boolean {
    return Boolean(this.$prefillTool.data('last-group'));
  }

  reset(): void {
    this.$element.find('.tool-item').removeClass('tool-item-active');
  }

  selectTool(event: JQuery.ClickEvent): void {
    const $tool = $(event.target).closest('.tool-item');

    if ($tool.hasClass('tool-disabled')) { return; }

    const oldTool = this.getCurrentTool();
    const clickedTool = $tool.data('tool');

    const newTool = oldTool === clickedTool ? null : clickedTool;

    this.setCurrentTool(newTool);
  }

  groupChange(event: JQuery.ChangeEvent): void {
    const val = $(event.target).find('option:selected').val();

    const noGroupSelected = val === '';

    this.$prefillTool.toggleClass('tool-disabled', noGroupSelected);
    this.$prefillTool.prop('disabled', noGroupSelected);

    if (val !== '') {
      if (!this.$prefillTool.hasClass('tool-item-active')) {
        this.$prefillTool.trigger('click');
      }
    }
    this.groupSelect(valToString(val));
    this.disallowTransfer();
  }

  getSubmitParams(): ToolbarSubmitParams {
    return {
      groupId: this.selectedGroupId,
      ...this.optionsMenu.getSubmitParams(),
    };
  }

  disallowTransfer(): void {
    $('#transfer-ownership-modal .allow-transfer').hide();
    $('#transfer-ownership-modal .deny-transfer').show();
  }

  updateToolbarDisplay(): void {
    this.reset();

    this.$currentTool.addClass('tool-item-active');
  }

  toggleRefnumTool(value): void {
    this.$refnumTool.toggleClass('tool-disabled', value);
  }
}

export type { ToolbarParams };
export default Toolbar;
