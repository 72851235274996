// @ts-strict-ignore
import 'jquery.signaturepad';
import { Controller } from '@hotwired/stimulus';
import A11yDialog from 'a11y-dialog';

import $template from 'src/helpers/dollar_template';
import { findEl } from 'src/helpers/finders';
import valToString from 'src/helpers/val_to_string';
import { hide, show } from 'src/helpers/visibility';

class FormSetSubmissionController extends Controller<HTMLElement> {
  $element: JQuery;
  $form: JQuery;
  $termsCheckbox: JQuery;
  $recipientsDropdown: JQuery;
  $recipientName: JQuery;
  $recipientEmail: JQuery;
  $signature: JQuery;
  $summaryButton: JQuery;
  approverStepType: string;
  signatureModal: HTMLElement;
  signatureDialog: A11yDialog;

  get form(): HTMLFormElement {
    return findEl(this.element, 'form');
  }

  get signature(): HTMLElement {
    return findEl(this.element, 'div', '.signature');
  }

  get signatureName(): HTMLElement {
    return findEl(this.signature, 'input', '.name');
  }

  get termsCheckbox(): HTMLElement {
    return findEl(this.element, 'input', '#terms-checkbox');
  }

  get recipientsDropdown(): HTMLElement {
    return findEl(this.element, 'select', '#submission_recipient_id');
  }

  get recipientName(): HTMLElement {
    return findEl(this.element, 'input', '#submission_recipient_name');
  }

  get recipientEmail(): HTMLElement {
    return findEl(this.element, 'input', '#submission_recipient_email');
  }

  get summaryButton(): HTMLElement {
    return findEl(this.element, 'button', '#summary-button');
  }

  get recipientCard(): HTMLElement {
    return this.element.querySelector('div#recipient-card');
  }

  get signatureButton(): HTMLElement {
    return findEl(this.signature, 'button', '.signature-btn');
  }

  connect(): void {
    this.$element = $(this.element);
    this.$signature = $(this.signature);

    this.$signature.find('.sigPad').signaturePad({ validateFields: false });
    this.signatureModal =
      findEl(this.element, 'div', '[data-a11y-dialog="signature-modal"]');
    this.signatureDialog = new A11yDialog(this.signatureModal);

    const $recipientCard = $(this.recipientCard);

    this.approverStepType = $recipientCard.data('step-type');

    if (this.approverStepType === 'RecipientStep') {
      this.$recipientsDropdown = $(this.recipientsDropdown);
    } else if (this.approverStepType === 'PromptStep') {
      this.$recipientName = $(this.recipientName);
      this.$recipientEmail = $(this.recipientEmail);
    } else {
      // end of route, no next step
    }

    this.$form = $<HTMLFormElement>(this.form);
    this.$termsCheckbox = $(this.termsCheckbox);

    this.$summaryButton = $(this.summaryButton);

    this.updateModalApproverText = this.updateModalApproverText.bind(this);
    this.updateSignatureDisplay = this.updateSignatureDisplay.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.$signature.find('.sigPad').signaturePad({ validateFields: false });
    this.signatureDialog.on('hide', this.updateSignatureDisplay);

    this.$form.on('submit', this.handleSubmit);

    this.recipientFields().forEach(($field) => {
      $field.on('change', this.updateModalApproverText);
    });
  }

  recipientFields(): JQuery[] {
    const fields: JQuery[] = [];

    if (this.approverStepType === 'RecipientStep') {
      fields.push(this.$recipientsDropdown);
    } else if (this.approverStepType === 'PromptStep') {
      fields.push(this.$recipientName, this.$recipientEmail);
    }

    return fields;
  }

  isSubmittable(): boolean {
    return this.form.checkValidity();
  }

  updateSignatureDisplay(): void {
    const $completedSignature = this.$signature.find('.completed-signature');
    const drawnSignature = valToString(this.$signature.find('.output').val());
    const name = valToString(this.$signature.find('.name').val());

    $completedSignature.html($template('completed-signature'));

    if (drawnSignature && name) {
      $completedSignature.find('.sigPad')
        .signaturePad({ displayOnly: true })
        .regenerate(drawnSignature);
      hide(this.signatureButton);
    } else if (name) {
      $completedSignature.find('.typed').text(name);
      hide(this.signatureButton);
    } else {
      $completedSignature.empty();
      show(this.signatureButton);
    }
  }

  updateModalApproverText(): void {
    this.$element.find('#modal-approver-description').text(this.approverLabel());
  }

  approverLabel(): string {
    if (this.approverStepType === 'RecipientStep') {
      return this.$recipientsDropdown.find('option:selected').text();
    }

    return `${this.$recipientName.val()} (${this.$recipientEmail.val()})`;
  }

  validateForm(event: Event): void {
    if (this.isSubmittable()) {
      this.handleSubmit(event);
      return;
    }

    const firstInvalidElement = this.element.querySelector('input:invalid');

    if (firstInvalidElement === this.signatureName) { this.signatureDialog.show(); }

    this.form.reportValidity();
  }

  handleSubmit(event: Event): void {
    if (this.isSubmittable() && this.isConfirmationModalShowing()) {
      this.$element.find('button:contains("Cancel")').prop('disabled', 'disabled');
    } else {
      event.preventDefault();
      event.stopPropagation(); // prevent button from changing to disabled text
    }

    if (this.isSignatureModalShowing()) {
      this.hideSignatureModal();
    } else if (this.isSubmittable() && !this.isConfirmationModalShowing()) {
      this.showConfirmationModal();
    }
  }

  isSignatureModalShowing(): boolean {
    return !this.signatureModal.getAttribute('aria-hidden');
  }

  hideSignatureModal(): void {
    this.signatureDialog.hide();
  }

  isConfirmationModalShowing(): boolean {
    const element =
      findEl(this.element, 'div', '[data-a11y-dialog="form-set-submission-modal"]');

    return !element.getAttribute('aria-hidden');
  }

  showConfirmationModal(): void {
    const element =
      findEl(this.element, 'div', '[data-a11y-dialog="form-set-submission-modal"]');
    const dialog = new A11yDialog(element);

    dialog.show();
  }
}

export default FormSetSubmissionController;
