type TimeParseError = { error: string };
type TimeParseResult = { hours: number; minutes: number; amPm: 'AM' | 'PM' };
type ParsedTimeWithInterval = TimeParseResult & { interval: number };
type TimeParseResult24Hour = { hours: number; minutes: number };

function parseTime(input: string): TimeParseError | TimeParseResult {
  const time = input.replace(/\s/g, '').toLowerCase();
  const parsed = (/^(\d?\d|2[0-3]):?([0-5]\d)?([ap][m]?)?$/).exec(time);

  if (parsed === null) {
    return { error: 'Please enter a time, e.g., 12:30 PM.' };
  }
  const [, parsedHours, parsedMinutes, parsedAmPm] = parsed;
  const hours = Number(parsedHours);
  const minutes = parsedMinutes ? Number(parsedMinutes) : 0;

  if (hours === 0 || hours > 12) {
    return { error: 'Please enter a time in 12 hour format.' };
  } else if (!parsedAmPm) {
    return { error: 'Please enter AM/PM for time.' };
  }

  const amPm = parsedAmPm && parsedAmPm.includes('p') ? 'PM' : 'AM';

  return { amPm, hours, minutes };
}

function roundTime(
  { hours, minutes, amPm, interval }: ParsedTimeWithInterval,
): TimeParseResult {
  const roundedMinutes = Math.round(Number(minutes) / interval) * interval;
  let newHours = Number(hours);
  const date = new Date('1995-12-17T03:24:00');

  const amHours = newHours === 12 ? 0 : newHours;
  const pmHours = newHours === 12 ? 12 : newHours + 12;

  date.setHours(amPm === 'AM' ? amHours : pmHours);
  date.setMinutes(roundedMinutes);

  newHours = date.getHours();
  const newAmPm = newHours < 12 ? 'AM' : 'PM';

  newHours %= 12;
  if (newHours === 0) { newHours = 12; }

  return {
    amPm: newAmPm,
    hours: newHours,
    minutes: date.getMinutes(),
  };
}

function roundAndFormatTime(
  { hours, minutes, amPm, interval }: ParsedTimeWithInterval,
): string {
  const rounded = roundTime({ amPm, hours, interval, minutes });
  const paddedRoundedMinutes = rounded.minutes.toString().padStart(2, '0');

  return `${rounded.hours}:${paddedRoundedMinutes} ${rounded.amPm}`;
}

function parseTime24Hour(input: string): TimeParseError | TimeParseResult24Hour {
  const parsedTime = parseTime(input);

  if ('error' in parsedTime) { return parsedTime; }

  const { hours, minutes, amPm } = parsedTime;
  let totalHours = hours === 12 ? 0 : hours;

  if (amPm === 'PM') { totalHours += 12; }

  return { hours: totalHours, minutes };
}

export { parseTime, roundTime, roundAndFormatTime, parseTime24Hour };
export type { ParsedTimeWithInterval };
