// @ts-strict-ignore
import range from 'lodash/range';
import Papa from 'papaparse';

import { EMAIL_REGEX, supportEmail } from 'src/constants';
import stripHTMLTags from 'src/helpers/strip_html_tags';

class CsvValidator {
  columns: { [key: string]: string[] };
  errors: string[];
  headers: any[];
  mappings: { [key: string]: string };
  numRows: number;

  constructor() {
    this.columns = {};
    this.errors = [];
    this.numRows = 0;
    this.getRow = this.getRow.bind(this);
  }

  loadData(rawCsv): void {
    this.headers = [];

    Papa.parse(rawCsv, {
      complete: (results) => {
        const { data } = results;

        this.setHeaders(data[0]);
        data.slice(1).forEach((row) => { this.addRow(row); });
      },
      delimiter: ',',
      transform: stripHTMLTags,
    });
  }

  setHeaders(myHeaders): void {
    if (!myHeaders) { return; }
    this.headers = myHeaders.map((value) => {
      return value.replace(/[[\]]/g, ' ').trim();
    });
    this.headers.forEach((header) => {
      this.columns[header] = [];
    });
  }

  addRow(row): void {
    if (hasOnlyBlanks(row)) { return; }

    this.numRows += 1;

    Object.entries(this.columns).forEach(([column, columnValues]) => {
      const headerIndex = this.headers.indexOf(column);
      const value = row[headerIndex] || '';

      columnValues.push(value.trim());
    });
  }

  getHeaders() {
    return this.headers;
  }

  getColumns(): { [key: string]: string[] } {
    return this.columns;
  }

  setMappings(newMappings): void {
    this.errors = [];
    this.mappings = newMappings;
  }

  getErrors(): string {
    return this.errors.join('<br>');
  }

  validateNames(): void {
    const matchedHeaders = Object.keys(this.mappings);

    const fullNameChosen = matchedHeaders.includes('full name');
    const firstNameChosen = matchedHeaders.includes('first name');
    const lastNameChosen = matchedHeaders.includes('last name');

    if (!fullNameChosen && !(firstNameChosen && lastNameChosen)) {
      this.errors.push('Please make sure you have either a <u>name</u> ' +
        'OR <u>first name</u> and <u>last name</u> selected.');
    } else if (fullNameChosen && (firstNameChosen || lastNameChosen)) {
      this.errors.push('You should either have a name selected' +
        ' or a first name and last name selected.  Not both!');
    }
  }

  validateMultiEmail(): void {
    const matchedHeaders = Object.keys(this.mappings);

    if (!matchedHeaders.includes('recipient 1 email')) {
      this.errors.push("If each person's form will go to multiple " +
        'people, please specify at least 1 <u>recipient</u> email.');
    }
  }

  validateBlanks(): void {
    // The matched headers that can't have blanks in their columns
    const cantHaveBlanks = {
      'first name': true,
      'full name': true,
      'last name': true,
    };

    Object.entries(this.mappings).forEach(([matchedHeader, csvHeader]) => {
      if (cantHaveBlanks[matchedHeader]) {
        const column = this.columns[csvHeader];

        if (hasBlanks(column)) {
          this.errors.push(`The '${csvHeader}' column seems to be ` +
            'missing entries.<br>' +
            'Please review your .csv file or contact ' +
            `${supportEmail} for assistance.`);
        }
      }
    });
  }

  validateEmails(): void {
    Object.entries(this.mappings).forEach(([matchedHeader, csvHeader]) => {
      if (matchedHeader.includes('email')) {
        const column = this.columns[csvHeader];
        const invalidEmails = getInvalidEmails(column);

        if (invalidEmails.length > 0) {
          this.errors.push(`The '${csvHeader}' column has the ` +
            `following invalid emails: ${invalidEmails.join(', ')}` +
            '<br>Please review your .csv file or contact ' +
            `${supportEmail} for assistance.`);
        }
      }
    });
  }

  validateUniqueHeaders(): void {
    const duplicateHeaders = this.headers.filter((header, index) => {
      return this.headers.indexOf(header) !== index;
    });

    if (duplicateHeaders.length > 0) {
      this.errors.push(
        'The .csv file has multiple columns with the same ' +
        `header: "${duplicateHeaders.join('", "')}". ` +
        'Please review your .csv file or contact ' +
        `${supportEmail} for assistance.`,
      );
    }
  }

  rawCsv(): string {
    return Papa.unparse({ data: this.getRows(), fields: this.headers });
  }

  getRows(): string[][] {
    return range(this.numRows).map(this.getRow);
  }

  getRow(index: number): string[] {
    return this.headers.map((header) => {
      return this.columns[header][index];
    });
  }
}

// private

function hasOnlyBlanks(array): boolean {
  return array.every((entry) => {
    return !entry.trim();
  });
}

function hasBlanks(column: string[]): boolean {
  return column.some((entry) => { return entry === ''; });
}

function getInvalidEmails(column: string[]): string[] {
  return column.filter((email) => {
    return !(email === '' || email.toLowerCase().match(EMAIL_REGEX));
  });
}

export default CsvValidator;
