const SPECIAL_PLURALS: { [singular: string]: string } =
  { is: 'are', person: 'people', 'this': 'these' };

function pluralize(count: number, singular: string, plural?: string): string {
  return pluralized(count, singular, plural);
}

function pluralizeWithCount(
  count: number,
  singular: string,
  plural?: string,
): string {
  return `${count} ${pluralized(count, singular, plural)}`;
}

function pluralizeWithVerb(verb: string, count: number, noun: string): string {
  return `${pluralized(count, verb)} ${count} ${pluralized(count, noun)}`;
}

// private

function pluralized(count: number, singular: string, plural?: string): string {
  if (count === 1) {
    return singular;
  }
  return plural || SPECIAL_PLURALS[singular] || `${singular}s`;
}

export { pluralize, pluralizeWithCount, pluralizeWithVerb };
