// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';

import $template from 'src/helpers/dollar_template';
import { findEl, findEls } from 'src/helpers/finders';
import valToString from 'src/helpers/val_to_string';

class OrganizationSearchController extends Controller {
  $error: JQuery;
  $params: JQuery;
  $results: JQuery;
  $searchForm: JQuery;

  get searchForm(): HTMLFormElement {
    return findEl(this.element, 'form', '.domain-search');
  }

  get results(): HTMLElement {
    return findEl(this.element, 'div', '.search-results');
  }

  get params(): HTMLElement[] {
    return findEls(this.element, 'input', '.search-param');
  }

  get error(): HTMLElement {
    return findEl(this.element, 'div', '.org-error');
  }

  connect(): void {
    this.validateParams = this.validateParams.bind(this);
    this.successCallback = this.successCallback.bind(this);
    this.errorCallback = this.errorCallback.bind(this);

    this.$searchForm = $<HTMLFormElement>(this.searchForm);
    this.$results = $(this.results);
    this.$params = $(this.params);
    this.$error = $(this.error);

    this.$searchForm.on('ajax:success', this.successCallback)
      .on('ajax:error', this.errorCallback)
      .on('submit', this.validateParams);
  }

  validateParams(): boolean {
    const validParams = Array.from(this.$params).every((param) => {
      return valToString($(param).val()) !== '';
    });

    if (validParams) { this.$error.hide(); } else {
      this.showError(
        'Make sure you have a domain, organization name, and short name filled in',
      );
    }

    return validParams;
  }

  successCallback(event): void {
    const [data] = event.detail;
    const { data: organization }: { data: Organization } = data;

    const templateParams = {
      domain: organization.domain,
      name: organization.name,
      shortName: organization.shortName,
    };
    const $output = $template('results-template', templateParams);
    const entries: string[] = [];

    if (organization.users.length === 0) {
      $output.find('.display').append($template('no-results-template'));
    } else {
      const $table = $template('result-table-template');

      organization.users.forEach((user) => {
        entries.push($template('result-row-template', user));
      });

      $output.find('.display').append($table);
      $table.append(entries);
    }

    this.$results.empty().append($output).show();
  }

  errorCallback(event): void {
    const [data] = event.detail;

    this.showError(data.errors[0].title);
  }

  showError(error): void {
    this.$error.show().empty().append(error);
    this.$results.hide();
  }
}

export default OrganizationSearchController;
