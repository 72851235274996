// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';

import Location from 'src/helpers/location';

class MultiselectCampaignsController extends Controller {
  connect(): void {
    document.addEventListener('submit', this.removeBeforeUnload);
  }

  removeBeforeUnload(): void {
    Location.removeBeforeUnload('multiselect-campaigns');
  }

  addBeforeUnload(): void {
    Location.addBeforeUnload('multiselect-campaigns');
  }
}

export default MultiselectCampaignsController;
