import { FORMULA_FIELD_REGEX } from 'src/constants';

class FormulaDecorator {
  formulaString: string;

  constructor(formulaString: string) {
    this.formulaString = formulaString;
  }

  getFormulaString(): string {
    return this.formulaString;
  }

  getVariables(): RegExpMatchArray | [] {
    return this.formulaString.match(FORMULA_FIELD_REGEX) || [];
  }

  getFieldNumbers(): number[] {
    return this.getVariables().map(getFieldNumberFromVariable);
  }
}

// private

function getFieldNumberFromVariable(variable: string): number {
  return parseFloat(variable.slice(1));
}

export default FormulaDecorator;
