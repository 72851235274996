function setTooltip(element: JQuery, message: string): void {
  const $element = $(element);

  if ($element.prev('.tooltip--error').text() === message) { return; }

  removeAllTooltips();
  const $tooltip = $('<div>').addClass('tooltip--error').html(message);

  $element.before($tooltip);
}

function removeTooltip(element: JQuery, message: string): void {
  const $tooltip = $(element).prev('.tooltip--error');

  if ($tooltip.text() === message) { $tooltip.remove(); }
}

function removeAllTooltips(): void {
  $('.tooltip--error').remove();
}

export { setTooltip, removeAllTooltips, removeTooltip };
