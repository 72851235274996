// @ts-strict-ignore
import { fieldFormats } from 'src/fields/config/formats';

const FormatValidator = {
  validate(format, text): string {
    const validation = fieldFormats[format];

    if (text.trim() === '' || validation.regex.test(text)) { return null; }

    return errorMsg(format, validation);
  },
};

// private

function errorMsg(format, validation): string {
  let errorMessage = `Need ${validation.type}`;

  if (validation.formatInWarning) {
    errorMessage += ` in format: ${format}`;
  }

  return errorMessage;
}

export default FormatValidator;
