// @ts-strict-ignore
class BoundingBox {
  coordinate1: Coordinate;
  coordinate2: Coordinate;
  right: number;
  bottom: number;
  left: number;
  top: number;
  width: number;
  height: number;

  setCoordinate1(coordinate: Coordinate): void {
    this.coordinate1 = coordinate;
    this.updateCalculatedAttributes();
  }

  setCoordinate2(coordinate: Coordinate): void {
    this.coordinate2 = coordinate;
    this.updateCalculatedAttributes();
  }

  isOverlapping(other: BoundingBox): boolean {
    return !(
      this.right < other.left ||
      this.bottom < other.top ||
      this.left > other.right ||
      this.top > other.bottom
    );
  }

  updateCalculatedAttributes(): void {
    if (!this.coordinate1 || !this.coordinate2) { return; }

    this.left = Math.min(this.coordinate1.x, this.coordinate2.x);
    this.right = Math.max(this.coordinate1.x, this.coordinate2.x);
    this.top = Math.min(this.coordinate1.y, this.coordinate2.y);
    this.bottom = Math.max(this.coordinate1.y, this.coordinate2.y);

    this.width = this.right - this.left;
    this.height = this.bottom - this.top;
  }
}

export default BoundingBox;
