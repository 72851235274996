// @ts-strict-ignore
import bindAll from 'lodash/bindAll';

import { ClearFields, CopyFields } from 'controllers/doc_editor_controller';
import DocEditorSingleStore from 'src/chux/doc_editor/single_store';
import Dialog from 'src/helpers/dialog';
import { findEl } from 'src/helpers/finders';
import assertInclusion from 'src/helpers/assert_inclusion';
import valToString from 'src/helpers/val_to_string';

const BADGE_VALUES: BadgeType[] = [
  'editable',
  'field_logic',
  'field_number',
  'none',
  'prefill',
  'step_number',
];

type OptionsMenuSubmitParams = {
  doc: {
    fontSize: number;
    hasAttachments: '0' | '1';
    requireAttachments: '0' | '1';
    routingInstructions: string | null;
  };
};

class OptionsMenu {
  $element: JQuery;
  element: HTMLElement;
  $fromDoc: JQuery;
  $fontSelect: JQuery;
  $badgeSelect: JQuery;
  $routingTextarea: JQuery;
  $clearFieldsButton: JQuery;
  clearFieldsCallback: ClearFields;
  copyFieldsCallback: CopyFields;

  constructor(element: HTMLElement, params) {
    this.$element = $(element);
    this.element = element;
    this.copyFieldsCallback = params.copyFields;
    this.clearFieldsCallback = params.clearFields;

    bindAll(
      this,
      'clearFields',
      'copyFields',
      'fontSizeChange',
      'updateBadgeType',
      'requireAttachments',
      'setHasAttachments',
    );

    // prevent menu from closing when an option is clicked
    $(window.document).on('click', '.dropdown-menu', (event) => {
      if (event.target.dataset.turboFrame === 'dialog-container') {
        return;
      }
      event.stopPropagation();
    });

    this.$fromDoc = $(this.fromDoc);
    this.$clearFieldsButton = $(this.clearFieldsButton);
    this.$fontSelect = $(this.fontSelect);
    this.$badgeSelect = $(this.badgeSelect);
    this.$routingTextarea = $(this.routingTextarea);

    // event tracking
    this.$clearFieldsButton.on('click', this.clearFields);
    this.$fromDoc.on('change', this.copyFields);
    this.$fontSelect.on('change', this.fontSizeChange);
    this.$badgeSelect.on('change', this.updateBadgeType);
    this.$element.find('#has_attachments').on('change', this.setHasAttachments);

    const requireAttachments = this.$element.find('#require_attachments');

    requireAttachments.on('change', this.requireAttachments);

    this.updateBadgeType();
  }

  get fromDoc(): HTMLSelectElement {
    return findEl(this.element, 'select', '#from_doc');
  }

  get clearFieldsButton(): HTMLElement {
    return findEl(this.element, 'button', '.clear-fields');
  }

  get fontSelect(): HTMLElement {
    return findEl(this.element, 'select', '.font_select');
  }

  get badgeSelect(): HTMLElement {
    return findEl(this.element, 'select', '#badge_type');
  }

  get routingInstructions(): string | null {
    return this.routingTextarea ? this.routingTextarea.value : null;
  }

  get routingTextarea(): HTMLTextAreaElement | null {
    return this.element.querySelector('textarea#routing_instructions');
  }

  setHasAttachments(event: JQuery.ChangeEvent): void {
    const isChecked = $(event.currentTarget).prop('checked');

    if (!isChecked) {
      $('#require_attachments').prop('checked', isChecked);

      DocEditorSingleStore.update({ requireAttachments: isChecked });
    }

    DocEditorSingleStore.update({ hasAttachments: isChecked });
  }

  requireAttachments(event: JQuery.ChangeEvent): void {
    const isRequired = $(event.currentTarget).prop('checked');

    if (isRequired) {
      $('#has_attachments').prop('checked', isRequired);

      DocEditorSingleStore.update({ hasAttachments: isRequired });
    }

    DocEditorSingleStore.update({ requireAttachments: isRequired });
  }

  copyFields(): void {
    const $selectedOption = this.$fromDoc.find('option:selected');
    const val = $selectedOption.val();

    if (!val) { return; }

    const msg = 'Are you sure you want to copy fields from ' +
      `${$selectedOption.text()}?\n\n` +
      'This will clear any fields that are currently on your ' +
      'document, and you will have a chance to save to confirm.';

    if (Dialog.confirm(msg)) {
      this.copyFieldsCallback(Number(val));
    } else {
      this.fromDoc.selectedIndex = 0;
    }
  }

  clearFields(): void {
    const msg = 'Are you sure you want to clear all fields from the doc?\n\n' +
      'Be sure to save any changes you make after your ' +
      'fields are cleared.';

    if (Dialog.confirm(msg)) {
      this.clearFieldsCallback();
    }
  }

  fontSizeChange(): void {
    const fontSize = parseInt(this.$fontSelect.val() as string, 10);

    DocEditorSingleStore.update({ fontSize });
  }

  updateBadgeType(): void {
    const $selectedOption = this.$badgeSelect.find('option:selected');
    const badgeType =
      assertInclusion(BADGE_VALUES, valToString($selectedOption.val()));

    DocEditorSingleStore.update({ badgeType });
  }

  getSubmitParams(): OptionsMenuSubmitParams {
    const hasAttachments =
        this.$element.find('#has_attachments').prop('checked') ? '1' : '0';
    const requireAttachments =
        this.$element.find('#require_attachments').prop('checked') ? '1' : '0';

    return {
      doc: {
        fontSize: null,
        hasAttachments,
        requireAttachments,
        routingInstructions: this.routingInstructions,
      },
    };
  }
}

export default OptionsMenu;
export type { OptionsMenuSubmitParams };
