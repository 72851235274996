// @ts-strict-ignore
import React, { ReactNode } from 'react';

import DocEditorSingleStore from 'src/chux/doc_editor/single_store';
import { BoundRemoveFields, BoundUpdateFields }
  from 'src/chux/editable_fields/store';
import Checkbox from 'src/doc_editor/fields/components/checkbox';
import Badge from 'src/doc_editor/fields/components/common/badge';
import Medallion from 'src/doc_editor/fields/components/common/medallion';
import RemoveIcon from 'src/doc_editor/fields/components/common/remove_icon';
import RequiredCheckbox from
  'src/doc_editor/fields/components/common/required_checkbox';
import Attachment from 'src/doc_editor/fields/components/attachment';
import Date from 'src/doc_editor/fields/components/date';
import Dropdown from 'src/doc_editor/fields/components/dropdown';
import Formula from 'src/doc_editor/fields/components/formula';
import Input from 'src/doc_editor/fields/components/input';
import Prefill from 'src/doc_editor/fields/components/prefill';
import ReferenceNumber from 'src/doc_editor/fields/components/reference_number';
import Signature from 'src/doc_editor/fields/components/signature';
import StaticText from 'src/doc_editor/fields/components/static_text';
import Time from 'src/doc_editor/fields/components/time';
import isFieldRequireable from 'src/doc_editor/helpers/is_field_requireable';
import warningMessage from 'src/doc_editor/helpers/field_deletion_warning_message';

const subMenus = {
  Attachment,
  Checkbox,
  Date,
  Dropdown,
  Formula,
  Input,
  Prefill,
  ReferenceNumber,
  Signature,
  StaticText,
  Time,
};

type Props = {
  allowedInNumberFormula: boolean;
  store: typeof DocEditorSingleStore;
  type: FieldType;
  allowEdits: boolean;
  editableBySpecialApprover: boolean;
  content: string;
  dataSourceColumn: string | null;
  format?: string;
  height?: number;
  hasFieldRule?: boolean;
  isFilterField?: boolean;
  isSubsetField?: boolean;
  isTriggerField?: boolean;
  mode?: FieldMode;
  number: number;
  removeFields: BoundRemoveFields;
  required?: boolean;
  selected: boolean;
  stepSeq: number | null;
  updateFields: BoundUpdateFields;
  width?: number;
  label: string;
};

type State = {
  fontSize: number;
  activeFormulaMode: 'number' | 'time' | null;
  activeFormulaNumber: number;
  indexedHighlightedFields: FieldsByNumber;
  showMedallions: boolean;
  badgeType: BadgeType;
};

class Field extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.remove = this.remove.bind(this);
    this.state = this.props.store.getState();
  }

  componentDidMount(): void {
    const { store } = this.props;

    store.subscribe(() => { this.setState(store.getState()); });
  }

  render(): ReactNode {
    const {
      type,
      number,
      content,
      dataSourceColumn,
      format,
      hasFieldRule,
      height,
      width,
      stepSeq,
      isSubsetField,
      required,
      allowedInNumberFormula,
      editableBySpecialApprover,
      updateFields,
      allowEdits,
      mode,
      selected,
      label,
    } = this.props;
    const {
      fontSize,
      activeFormulaMode,
      activeFormulaNumber,
      indexedHighlightedFields,
      showMedallions,
      badgeType,
    } = this.state;
    const SubFieldComponent = subMenus[type];

    return (
      <span data-field-number={number}>
        <SubFieldComponent
          content={content}
          fontSize={fontSize}
          format={format}
          height={height}
          isSubsetField={isSubsetField}
          label={label}
          mode={mode}
          number={number}
          required={required}
          selected={selected}
          updateFields={updateFields}
          width={width}
        />
        <Badge
          activeFormulaNumber={activeFormulaNumber}
          badgeType={badgeType}
          editableBySpecialApprover={editableBySpecialApprover}
          hasFieldRule={hasFieldRule}
          number={number}
          prefillable={Boolean(dataSourceColumn)}
          seq={stepSeq}
        />
        <Medallion
          activeFormulaMode={activeFormulaMode}
          activeFormulaNumber={activeFormulaNumber}
          allowedInNumberFormula={allowedInNumberFormula}
          indexedHighlightedFields={indexedHighlightedFields}
          number={number}
          showMedallions={showMedallions}
          type={type}
        />
        <div className='field-hoverable'>
          <RemoveIcon remove={this.remove} />
          {
            isFieldRequireable({ allowEdits, mode, type }) &&
            <RequiredCheckbox
              number={number}
              required={required}
              updateFields={updateFields}
            />
          }
          <div className='draggable-tab-container'>
            <div className='draggable-tab'></div>
          </div>
        </div>
      </span>
    );
  }

  remove(): void {
    const { removeFields, number } = this.props;

    const warning = this.checkForWarning();

    if (warning) {
      if (!confirm(warning)) { return; }
    }

    removeFields({ number });
  }

  // private

  checkForWarning(): string | null {
    const { isSubsetField, isTriggerField, isFilterField } = this.props;
    const warning = warningMessage(isSubsetField, isTriggerField, isFilterField);

    return warning || null;
  }
}

export default Field;
export type { Props };
