import { Controller } from '@hotwired/stimulus';

import { supportEmail } from 'src/constants';
import { fetchGet } from 'src/helpers/fetch';
import Location from 'src/helpers/location';
import notify from 'src/helpers/notify';

class DocEditorLoaderController extends Controller {
  connect(): void {
    const $loader = $(this.element);

    //  1) get doc ID
    const docId = parseInt($loader.data('id'), 10);

    //  2) ask server if the document is ready
    let triesSoFar = 0;
    const triesUntilFail = 50;

    const intervalId = window.setInterval(() => {
      fetchGet('/docs/status', { id: docId }).then(({ data: doc }) => {
        triesSoFar += 1;
        if (!doc.isProcessing) {
          Location.reload();
          window.clearInterval(intervalId);
        } else if (triesSoFar === triesUntilFail) {
          const myHtml = [
            '<h4>Your document is taking longer than usual to process. ',
            'This may be because your file size is very large.<br/>',
            '<strong>Please wait a few minutes and refresh the page. ',
            'If your document has many pages, please wait at least 5 minutes.',
            '</strong>',
            '<br><br>If you encounter this message again after refreshing, ',
            'there may have been an error processing your document.<br/>',
            `Then please contact <strong>${supportEmail}</strong> `,
            'for assistance.</h4>',
          ].join('');

          $loader.find('img').hide();
          $('.alert-info').removeClass('alert-info').addClass('alert-danger')
            .html(myHtml);
        }
      }, notify);
    }, 1000);
  }
}

export default DocEditorLoaderController;
