import React, { ReactNode } from 'react';

import Icon from 'src/common/components/icon';

type Props = {
  copyText: string;
  id: string;
  text: string;
};

type State = {
  copied: boolean;
};

class CopyButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.copy = this.copy.bind(this);

    this.state = { copied: false };
  }

  render(): ReactNode {
    const { text, id } = this.props;
    const { copied } = this.state;

    return (
      <button
        className='btn btn-sm btn-default'
        id={id}
        type='button'
        onClick={this.copy}
      >
        { copied &&
          <Icon
            iconClassName='text-success wb-u-margin-r-1'
            name='check'
            size='1-2x'
          /> }
        { text }
      </button>
    );
  }

  copy(): void {
    navigator.clipboard.writeText(this.props.copyText);
    this.setState({ copied: true });

    setTimeout(() => {
      this.setState({ copied: false });
    }, 2000);
  }
}

export default CopyButton;
export type { Props };
