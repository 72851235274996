// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';

import { assert } from 'src/helpers/assertion';
import Dialog from 'src/helpers/dialog';
import { fetchDelete } from 'src/helpers/fetch';

class RowDeleteLinkController extends Controller<HTMLElement> {
  $element: JQuery;

  connect(): void {
    this.$element = $(this.element);

    this.$element.on('click', this.sendDeleteRequest.bind(this));
  }

  sendDeleteRequest(event: Event): void {
    event.stopPropagation();
    event.preventDefault();

    const confirmMessage = 'Are you sure you want to remove this attachment?';
    const confirmed = Dialog.confirm(confirmMessage);

    if (!confirmed) { return; }

    this.$element.closest('tr').fadeOut().remove();

    const url = assert(this.$element.attr('href'));

    fetchDelete(url);
  }
}

export default RowDeleteLinkController;
