// @ts-strict-ignore
import React, { ReactNode } from 'react';

import Icon from 'src/common/components/icon';
import CopyButton from 'src/doc_editor/fields/components/common/copy_button';
import Dialog from 'src/helpers/dialog';

type SharedDropdown = {
  id: number;
  name: string;
};

type Props = {
  choices: string[];
  dropdowns: SharedDropdown[];
  number: number;
  setChoices: (choices: string[]) => void;
  setSortChoices: (sorted: boolean) => void;
  sortChoices: boolean;
  toggleDropdownMode: () => void;
};

type State = never;

class DropdownContextMenu extends React.Component<Props, State> {
  private choicesInput = React.createRef<HTMLInputElement>();

  constructor(props: Props) {
    super(props);

    this.addValueOnEnter = this.addValueOnEnter.bind(this);
    this.addValue = this.addValue.bind(this);
    this.removeAll = this.removeAll.bind(this);
    this.removeValue = this.removeValue.bind(this);
    this.renderValue = this.renderValue.bind(this);
    this.renderCopyButton = this.renderCopyButton.bind(this);
    this.renderToggleDropdownMode = this.renderToggleDropdownMode.bind(this);
  }

  renderCopyButton(): React.JSX.Element {
    const { choices, number } = this.props;

    return (
      <div className='text-center wb-u-margin-v-2'>
        <CopyButton
          copyText={choices.join(';')}
          id={`dropdown-copy-button-${number}`}
          text='Copy choices to clipboard'
        />
      </div>
    );
  }

  renderToggleDropdownMode(): React.JSX.Element {
    const { toggleDropdownMode, number } = this.props;

    return (
      <button
        className='wb-button wb-button--small'
        id={`toggle-dropdown-mode-button-${number}`}
        type='button'
        onClick={toggleDropdownMode}
      >
        <span className='dropdown-mode-toggle__text wb-u-whitespace-no-wrap'>
          Use centralized dropdown
        </span>
      </button>
    );
  }

  render(): ReactNode {
    const { choices, dropdowns, sortChoices, setSortChoices, number } = this.props;

    return (
      <div className='dropdown-context'>
        <p className='wb-text wb-text--small text-center'>
          Type in the box and click &quot;Add&quot; for each dropdown choice.
        </p>
        <div className='input-append'>
          <input
            ref={this.choicesInput}
            className='add-dropdown-value'
            id={`dropdown-text-${number}`}
            name='dropdown-text'
            placeholder='Separate multiple with ;'
            onKeyUp={this.addValueOnEnter}
          />
          <button
            className='btn btn-sm btn-primary add-entry'
            type='button'
            onClick={this.addValue}
          >
            Add
          </button>
          <a
            className='wb-u-margin-t-1 wb-text--small pull-right'
            href='#!'
            onClick={this.removeAll}
          >
            Remove all
          </a>
        </div>
        <table className='dropdown-values'>
          <tbody>{choices.map(this.renderValue)}</tbody>
        </table>
        <label className='sort-checkbox labeled-checkbox'>
          <input
            defaultChecked={sortChoices}
            type='checkbox'
            onChange={(event): void => { setSortChoices(event.target.checked); }}
          />
          Sort choices
        </label>
        {dropdowns.length > 0 && this.renderToggleDropdownMode()}
        {choices.length > 0 && this.renderCopyButton()}
      </div>
    );
  }

  addValueOnEnter(event: React.KeyboardEvent): void {
    if (event.key === 'Enter') { this.addValue(); }
  }

  addValue(): void {
    const { choices: currentChoices, setChoices } = this.props;
    const choicesString = this.choicesInput.current.value;
    const newChoices = choicesString.split(';').filter((option) => {
      return option.trim();
    });
    const uniqChoices = new Set([...currentChoices, ...newChoices]);

    setChoices(Array.from(uniqChoices));
    this.choicesInput.current.value = '';
  }

  removeValue(toRemove: string): void {
    const { choices, setChoices } = this.props;
    const newChoices = choices.filter((choice) => { return choice !== toRemove; });

    setChoices(newChoices);
  }

  removeAll(): void {
    const { setChoices } = this.props;
    const confirmMessage = 'All dropdown choices will be removed. Continue?';

    if (Dialog.confirm(confirmMessage)) {
      setChoices([]);
    }
  }

  renderValue(value: string): React.JSX.Element {
    return (
      <tr key={value} className='dropdown-row'>
        <td className='dropdown-entry'>{value}</td>
        <td className='text-center'>
          <div className='dropdown-remove'>
            <button
              className='link-text link-no-underline'
              type='button'
              onClick={(): void => { this.removeValue(value); }}
            >
              <span className='sr-only'>{`Remove Option ${value}`}</span>
              <Icon name='times' />
            </button>
          </div>
        </td>
      </tr>
    );
  }
}

export default DropdownContextMenu;
export type { Props };
