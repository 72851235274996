// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['pausedMessage', 'previewLink', 'submitButton'];

  pausedMessageTarget: HTMLElement;
  previewLinkTarget: HTMLElement;
  submitButtonTarget: HTMLInputElement;

  enableSubmit(): void {
    this.submitButtonTarget.disabled = false;
  }

  hidePausedMessage(): void {
    this.pausedMessageTarget.hidden = true;
  }

  showPausedMessage(): void {
    this.pausedMessageTarget.hidden = false;
  }

  hidePreviewLink(): void {
    this.previewLinkTarget.hidden = true;
  }

  showPreviewLink(): void {
    if (this.previewLinkTarget.dataset.paused === 'true') {
      this.previewLinkTarget.hidden = false;
    }
  }
}
