// @ts-strict-ignore
import React, { ReactNode } from 'react';

import { BoundUpdateFields } from 'src/chux/editable_fields/store';
import Icon from 'src/common/components/icon';
import { fieldFormats } from 'src/fields/config/formats';

type Props = {
  dataSource: DataSource;
  dataSourceColumn: string;
  editable: boolean;
  format?: string;
  mode?: string;
  number: number;
  stepSeq: number;
  type: FieldType;
  updateFields: BoundUpdateFields;
};

const HELP_HTML = {
  date: <span>should have the following format: {boldText('MM/DD/YYYY')}</span>,
  dropdown: <span>should match the dropdown choices above</span>,
  number: <span>should be a number</span>,
  phone: <span>should have the following format: {boldText('xxx-xxx-xxxx')}</span>,
  ssn: <span>should have the following format: {boldText('xxx-xx-xxxx')}</span>,
  time: <span>should have the following format: {boldText('HH:MM AM')}</span>,
};

function boldText(text): React.JSX.Element {
  return <span className='wb-u-font-bold'>{text}</span>;
}

class PrefillSelector extends React.Component<Props, never> {
  constructor(props: Props) {
    super(props);
    this.updatePrefill = this.updatePrefill.bind(this);
    this.renderPrefillHelp = this.renderPrefillHelp.bind(this);
  }

  render(): ReactNode {
    const {
      dataSource,
      dataSourceColumn,
      editable,
      number,
      mode,
      stepSeq,
      type,
    } = this.props;

    if (stepSeq && stepSeq !== 1) { return null; }
    if (type === 'Date' && mode === 'auto_current') { return null; }

    return (
      <React.Fragment>
        <div className='context-menu-prefill col-md-12'>
          <label className='wb-u-font-bold' htmlFor={`field_prefill_${number}`}>
            Prefill Data:
          </label>
          <select
            className='field-prefill-select'
            disabled={!editable}
            id={`field_prefill_${number}`}
            value={dataSourceColumn || ''}
            onChange={this.updatePrefill}
          >
            <option value=''>None</option>
            {dataSource.csvHeaders.map(renderStepOption)}
          </select>
        </div>
        {dataSourceColumn ? this.renderPrefillHelp() : null}
      </React.Fragment>
    );
  }

  updatePrefill(event: React.ChangeEvent<HTMLSelectElement>): void {
    const { number, updateFields } = this.props;

    updateFields({ dataSourceColumn: event.target.value, number });
  }

  renderPrefillHelp(): React.JSX.Element {
    const { type, format, dataSourceColumn } = this.props;
    const formatType = fieldFormats[format]?.type ?? type.toLowerCase();
    const helpString = HELP_HTML[formatType];

    return (
      helpString && (
        <p>
          <Icon iconClassName='wb-u-padding-r-1' name='exclamationCircle' />
          Data in {boldText(dataSourceColumn)} {helpString}
        </p>
      )
    );
  }
}

// private

function renderStepOption(csvColumn): React.JSX.Element {
  return <option key={csvColumn} value={csvColumn}>{csvColumn}</option>;
}

export default PrefillSelector;
export type { Props };
