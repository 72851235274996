// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

import { assert } from 'src/helpers/assertion';

class LockingMenuController extends Controller<HTMLElement> {
  $element: JQuery;
  $placeholder: JQuery;
  menuPosition: JQuery.Coordinates;

  connect(): void {
    this.$element = $(this.element);

    this.fixDiv = this.fixDiv.bind(this);

    // placeholder to occupy space in DOM when menu is taken out of the flow
    this.$placeholder = $('<div class="locking-menu-placeholder"></div>');
    this.menuPosition = assert(this.$element.offset());

    // We want the placeholder to mimic the size of the $element
    this.$placeholder.css('height', assert(this.$element.outerHeight()));
    this.$element.before(this.$placeholder);

    $(window).on('scroll', this.fixDiv);
    this.fixDiv();
  }

  fixDiv(): void {
    const scrollTop = assert($(window).scrollTop());
    const fixed = scrollTop > this.menuPosition.top;

    this.$placeholder.toggleClass('expand', fixed);
    this.$element.toggleClass('menu-locked', fixed);
  }
}

export default LockingMenuController;
