import React, { ReactNode } from 'react';

import Icon from 'src/common/components/icon';
import iconClasses from 'src/helpers/icon';

type Props = {
  question: Question;
  manageQuestionButtons: ReactNode;
};

class StepQuestion extends React.Component<Props, never> {
  render(): ReactNode {
    const { question, manageQuestionButtons } = this.props;

    return (
      <div className='step-content wb-l-split'>
        <div>
          <p className='wb-text wb-text--muted wb-text--small wb-u-margin-b-1'>
            Question
          </p>
          <p className='wb-text wb-u-bold'><b>{question.value}</b></p>
          <p className='wb-text wb-text--muted wb-text--small wb-u-margin-b-1'>
            Answers
          </p>
          { formattedAnswers(question.answers) }
        </div>
        { manageQuestionButtons }
      </div>
    );
  }
}

// private

function formattedAnswers(
  answers: Answer[],
): React.JSX.Element | React.JSX.Element[] {
  if (answers.length === 0) {
    return noAnswers();
  }

  return (
    answers.map((answer) => {
      return answerRow(answer);
    })
  );
}

function noAnswers(): React.JSX.Element {
  return (
    <div className='wb-tile wb-tile--warning wb-l-media'>
      <div className='wb-l-media__figure'>
        <i className={`${iconClasses('warning')} text-warning fa-lg`} />
      </div>
      <div className='wb-l-media__body'>
        <p className='wb-text wb-u-margin-b-0'>
          No answers are provided. Approvers will not be able to route submissions
          to the next step.
        </p>
      </div>
    </div>
  );
}

function answerRow(answer: Answer): React.JSX.Element {
  const { id, value, gotoSeq } = answer;
  const toStep =
    gotoSeq === 'end_route' ? 'Completes form' : `Goes to Step ${gotoSeq}`;

  return (
    <p key={id} className='wb-text'>
      <b>{value}</b>
      <Icon iconClassName='wb-u-margin-h-2 text-brand' name='arrowRight' />
      {toStep}
    </p>
  );
}

export type { Props };

export default StepQuestion;
