import keyBy from 'lodash/keyBy';

import { DATE_REGEX, NUMBER_REGEX, PHONE_REGEX, SSN_REGEX,
  TIME_REGEX } from 'src/constants';

const formats = [
  {
    fixedLength(): number { return this.validExample.length; },
    formatInWarning: true,
    label: 'mm/dd/yyyy',
    regex: DATE_REGEX,
    type: 'date',
    validExample: '10/11/1988',
  },
  {
    fixedLength(): boolean { return false; },
    formatInWarning: false,
    label: 'number',
    regex: NUMBER_REGEX,
    type: 'number',
  },
  {
    fixedLength(): number { return this.validExample.length; },
    formatInWarning: true,
    label: 'xxx-xxx-xxxx',
    regex: PHONE_REGEX,
    type: 'phone',
    validExample: '123-456-7890',
  },
  {
    fixedLength(): number { return this.validExample.length; },
    formatInWarning: true,
    label: 'xxx-xx-xxxx',
    regex: SSN_REGEX,
    type: 'ssn',
    validExample: '123-45-6789',
  },
  {
    fixedLength(): number { return this.validExample.length; },
    formatInWarning: true,
    label: 'HH:MM AM',
    regex: TIME_REGEX,
    type: 'time',
    validExample: '12:34 AM',
  },
];

const fieldFormats = keyBy(formats, 'label');
const inputFormatValidationOptions = formats.filter((format) => {
  return !['date', 'time'].includes(format.type);
});

export { fieldFormats, inputFormatValidationOptions };
