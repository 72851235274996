// @ts-strict-ignore
import FieldModel from 'src/doc_editor/fields/models/field';

const DEFAULTS = {
  content: 'Reference #',
  required: false,
  startingReferenceNumber: '1',
};

class ReferenceNumber extends FieldModel {
  constructor(opts) {
    super({ ...DEFAULTS, ...opts });
  }
}

export default ReferenceNumber;
