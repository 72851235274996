import { assert } from 'src/helpers/assertion';
import Location from 'src/helpers/location';

class ResponseRowController {
  $element: JQuery;
  element: HTMLElement;

  constructor(element: HTMLElement) {
    this.$element = $(element);
    this.element = element;

    this.$element.on('click', () => {
      Location.navigateTo(assert(this.element.dataset.href));
    });
  }
}

export default ResponseRowController;
