// @ts-strict-ignore
import FormulaDecorator from 'src/decorators/formula';
import {
  dependsOnLaterSteps,
  getDependencyFields,
  missingDependencyFields,
} from 'src/doc_editor/fields/helpers/formula_validator/shared_helpers';

const TimeFormulaValidator = {
  validate(
    formulaField: Field,
    fieldsByNumber: FieldsByNumber,
    stepsById: IndexedSteps,
  ): string[] {
    const errors: string[] = [];
    const { content: formulaString } = formulaField;
    const formula = new FormulaDecorator(formulaString);
    const dependencyVariables = formula.getVariables();

    if (invalidTimeFormulaFormat(formulaString, dependencyVariables)) {
      errors.push('Please enter start and end time fields.');
      return errors;
    }

    const missingFields = missingDependencyFields(formulaField, fieldsByNumber);

    if (missingFields.length > 0) {
      const fieldsString = missingFields.join(', ');

      errors.push(
        `Referencing a field that is invalid or missing - ${fieldsString}`,
      );
    }

    const dependencyFields = getDependencyFields(formulaField, fieldsByNumber);

    if (dependencyFields.some((field) => { return field.type !== 'Time'; })) {
      errors.push('Formula depends on non-time field.');
    }

    if (dependsOnLaterSteps(formulaField, fieldsByNumber, stepsById)) {
      errors.push('Formula depends on fields from later steps.');
    }

    return errors;
  },
};

// private

function invalidTimeFormulaFormat(
  formulaString: string,
  dependencyVariables,
): boolean {
  if (dependencyVariables.length !== 2) { return true; }

  const [endTime, startTime] = dependencyVariables;

  return formulaString !== `${endTime}-${startTime}`;
}

export default TimeFormulaValidator;
