// @ts-strict-ignore
import React, { useState } from 'react';

import CustomChoices from './dropdowns/custom_choices';
import SharedSelector from './dropdowns/shared_selector';

import { BoundUpdateFields } from 'src/chux/editable_fields/store';
import { helpCenterUrls } from 'src/constants';

type SharedDropdown = {
  id: number;
  name: string;
};

type Props = {
  choices: string[];
  docId: string;
  dropdownId?: number;
  dropdowns: SharedDropdown[];
  isSubsetField?: boolean;
  isTriggerField?: boolean;
  number: number;
  sortChoices: boolean;
  updateFields: BoundUpdateFields;
};

function Dropdown(props: Props): React.JSX.Element {
  const {
    choices,
    docId,
    dropdownId,
    dropdowns,
    isSubsetField,
    isTriggerField,
    number,
    sortChoices,
    updateFields,
  } = props;

  const [customChoices, setCustomChoices] = useState(choices);
  const [selectedDropdownId, setSelectedDropdownId] = useState(dropdownId);
  const [mode, setMode] = useState(dropdownId ? 'shared' : 'custom');

  function toggleDropdownMode(): void {
    if (mode === 'shared') {
      updateFields({ content: customChoices.join(';'), dropdownId: null, number });
      setMode('custom');
    } else {
      updateFields({ content: '', dropdownId: selectedDropdownId, number });
      setMode('shared');
    }
  }

  function selectDropdown(newDropdownId): void {
    setSelectedDropdownId(newDropdownId);
    updateFields({ dropdownId: newDropdownId, number });
  }

  function setDropdownContent(centeralizedChoices: string[]): void {
    updateFields({ content: centeralizedChoices.join(';'), number });
  }

  function setChoices(newChoices: string[]): void {
    setCustomChoices(newChoices);
    updateFields({ content: newChoices.join(';'), number });
  }

  function setSortChoices(sorted: boolean): void {
    updateFields({ number, sortDropdownChoices: sorted });
  }

  function renderReusableSelector(): React.JSX.Element {
    return (
      <SharedSelector
        docId={docId}
        dropdownId={dropdownId}
        dropdowns={dropdowns}
        number={number}
        setDropdownContent={setDropdownContent}
        setSelectedDropdown={selectDropdown}
        toggleDropdownMode={toggleDropdownMode}
      />
    );
  }

  function renderCustomChoices(): React.JSX.Element {
    return (
      <CustomChoices
        choices={choices}
        dropdowns={dropdowns}
        number={number}
        setChoices={setChoices}
        setSortChoices={setSortChoices}
        sortChoices={sortChoices}
        toggleDropdownMode={toggleDropdownMode}
      />
    );
  }

  return (
    <div className='dropdown-context'>
      {
        Boolean(isSubsetField) && (
          <div className='alert alert-danger text-sm'>
            <b>CAREFUL:</b> This field is used to give people access to submissions.
            Updating choices could affect people&apos;s access.
            <br />
            <a
              className='wb-u-margin-l-1'
              href={helpCenterUrls.editPermissionFields}
              rel='noopener noreferrer'
              target='_blank'
            >
              Learn more
            </a>
          </div>
        )
      }
      {
        Boolean(isTriggerField) && (
          <div className='alert alert-danger text-sm'>
            <b>CAREFUL:</b> This field is used to determine field logic for other
            fields. Updating choices could affect what people can fill out.
            <br />
          </div>
        )
      }
      { mode === 'shared' ? renderReusableSelector() : renderCustomChoices() }
    </div>
  );
}

export default Dropdown;
export type { Props };
