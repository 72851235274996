// @ts-strict-ignore
import React, { ReactNode } from 'react';

import { BoundUpdateFields } from 'src/chux/editable_fields/store';

type Props = {
  number: number;
  showSecurityMetadata: boolean | null;
  updateFields: BoundUpdateFields;
};

class SignatureContextMenu extends React.Component<Props, never> {
  constructor(props: Props) {
    super(props);

    this.updateShowSecurityMetadata = this.updateShowSecurityMetadata.bind(this);
  }

  render(): ReactNode {
    const { showSecurityMetadata } = this.props;

    return (
      <label className='sensitive-checkbox labeled-checkbox'>
        <input
          defaultChecked={showSecurityMetadata}
          type='checkbox'
          onChange={this.updateShowSecurityMetadata}
        />
        Show signature details
      </label>
    );
  }

  updateShowSecurityMetadata(event: React.ChangeEvent<HTMLInputElement>): void {
    const { number, updateFields } = this.props;

    updateFields({ number, showSecurityMetadata: event.target.checked });
  }
}

export default SignatureContextMenu;
export type { Props };
