function sanitizeInput(...args: JQuery[]): void {
  args.forEach((arg) => {
    sanitize(arg);
  });
}

// private

function sanitize($input: JQuery): void {
  $input.on('paste', (event) => {
    const $currentInput = $(event.currentTarget);

    window.setTimeout(() => {
      $currentInput.text($currentInput.text().trim());
    }, 5);
  });
}

export default sanitizeInput;
