// @ts-strict-ignore
import React, { ReactNode } from 'react';

import Icon from 'src/common/components/icon';
import { defaultPageSize } from 'src/constants';

type Props = {
  campaign: Campaign;
  canGeneratePdf: boolean;
  totalCount: number;
  totalPages: number;
  statusGroup?: StatusGroup;
  responses?: StoreResponses;
};

class ResponsesBulkActions extends React.Component<Props, State> {
  render(): ReactNode {
    const { statusGroup } = this.props;

    return (
      <div
        className='responses-bulk-actions hide'
        data-responses-selection-target='bulkActionToolbar'
      >
        <strong data-responses-selection-target='selectedCount' />
        {this.selectLink()}
        <ul className='list-actions'>
          {this.bulkRemindersElement()}
          {this.bulkExportElement()}
          {statusGroup === 'archived' ? this.bulkUnarchive() : this.bulkArchive()}
        </ul>
      </div>
    );
  }

  bulkRemindersElement(): React.JSX.Element {
    const { campaign, statusGroup } = this.props;

    if (campaign.userPermissions.includes('send_reminder') &&
      ['pending', 'active', undefined].includes(statusGroup) &&
      campaign.isActive) {
      return (
        <li>
          <form
            action={`/campaigns/${campaign.id}/reminders/new`}
            data-action='dialog#showLoader'
            data-turbo='true'
            data-turbo-frame='dialog-container'
            method='GET'
          >
            <button
              className='hidden-xs link-text'
              data-action='responses-selection#setHiddenField'
              type='submit'
            >
              <input name='selected_responses_count' type='hidden' />
              <input name='form_set_data' type='hidden' />
              <Icon name='send' />
              Send Reminder
            </button>
          </form>
        </li>
      );
    }
    return null;
  }

  bulkExportElement(): React.JSX.Element {
    const { canGeneratePdf } = this.props;

    return (
      <li className='dropdown'>
        <button
          className='hidden-xs link-text dropdown-toggle'
          data-toggle='dropdown'
          type='button'
        >
          <Icon name='download' />
          Download
        </button>
        <ul className='dropdown-menu dropdown-menu-right'>
          {this.bulkCsvLink()}
          {this.bulkPdfLink()}
          {this.bulkZipLink()}
          {!canGeneratePdf && disabledMessage()}
        </ul>
      </li>
    );
  }

  bulkCsvLink(): React.JSX.Element {
    const { campaign } = this.props;

    return (
      <li>
        <form
          action={`/campaigns/${campaign.id}/csv_reports/new`}
          data-action='dialog#showLoader'
          data-turbo='true'
          data-turbo-frame='dialog-container'
          method='GET'
        >
          <button
            className='dropdown-menu__link'
            data-action='responses-selection#setHiddenField'
            type='submit'
          >
            <input name='form_set_data' type='hidden' />
            As a spreadsheet
          </button>
        </form>
      </li>
    );
  }

  bulkPdfLink(): React.JSX.Element {
    const { campaign, canGeneratePdf } = this.props;

    return (
      <li className={canGeneratePdf ? '' : 'disabled'}>
        <form
          action={`/campaigns/${campaign.id}/pdf_reports/new`}
          data-action='dialog#showLoader'
          data-turbo='true'
          data-turbo-frame='dialog-container'
          method='GET'
        >
          <button
            className='dropdown-menu__link'
            data-action='responses-selection#setHiddenField'
            disabled={!canGeneratePdf}
            type='submit'
          >
            <input name='form_set_data' type='hidden' />
            As single PDF file
          </button>
        </form>
      </li>
    );
  }

  bulkZipLink(): React.JSX.Element {
    const { campaign, canGeneratePdf } = this.props;

    return (
      <li className={canGeneratePdf ? '' : 'disabled'}>
        <form
          action={`/campaigns/${campaign.id}/zip_reports/new`}
          data-action='dialog#showLoader'
          data-turbo='true'
          data-turbo-frame='dialog-container'
          method='GET'
        >
          <button
            className='dropdown-menu__link'
            data-action='responses-selection#setHiddenField'
            disabled={!canGeneratePdf}
            type='submit'
          >
            <input name='form_set_data' type='hidden' />
            As individual PDF files
          </button>
        </form>
      </li>
    );
  }

  bulkArchive(): React.JSX.Element | null {
    const { campaign, statusGroup } = this.props;

    if (!campaign.userPermissions.includes('archive_form') ||
      statusGroup === 'cancelled') {
      return null;
    }

    return this.archiveButton();
  }

  archiveButton(): React.JSX.Element {
    const { campaign } = this.props;

    return (
      <li>
        <form
          action={`/campaigns/${campaign.id}/form_archives/new`}
          data-action='dialog#showLoader'
          data-turbo='true'
          data-turbo-frame='dialog-container'
          method='GET'
        >
          <button
            className='hidden-xs link-text'
            data-action='responses-selection#setHiddenField'
            type='submit'
          >
            <input name='form_set_data' type='hidden' />
            <input name='selected_responses_count' type='hidden' />
            <input name='status' type='hidden' value='archived' />
            <Icon name='archive' />
            Archive
          </button>
        </form>
      </li>
    );
  }

  bulkUnarchive(): React.JSX.Element | null {
    const { campaign } = this.props;

    if (!campaign.userPermissions.includes('archive_form')) { return null; }

    return this.unarchiveButton();
  }

  unarchiveButton(): React.JSX.Element {
    const { campaign } = this.props;

    return (
      <li>
        <form
          action={`/campaigns/${campaign.id}/form_archives/new`}
          data-action='dialog#showLoader'
          data-turbo='true'
          data-turbo-frame='dialog-container'
          method='GET'
        >
          <button
            className='hidden-xs link-text'
            data-action='responses-selection#setHiddenField'
            type='submit'
          >
            <input name='form_set_data' type='hidden' />
            <input name='selected_responses_count' type='hidden' />
            <input name='status' type='hidden' value='restored' />
            <Icon name='repeat' />
            Unarchive
          </button>
        </form>
      </li>
    );
  }

  selectLink(): React.JSX.Element {
    const { totalCount } = this.props;
    const linkText = `Select all ${totalCount} filtered submissions`;

    return (
      <span>
        <a
          className='select-link'
          data-action='responses-selection#selectAllAcrossPages'
          data-responses-selection-target='selectAcrossPages'
          href='#!'
        >
          {linkText}
        </a>
        <a
          className='select-link'
          data-action='responses-selection#deselectAllAcrossPages'
          data-responses-selection-target='deselectAcrossPages'
          href='#!'
        >
          Clear
        </a>
      </span>
    );
  }
}

// private

function disabledMessage(): React.JSX.Element[] {
  const message = 'PDF download unavailable for more than ' +
    `${defaultPageSize} submissions.`;

  return [
    <li key={0} className='divider spacer' role='separator'></li>,
    <li key={1} className='dropdown-header'>{message}</li>,
  ];
}

export default ResponsesBulkActions;
export type { Props };
