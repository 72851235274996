// @ts-strict-ignore
import React, { useEffect, useState } from 'react';

import CopyButton from 'src/doc_editor/fields/components/common/copy_button';
import { fetchGet } from 'src/helpers/fetch';

type SharedDropdown = {
  id: number;
  name: string;
};

type Props = {
  docId: string;
  dropdownId?: number;
  number: number;
  dropdowns: SharedDropdown[];
  setDropdownContent: (choices: string[]) => void;
  setSelectedDropdown: (dropdownId: string) => void;
  toggleDropdownMode: () => void;
};

function SharedSelector(props: Props): React.JSX.Element {
  const {
    docId,
    dropdownId,
    dropdowns,
    number,
    setDropdownContent,
    setSelectedDropdown,
    toggleDropdownMode,
  } = props;

  const [choices, setChoices] = useState([]);

  useEffect(() => {
    setChoices([]);

    if (dropdownId) {
      const url = `/api/docs/${docId}/dropdowns/${dropdownId}/dropdown_options`;

      fetchGet(url).then(({ data }) => {
        setChoices(data);
        setDropdownContent(data.map((choice) => { return choice.value; }));
      });
    }
  }, [dropdownId]);

  function renderDropdownSelector(): React.JSX.Element {
    return (
      <React.Fragment>
        <label className='wb-label' htmlFor='shared-dropdown-selector'>
          Select Centralized Dropdown
        </label>
        <select
          defaultValue={dropdownId || ''}
          id='shared-dropdown-selector'
          onChange={(event): void => {
            setSelectedDropdown(event.target.value);
          }}
        >
          <option value=''>Select dropdown</option>
          {dropdowns.map((dropdown) => {
            return (
              <option key={dropdown.id} value={dropdown.id}>
                {dropdown.name}
              </option>
            );
          })}
        </select>
      </React.Fragment>
    );
  }

  function renderHelpText(): React.JSX.Element {
    return (
      <p className='wb-tile--info wb-u-padding-a-1'>
        These choices are shared across your organization. Your dropdown will
        update when your form admin changes it.
      </p>
    );
  }

  function renderToggleDropdown(): React.JSX.Element {
    return (
      <button
        className='wb-button wb-button--small wb-u-margin-t-2'
        id={`toggle-dropdown-mode-button-${number}`}
        type='button'
        onClick={toggleDropdownMode}
      >
        <span className='dropdown-mode-toggle__text wb-u-whitespace-no-wrap'>
          Define your own choices
        </span>
      </button>
    );
  }

  function renderChoices(): React.JSX.Element {
    return (
      <ul className='dropdown-values wb-list--empty'>
        {choices.map((choice) => {
          return (
            <li key={choice.id} className='dropdown-row'>
              <span className='dropdown-entry'>{choice.value}</span>
            </li>
          );
        })}
      </ul>
    );
  }

  function renderCopyButton(): React.JSX.Element {
    if (!dropdownId) { return null; }

    const copyText = choices.map((option) => { return option.value; }).join(';');

    return (
      <div className='text-center wb-u-margin-v-2'>
        <CopyButton
          copyText={copyText}
          id={`dropdown-copy-button-${number}`}
          text='Copy choices to clipboard'
        />
      </div>
    );
  }

  return (
    <div>
      {renderDropdownSelector()}
      {renderChoices()}
      {renderHelpText()}
      {renderToggleDropdown()}
      {renderCopyButton()}
    </div>
  );
}

export type { Props };
export default SharedSelector;
