// @ts-strict-ignore
import { connect } from 'react-redux';

import { updateMeta } from 'src/steps/action_creators';
import StepDiagram from 'src/steps/components/step_diagram';
import { selectOrderedSteps } from 'src/steps/selectors';

function mapStateToProps(state) {
  return {
    selectedStepId: state.steps.meta.selectedStepId,
    steps: selectOrderedSteps(state),
    userPermissions: state.campaign.userPermissions,
  };
}

export default connect(mapStateToProps, { updateMeta })(StepDiagram);
