// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';

class WindowOpenLinkController extends Controller<HTMLElement> {
  $element: JQuery;

  connect(): void {
    this.$element = $(this.element);

    this.$element.on('click', this.openLinkInWindow.bind(this));
  }

  openLinkInWindow(event: Event): void {
    event.stopPropagation();
    event.preventDefault();

    window.open(
      this.$element.attr('href'),
      '',
      'menubar=no,toolbar=no,height=600,width=600',
    );
  }
}

export default WindowOpenLinkController;
