// @ts-strict-ignore
import FieldModel from 'src/doc_editor/fields/models/field';
import TIME_INTERVALS from 'src/fields/config/time_intervals';

const DEFAULTS = {
  content: '00:00 AM',
  format: 'HH:MM AM',
  interval: TIME_INTERVALS.none.interval,
  required: false,
};

class Time extends FieldModel {
  constructor(opts) {
    super({ ...DEFAULTS, ...opts });
  }
}

export default Time;
