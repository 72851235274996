// @ts-strict-ignore
import { connect } from 'react-redux';

import ResponsesFilters from 'src/responses/components/filters';

function mapStateToProps(state) {
  const { responses: { meta: { queryParams: { filters } } } } = state;

  return { filters };
}

export default connect(mapStateToProps)(ResponsesFilters);
