// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';

import $template from 'src/helpers/dollar_template';
import { findEl, findEls } from 'src/helpers/finders';
import grab from 'src/helpers/grab';
import assertInclusion from 'src/helpers/assert_inclusion';

const DELEGATABLE_STEP_TYPES: DelegatableStepType[] =
  ['RecipientStep', 'PromptStep'];

abstract class StepRow {
  protected row: HTMLElement;

  constructor(row: HTMLElement) {
    this.row = row;

    this.toggleRequired = this.toggleRequired.bind(this);

    this.checkbox.addEventListener('change', this.toggleRequired);
  }

  get checkbox(): HTMLInputElement {
    return findEl(this.row, 'input', '[type="checkbox"]');
  }

  get label(): string {
    return findEl(this.row, 'span', '.step-label').textContent;
  }

  get isSelected(): boolean {
    return this.checkbox.checked;
  }

  abstract get isValid(): boolean;
  abstract get displayText(): string;
  protected abstract toggleRequired(): void;
}

class PromptStepRow extends StepRow {
  get nameInput(): HTMLInputElement {
    return findEl(this.row, 'input', '[type="text"]');
  }

  get emailInput(): HTMLInputElement {
    return findEl(this.row, 'input', '[type="email"]');
  }

  get isValid(): boolean {
    const { nameInput, emailInput } = this;

    return Boolean(nameInput.value) && Boolean(emailInput.value);
  }

  get displayText(): string {
    const { nameInput, emailInput } = this;

    return `${this.label} - ${nameInput.value} (${emailInput.value})`;
  }

  toggleRequired(): void {
    const { isSelected, nameInput, emailInput } = this;

    nameInput.required = isSelected;
    nameInput.disabled = !isSelected;
    emailInput.required = isSelected;
    emailInput.disabled = !isSelected;
  }
}

class RecipientStepRow extends StepRow {
  get select(): HTMLSelectElement {
    return findEl(this.row, 'select');
  }

  get isValid(): boolean {
    return this.select.length === 1 || this.select.selectedIndex > 0;
  }

  get displayText(): string {
    const { select } = this;

    return `${this.label} - ${select.options[select.selectedIndex].text}`;
  }

  toggleRequired(): void {
    const { isSelected, select } = this;

    select.required = isSelected;
    select.disabled = !isSelected;
  }
}

const ROW_CLASSES = {
  PromptStep: PromptStepRow,
  RecipientStep: RecipientStepRow,
};

class DispatcherSelectionsController extends Controller {
  connect(): void {
    const form = findEl(this.element, 'form');
    const approverRows: HTMLElement[] = findEls(form, 'tr', '.approver-row');

    const rows = approverRows.map((row) => {
      const stepType =
        assertInclusion(DELEGATABLE_STEP_TYPES, row.dataset.stepType);

      const RowClass = grab(ROW_CLASSES, stepType);

      return new RowClass(row);
    });

    updateSummary(form, rows);
    form.addEventListener('change', () => { updateSummary(form, rows); });
  }
}

function updateSummary(form: HTMLElement, rows: StepRow[]): void {
  const selectedRows = rows.filter((row) => { return row.isSelected; });
  const invalidRows = selectedRows.filter((row) => { return !row.isValid; });

  let $summary;

  if (invalidRows.length > 0) {
    const warningLines = invalidRows.map((row) => { return row.label; });

    $summary = $template('form-routing-warning-template', { warningLines });
  } else {
    const approverLines = selectedRows.map((row) => { return row.displayText; });

    $summary = $template('form-routing-summary-template', { approverLines });
  }

  $(findEl(form, 'div', '.form-routing-summary')).html($summary);
}

export default DispatcherSelectionsController;
