import 'src/config/honeybadger';
import 'src/config/trackjs';
import 'src/globals';
import 'bootstrap-sass/assets/javascripts/bootstrap/transition';
import 'bootstrap-sass/assets/javascripts/bootstrap/alert';
import 'bootstrap-sass/assets/javascripts/bootstrap/button';
import 'bootstrap-sass/assets/javascripts/bootstrap/collapse';
import 'bootstrap-sass/assets/javascripts/bootstrap/dropdown';
import 'bootstrap-sass/assets/javascripts/bootstrap/tooltip';
import 'bootstrap-sass/assets/javascripts/bootstrap/popover';
import 'bootstrap-sass/assets/javascripts/bootstrap/tab';
import '@github/details-menu-element';
import '@rails/actiontext';
import 'a11y-dialog';
import 'regenerator-runtime/runtime';
import '@hotwired/turbo-rails';
import 'controllers/index';
