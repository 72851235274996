// @ts-strict-ignore
function createMergedReducer(reducerMap) {
  return function mergedReducer(previousState, action) {
    if (previousState === undefined) { return initState(reducerMap); }

    const reducerKey = getReducerKey(action);
    const reducer = fetchReducer(reducerMap, reducerKey);
    const newState = { ...previousState };

    newState[reducerKey] = reducer(previousState[reducerKey], action);

    return newState;
  };
}

// private

function getReducerKey(action): string {
  return action.type.split('/')[0];
}

function fetchReducer(reducerMap, reducerKey) {
  const reducer = reducerMap[reducerKey];

  if (!reducer) { throw new Error(`no reducer found for: ${reducerKey}`); }

  return reducer;
}

function initState(reducerMap) {
  const newState = {};

  Object.keys(reducerMap).forEach((key) => {
    newState[key] = reducerMap[key]();
  });

  return newState;
}

export default createMergedReducer;
