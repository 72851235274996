// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';

import { findEls } from 'src/helpers/finders';

const SELECTED_CELL_STYLE = 'datasources__unique-cell';
const SELECTED_HEADER_CELL_STYLE = 'datasources__unique-cell--header';
const SELECTED_LAST_CELL_STYLE = 'datasources__unique-cell--last';

export default class extends Controller {
  static targets = [
    'previewTable',
    'previewTableHeaders',
  ];

  previewTableTarget: HTMLElement;
  previewTableHeadersTarget: HTMLTableRowElement;
  headers: HTMLElement[];
  cells: NodeListOf<HTMLTableCellElement>;

  connect(): void {
    this.headers = Array.from(this.previewTableHeadersTarget.cells);
    this.cells = this.previewTableTarget.querySelectorAll('tr td');
  }

  highlightSelectedColumn(event: HTMLSelectEvent): void {
    const { selectedIndex } = event.target;

    this.removeHighlight();
    if (selectedIndex >= 1) { this.addHighlight(selectedIndex); }
  }

  addHighlight(selectedIndex: number): void {
    this.headers[selectedIndex - 1].classList.add(
      SELECTED_CELL_STYLE,
      SELECTED_HEADER_CELL_STYLE,
    );

    const selectedCells = findEls(
      this.previewTableTarget,
      'td',
      `:nth-child(${selectedIndex})`,
    );

    selectedCells.forEach((cell, index) => {
      cell.classList.add(SELECTED_CELL_STYLE);
      if (index >= selectedCells.length - 1) {
        cell.classList.add(SELECTED_LAST_CELL_STYLE);
      }
    });
  }

  removeHighlight(): void {
    this.headers.forEach((header) => {
      header.classList.remove(
        SELECTED_CELL_STYLE,
        SELECTED_HEADER_CELL_STYLE,
      );
    });

    this.cells.forEach((cell) => {
      cell.classList.remove(
        SELECTED_CELL_STYLE,
        SELECTED_LAST_CELL_STYLE,
      );
    });
  }
}
