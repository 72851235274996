// @ts-strict-ignore
import React from 'react';
import ReactDOM from 'react-dom';

import ContextMenuWrapper, { Options } from 'src/doc_editor/context_menu_wrapper';
import InputContextMenu from 'src/doc_editor/fields/components/context_menus/input';

class InputContextMenuWrapper extends ContextMenuWrapper {
  constructor(opts: Options) {
    super(opts);

    this.updateDisplay = this.updateDisplay.bind(this);
    this.updateDisplay(opts);
  }

  updateDisplay(opts: Options): void {
    super.updateDisplay(opts);
    const { formatLocked, number, precision, format } = opts;

    ReactDOM.render(
      <React.StrictMode>
        <InputContextMenu
          format={format}
          formatLocked={formatLocked}
          number={number}
          precision={precision}
          updateFields={this.updateFields}
        />
      </React.StrictMode>,
      this.fieldSpecificContainer,
    );
  }
}

export default InputContextMenuWrapper;
