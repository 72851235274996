// @ts-strict-ignore
class DataMatcher {
  columns: any[];
  currentlySelectedColumn: any;
  currentHeader: any;

  constructor(columns) {
    this.columns = columns;

    this.useMatcher(false);
  }

  matchCallback() { /* overridden in data_reviewer */ }

  useMatcher(header): void {
    this.currentlySelectedColumn = null;
    this.currentHeader = header;

    this.columns.forEach((column) => {
      if (column.isLocked()) {
        // If header was previously matched to this column, let's
        //   unlock it so we can change this matching
        if (column.getMatchedHeader() === header) {
          if (this.currentlySelectedColumn) {
            throw new Error(`Two columns shouldn't be matched to header ${header}`);
          }

          column.unlock();
          this.currentlySelectedColumn = column;
        } else {
          column.$element.off('click.matcher');
          return;
        }
      }

      column.$element.off('click.matcher').on('click.matcher', () => {
        if (this.currentlySelectedColumn) {
          this.currentlySelectedColumn.setMatchedHeader('');
        }
        column.setMatchedHeader(header);

        this.currentlySelectedColumn = column;

        this.matchCallback();
      });
    });
  }

  getMatchMappings() {
    const mappings = {};

    this.columns.forEach((column) => {
      const matchedHeader = column.getMatchedHeader();

      if (matchedHeader) {
        mappings[matchedHeader] = column.getHeader();
      }
    });

    return mappings;
  }

  saveCurrentHeader(): void {
    if (this.currentHeader && this.currentlySelectedColumn) {
      this.currentlySelectedColumn.lock();
    }
  }

  clearHeader(header): void {
    this.columns.forEach((column) => {
      if (header === column.getMatchedHeader()) {
        if (column.isLocked()) { column.unlock(); }
        column.setMatchedHeader('');
      }
    });
  }

  getColumns() {
    return this.columns;
  }

  hasSelected(): boolean {
    return Boolean(this.currentlySelectedColumn);
  }

  clear(): void {
    this.columns.forEach((column) => {
      if (column.getMatchedHeader()) {
        if (column.isLocked()) { column.unlock(); }
        column.setMatchedHeader('');
      }
    });
  }

  getCurrentHeader() {
    return this.currentHeader;
  }
}

export default DataMatcher;
