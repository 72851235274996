// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';

class RadioViewController extends Controller {
  static values = { name: String };
  static targets = ['view'];

  viewTargets: HTMLElement[];

  connect(): void {
    this.toggleSection = this.toggleSection.bind(this);

    this.viewTargets.forEach((element) => {
      element.hidden = true;
    });
  }

  toggleSection(event: HTMLInputEvent): void {
    const activatedViewName = event.target.value;

    this.viewTargets.forEach((element) => {
      element.hidden = element.dataset.radioViewName !== activatedViewName;
    });
  }
}

export default RadioViewController;
