// @ts-strict-ignore
import FieldModel from 'src/doc_editor/fields/models/field';

const DEFAULTS = {
  allowedInNumberFormula: true,
  height: 14,
  minHeight: 14,
  minWidth: 21,
  required: false,
  width: 28,
};

class Input extends FieldModel {
  constructor(opts) {
    super({ ...DEFAULTS, ...opts });
  }
}

export default Input;
