// @ts-strict-ignore
import { createSelector } from 'reselect';

const selectOrderedSteps = createSelector(
  [_indexedSteps, _orderedStepIds],
  _orderedSteps,
);

const getSelectedStep = createSelector(
  [_indexedSteps, _selectedStepId],
  _selectedStep,
);

// private

function _indexedSteps(state) {
  return state.steps.byId;
}

function _orderedStepIds(state) {
  return state.steps.orderedIds;
}

function _selectedStepId(state) {
  return state.steps.meta.selectedStepId;
}

function _orderedSteps(indexedSteps: IndexedSteps, orderedStepIds: number[]) {
  return orderedStepIds.map((stepId) => { return indexedSteps[stepId]; });
}

function _selectedStep(indexedSteps: IndexedSteps, selectedStepId: number) {
  if (selectedStepId === undefined) { return null; }

  return indexedSteps[selectedStepId];
}

export { selectOrderedSteps, getSelectedStep };
