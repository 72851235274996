// @ts-strict-ignore
import FormulaDecorator from 'src/decorators/formula';

let previousFieldDependencies = {};
const currentFieldDependencies = {};

class FormulaDependencyUpdater {
  updateDependencies(
    number: number,
    formulaString: string,
    fieldsByNumber: FieldsByNumber,
  ): Partial<Field>[] {
    const formula = new FormulaDecorator(formulaString);
    const dependencyNumbers = formula.getFieldNumbers();
    const inputFieldNumbers = dependencyNumbers.filter((fieldNumber) => {
      const field = fieldsByNumber[fieldNumber];

      return field && field.type === 'Input';
    });

    previousFieldDependencies = { ...currentFieldDependencies };
    currentFieldDependencies[number] = inputFieldNumbers;

    return getCurrentFieldFormatUpdates(inputFieldNumbers)
      .concat(getRemovedFieldFormatUpdates());
  }

  removeDependencies(number: number): Partial<Field>[] {
    previousFieldDependencies = { ...currentFieldDependencies };
    delete currentFieldDependencies[number];

    return getRemovedFieldFormatUpdates();
  }
}

// private

function getUniqueValsFromObject(object): number[] {
  const fieldNumberArrays: number[][] = Object.values(object);

  return [...new Set(fieldNumberArrays.flat())];
}

function getCurrentFieldFormatUpdates(fieldNumbers: number[]): Partial<Field>[] {
  return fieldNumbers.map((number) => {
    return { format: 'number', formatLocked: true, number };
  });
}

function getRemovedFieldFormatUpdates(): Partial<Field>[] {
  const oldDependencies = getUniqueValsFromObject(previousFieldDependencies);
  const newDependencies = getUniqueValsFromObject(currentFieldDependencies);

  const removed = oldDependencies.filter((dependency) => {
    return !newDependencies.includes(dependency);
  });

  return removed.map((number) => {
    return { format: null, formatLocked: false, number };
  });
}

export default new FormulaDependencyUpdater();
