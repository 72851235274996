// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';

class SearchablePickerListController extends Controller {
  static targets = ['optionsDiv', 'emptyDiv', 'option'];

  optionsDivTarget: HTMLElement;
  emptyDivTarget: HTMLElement;
  optionTargets: HTMLElement[];

  connect(): void {
    this.filterRows = this.filterRows.bind(this);
    this.selectOption = this.selectOption.bind(this);

    this.emptyDivTarget.classList.add('hide');
    this.optionTargets.forEach((option) => {
      option.querySelector('.checkmark')?.classList.add('hide');
    });
  }

  filterRows(event: Event): void {
    const eventTarget = event.target as HTMLInputElement;
    let atLeastOneMatch = false;
    const matchText = eventTarget.value.toLowerCase();

    this.optionTargets.forEach((option) => {
      const description = option.querySelector('.description');
      const isMatch = description.textContent.toLowerCase().includes(matchText);

      atLeastOneMatch ||= isMatch;
      const hideOption = !isMatch;

      option.classList.toggle('hide', hideOption);
    });

    const noMatches = !atLeastOneMatch;

    this.optionsDivTarget.classList.toggle('hide', noMatches);
    this.emptyDivTarget.classList.toggle('hide', atLeastOneMatch);
  }

  selectOption(event: Event): void {
    const eventTarget = event.target as HTMLDivElement;

    this.optionTargets.forEach((option) => {
      const isSelected = option.contains(eventTarget);

      option.classList.toggle('list-group-item-success', isSelected);
      option.querySelector('.checkmark')?.classList.toggle('hide', !isSelected);
    });
  }
}

export default SearchablePickerListController;
