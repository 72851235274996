import Field from 'src/doc_editor/field';
import $template from 'src/helpers/dollar_template';

class Checkbox extends Field {
  $preview(): JQuery {
    const $previewElement = $template('checkbox-field-preview-template');

    $previewElement.css({
      height: this.$element.height(),
      left: this.$element.position().left,
      top: this.$element.position().top,
      width: this.$element.width(),
    });

    return $previewElement;
  }
}

export default Checkbox;
