// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';
import routie from 'routie';

import Dialog from 'src/helpers/dialog';
import $template from 'src/helpers/dollar_template';
import { findEl } from 'src/helpers/finders';
import sanitizeInput from 'src/helpers/sanitize_input';
import updateWizardHeader from 'src/helpers/update_wizard_header';
import valToString from 'src/helpers/val_to_string';

class ManualEntryController extends Controller<HTMLElement> {
  $element: JQuery;
  $adderPanel: JQuery;
  $reviewPanel: JQuery;
  $entryAdderName: JQuery;
  $entryAdderEmails: JQuery;

  connect(): void {
    this.$element = $(this.element);

    this.rootRoute = this.rootRoute.bind(this);
    this.reviewRoute = this.reviewRoute.bind(this);

    this.$adderPanel = $(this.adderPanel);
    this.$reviewPanel = $(this.reviewPanel);
    this.$entryAdderName = $(this.entryAdderName);
    this.$entryAdderEmails = $(this.entryAdderEmails);

    sanitizeInput(this.$entryAdderName, this.$entryAdderEmails);

    routie('', this.rootRoute);
    routie('review', this.reviewRoute);

    this.$adderPanel.find('.entry-add').on('click', () => {
      const names = valToString(this.$entryAdderName.val()).split('\n');
      const emails = valToString(this.$entryAdderEmails.val()).split('\n');

      const errorMessage = this.getAdderErrorMsg();

      if (errorMessage) {
        Dialog.alert(errorMessage);
        return false;
      }

      // Clear the exisiting entries in the review panel
      this.$reviewPanel.find('.entry-unit').remove();

      for (let index = 0; index < names.length; index++) {
        const [firstName] = names[index].split(' ');
        const lastName = names[index].split(' ').splice(1).join(' ');
        const email = index < emails.length ? emails[index] : '';

        const $record = $template('entry-unit-template', {
          email,
          firstName,
          index,
          lastName,
        });

        this.$reviewPanel.find('.row:last-child').before($record);
      }

      this.$reviewPanel.find('.entry-remove').on('click', (event) => {
        const numRecords = this.$reviewPanel.find('.entry-unit').length;

        // If this is the last record, go back to the entry panel
        if (numRecords <= 1) {
          routie('');
        } else {
          $(event.currentTarget).closest('.entry-unit').remove();
        }
      });

      routie('review');
      return true;
    });

    this.$element.find('input[type=submit]').on('click', () => {
      $('.entry-first-name').each((_index, firstName) => {
        const $firstName = $(firstName);
        const $firstNames = $('#first_names');

        if (!$firstName.val()) { $firstName.val(' '); }

        $firstNames.val(`${$firstNames.val()}\`${$firstName.val()}`);
      });

      $('.entry-last-name').each((_index, lastName) => {
        const $lastName = $(lastName);
        const $lastNames = $('#last_names');

        if (!$lastName.val()) { $lastName.val(' '); }

        $lastNames.val(`${$lastNames.val()}\`${$lastName.val()}`);
      });

      $('.entry-email').each((_index, email) => {
        const $email = $(email);

        if (!$email.val()) {
          $email.val(' ');
        }
        $('#emails').val(`${$('#emails').val()}\`${$email.val()}`);
      });
    });
  }

  get adderPanel(): HTMLElement {
    return findEl(this.element, 'div', '.entry-adder');
  }

  get reviewPanel(): HTMLElement {
    return findEl(this.element, 'div', '.entry-review');
  }

  get entryAdderName(): HTMLElement {
    return findEl(this.adderPanel, 'textarea', '.entry-adder-name');
  }

  get entryAdderEmails(): HTMLElement {
    return findEl(this.adderPanel, 'textarea', '.entry-adder-email');
  }

  getAdderErrorMsg(): string | false {
    if (!this.$element.find('#entry-group-name').val()) {
      return 'Please enter in a group name';
    }

    if (!this.$entryAdderName.val()) {
      return 'Please enter in names for people in your group';
    }

    return false;
  }

  rootRoute(): void {
    this.$reviewPanel.hide();
    this.$adderPanel.show();

    updateWizardHeader('Group Options');
  }

  reviewRoute(): void {
    // If theres nothing in the adder, don't let them go into the review panel
    if (this.getAdderErrorMsg()) {
      routie('');
    } else {
      this.$adderPanel.hide();
      this.$reviewPanel.show();

      updateWizardHeader('Review');
    }
  }
}

export default ManualEntryController;
