type PhoneNumberParseError = { error: string };
type PhoneNumberParseResult = { phoneNumber: string };

function parsePhoneNumber(
  input: string,
): PhoneNumberParseError | PhoneNumberParseResult {
  const cleanedPhoneNumber = input.replace(/\D/g, '');

  if (cleanedPhoneNumber.length !== 10) {
    return {
      error: 'Please enter a ten digit phone number, e.g. 222-333-4444.',
    };
  }

  const formattedPhoneNumber =
    cleanedPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');

  return { phoneNumber: formattedPhoneNumber };
}

export default parsePhoneNumber;
