// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';

import { hide, show } from 'src/helpers/visibility';

class MultiCheckbox extends Controller {
  static targets = [
    'checkbox',
    'checkboxAll',
    'error',
  ];

  checkboxAllTarget: HTMLInputElement;
  checkboxTargets: HTMLInputElement[];
  errorTarget: HTMLElement;

  toggleAllCheckboxes(): void {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = this.checkboxAllTarget.checked;
    });
    this.updateRequiredText();
  }

  toggleCheckbox(): void {
    this.checkboxAllTarget.checked = this.areAllChecked();
    this.updateRequiredText();
  }

  checkedCheckboxes(): HTMLInputElement[] {
    return this.checkboxTargets.filter((checkbox) => { return checkbox.checked; });
  }

  checkedCount(): number {
    return this.checkedCheckboxes().length;
  }

  areAllChecked(): boolean {
    return this.checkedCount() === this.checkboxTargets.length;
  }

  areNoneChecked(): boolean {
    return this.checkedCount() === 0;
  }

  updateRequiredText(): void {
    this.areNoneChecked() ? this.setErrorState() : this.clearErrorState();
  }

  clearErrorState(): void {
    hide(this.errorTarget);
  }

  setErrorState(): void {
    show(this.errorTarget);
  }
}

export default MultiCheckbox;
