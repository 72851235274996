// @ts-strict-ignore
import React, { ReactNode } from 'react';

import getCSRFToken from 'src/helpers/get_csrf_token';

type Props = {
  id?: string;
  action: string;
  children: ReactNode;
  method: 'DELETE' | 'PATCH' | 'POST' | 'PUT';
  turbo?: boolean;
};

const RailsForm = React.forwardRef<HTMLFormElement, Props>((props, ref) => {
  const { action, method, id, turbo } = props;
  const csrfToken = getCSRFToken();

  return (
    <form ref={ref} action={action} data-turbo={turbo} id={id} method='POST'>
      <input autoComplete='off' name='_method' type='hidden' value={method} />
      <input
        autoComplete='off'
        name='authenticity_token'
        type='hidden'
        value={csrfToken}
      />
      {props.children}
    </form>
  );
});

RailsForm.defaultProps = {
  turbo: false,
};

RailsForm.displayName = 'RailsForm';

export default RailsForm;
