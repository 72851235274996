// @ts-strict-ignore
import { connect } from 'react-redux';

import FeedbacksIndex from 'src/feedbacks/components/index';

function mapStateToProps(state) {
  const { feedbacks } = state;

  return {
    counts: feedbacks.meta.counts,
    feedbacks: feedbacks.byId,
    paginationTotals: feedbacks.meta.pagination,
    queryParams: feedbacks.meta.queryParams,
  };
}

export default connect(mapStateToProps)(FeedbacksIndex);
