// @ts-strict-ignore
import { serializeParams } from 'src/helpers/json';
import Location from 'src/helpers/location';
import { updateMeta as feedbacksUpdateMeta } from 'src/feedbacks/action_creators';
import { updateMeta as responsesUpdateMeta } from 'src/responses/action_creators';


function updateResponsesQueryParams(queryParams: Partial<ResponsesQueryParams>) {
  return (dispatch, getState): void => {
    const mergedParams: ResponsesQueryParams = {
      ...getState().responses.meta.queryParams,
      ...queryParams,
    };
    const historyState = `?${serializeParams(mergedParams)}`;

    Location.replaceHistoryState(historyState);
    dispatch(responsesUpdateMeta({ queryParams: mergedParams }));
  };
}


function updateFeedbacksQueryParams(queryParams: Partial<FeedbacksQueryParams>) {
  return (dispatch, getState): void => {
    const mergedParams: FeedbacksQueryParams = {
      ...getState().feedbacks.meta.queryParams,
      ...queryParams,
    };
    const historyState = `?${serializeParams(mergedParams)}`;

    Location.replaceHistoryState(historyState);
    dispatch(feedbacksUpdateMeta({ queryParams: mergedParams }));
  };
}

export { updateResponsesQueryParams, updateFeedbacksQueryParams };
