import React, { ReactNode } from 'react';

import Icon from 'src/common/components/icon';

type Props = {
  buttonClassName: string;
  children: ReactNode;
  label: string;
  id?: string;
  ulClassName?: string;
  dataControllerName?: string;
};

function DropdownButton(props: Props): React.JSX.Element {
  const {
    buttonClassName,
    label,
    ulClassName,
    children,
    id,
    dataControllerName,
  } = props;

  return (
    <div
      className='btn-group'
      data-controller={dataControllerName}
    >
      <button
        className={`btn dropdown-toggle ${buttonClassName}`}
        data-responses-selection-target='filter'
        data-toggle='dropdown'
        id={id}
        type='button'
      >
        {label}
        <Icon iconClassName='wb-u-margin-l-1' name='caretDown' />
      </button>
      <ul className={`dropdown-menu ${ulClassName || ''}`}>{children}</ul>
    </div>
  );
}

export default DropdownButton;
export type { Props };
