// @ts-strict-ignore
type SerializedFieldRule = {
  id: number;
  triggerFieldNumber: number;
  affectedFieldNumbers: number[];
  triggerValue: string;
};
class FieldRule {
  id: number;
  triggerFieldNumber: number;
  affectedFieldNumbers: number[];
  triggerValue: string;
  action: string;
  active: boolean;
  valid = true;
  triggerFieldElement: HTMLInputElement | null;
  affectedFieldElements: HTMLElement[];

  constructor(ruleData: SerializedFieldRule) {
    this.id = ruleData.id;
    this.triggerFieldNumber = ruleData.triggerFieldNumber;
    this.affectedFieldNumbers = ruleData.affectedFieldNumbers;
    this.triggerValue = ruleData.triggerValue;
    this.action = 'show';
    this.active = this.initialTriggerState();
    this.valid = this.isRuleValid();
  }

  get triggerField(): HTMLInputElement | null {
    this.triggerFieldElement ||= document.querySelector(
      `[data-field-number="${this.triggerFieldNumber}"]`,
    );
    return this.triggerFieldElement;
  }

  get triggerFieldType(): string | null {
    if (!this.triggerField) { return null; }

    return this.triggerField.dataset.fieldType;
  }

  get affectedFields(): HTMLElement[] {
    this.affectedFieldElements ||= Array.from(
      document.querySelectorAll(
        this.affectedFieldNumbers.map((num) => {
          return `[data-field-number="${num}"]`;
        }).join(', '),
      ),
    );
    return this.affectedFieldElements;
  }

  get affectedInputs(): FormInput[] {
    return this.affectedFields.flatMap((field) => {
      if (field instanceof HTMLSelectElement) {
        return Array.from(field.parentElement.querySelectorAll('input, select'));
      }
      return Array.from(field.querySelectorAll('input, textarea, div'));
    });
  }

  initialTriggerState(): boolean {
    if (this.triggerFieldType === 'CheckboxField') {
      const checkbox = this.triggerField.querySelector('input');
      const checkboxValue = checkbox.checked ? 'checked' : 'unchecked';
      return checkboxValue === this.triggerValue;
    }

    if (this.triggerFieldType === 'DropdownField') {
      return this.dropdownSelected();
    }

    return false;
  }

  dropdownSelected(): boolean {
    if (this.triggerField.classList.contains('locked-field')) {
      const parsedData =
        JSON.parse(this.triggerField.dataset.field || '{}') as { value: string };
      const { value } = parsedData;

      return value === this.triggerValue;
    }

    const selectInput = this.triggerField.querySelector('select') ||
      this.triggerField;
    return selectInput.value === this.triggerValue;
  }

  isRuleValid(): boolean {
    if (!this.triggerField) { return false; }

    if (this.triggerFieldType === 'DropdownField') {
      const selectInput = this.triggerField.querySelector('select') ||
        this.triggerField;
      const options = Array.from(
        selectInput.querySelectorAll('option'),
      ).map((option) => { return option.value; });
      return options.includes(this.triggerValue);
    }

    return true;
  }

  apply(): void {
    if (!this.valid) { return; }

    if (this.action === 'show' && this.active) {
      this.affectedFields.forEach((field) => { field.hidden = false; });
      this.affectedInputs.forEach((element) => {
        element.disabled = false;
        element.dataset.disabled = 'false';
      });
    } else if (this.action === 'show' && !this.active) {
      this.affectedFields.forEach((field) => { field.hidden = true; });
      this.affectedInputs.forEach((element) => {
        element.disabled = true;
        element.dataset.disabled = 'true';
      });
    }
  }

  toggle(): void {
    if (this.triggerFieldType === 'CheckboxField') {
      this.active = !this.active;
    } else {
      this.active = this.triggerField.value === this.triggerValue;
    }
  }

  // Rules should apply in the following order: 'hide', 'show'
  order(): number {
    if (this.action === 'show' && !this.active) { return 1; }
    if (this.action === 'show' && this.active) { return 2; }
    return 0;
  }
}

export default FieldRule;
export type { SerializedFieldRule };
