// @ts-strict-ignore
import Field from 'src/doc_editor/field';
import fontSizeCss from 'src/doc_editor/fields/helpers/font_size_css';
import { assert } from 'src/helpers/assertion';
import $template from 'src/helpers/dollar_template';
import sanitizeInput from 'src/helpers/sanitize_input';
import serializedHtml from 'src/helpers/serialized_html';

class StaticText extends Field {
  constructor(opts) {
    super(opts);

    this.updateContentInInput = this.updateContentInInput.bind(this);
    this.setContent = this.setContent.bind(this);

    if (!opts.initCallbacks) { return; }

    this.$element.find('.text-input').on('input', () => {
      if (this.contextMenu) { this.contextMenu.hide(); }

      this.setContent();
      this.model.set({
        height: this.$element.find('.field-container').height(),
        width: this.$element.find('.field-container').width(),
      });
    });

    this.updateContentInInput(this.model);
    sanitizeInput(this.$element.find('.text-input'));
  }

  $preview(): JQuery {
    const $previewElement = $template('static-text-field-preview-template');

    $previewElement.css({
      height: this.$element.height(),
      left: this.$element.position().left,
      top: this.$element.position().top,
      width: this.$element.width(),
    });
    const textInputHtml = this.$element.find('.text-input').html();

    $previewElement.find('.text-input').html(textInputHtml);
    return $previewElement;
  }

  focus(): void {
    this.$element.find('.text-input').trigger('focus');
  }

  updateContentInInput(model): void {
    const content = model.get('content');

    this.$element.find('.text-input').html(content.replace(/\n/ug, '<br>'));
  }

  previewOffset(): Coordinate {
    const outerWidth = assert(this.$element.outerWidth());
    const outerHeight = assert(this.$element.outerHeight());

    return { x: outerWidth / 2, y: outerHeight / 2 };
  }

  setFontSize(val): void {
    const fontCss = fontSizeCss(val);

    fontCss['min-height'] = fontCss['line-height'];
    this.$element.find('.adjustable-font').css(fontCss);
  }

  setContent(): void {
    const content = serializedHtml(this.$element.find('.text-input').html());

    this.model.set('content', content);
  }
}

export default StaticText;
