import { defaultPageSize } from 'src/constants';

const BASE_PARAMS = {
  direction: 'desc',
  page: 1,
  pageSize: defaultPageSize,
  sortBy: 'last_activity',
};

export const DEFAULT_FILTERS: ResponseFilters = { statusGroup: 'active' };

export const DEFAULT_QUERY_PARAMS = {
  GroupCampaign: {
    active: { ...BASE_PARAMS, direction: 'asc', sortBy: 'status_and_recipient' },
    archived: BASE_PARAMS,
    cancelled: BASE_PARAMS,
    completed: BASE_PARAMS,
    pending: { ...BASE_PARAMS, direction: 'asc', sortBy: 'status_and_recipient' },
  },
  LinkCampaign: {
    active: BASE_PARAMS,
    archived: BASE_PARAMS,
    cancelled: BASE_PARAMS,
    completed: BASE_PARAMS,
    denied: BASE_PARAMS,
    pending: BASE_PARAMS,
  },
  RoutedGroupCampaign: {
    active: {
      ...BASE_PARAMS,
      direction: 'asc',
      sortBy: 'status_and_seq_and_recipient',
    },
    archived: BASE_PARAMS,
    cancelled: BASE_PARAMS,
    completed: BASE_PARAMS,
    denied: BASE_PARAMS,
    pending: {
      ...BASE_PARAMS,
      direction: 'asc',
      sortBy: 'status_and_seq_and_recipient',
    },
  },
  RoutedLinkCampaign: {
    active: {
      ...BASE_PARAMS,
      direction: 'asc',
      sortBy: 'status_and_seq_and_recipient',
    },
    archived: BASE_PARAMS,
    cancelled: BASE_PARAMS,
    completed: BASE_PARAMS,
    denied: BASE_PARAMS,
    pending: {
      ...BASE_PARAMS,
      direction: 'asc',
      sortBy: 'status_and_seq_and_recipient',
    },
  },
};
