// @ts-strict-ignore
import keyBy from 'lodash/keyBy';

import { StoreState } from 'src/chux/doc_editor/single_store';
import createBasicReducer from 'src/common/basic_reducer';

const operations = {
  '@@INIT': initStore,
  'doc_editor/RESET': initStore,
  'doc_editor/UPDATE': updateDocEditor,
};

function updateDocEditor(previousState, payload): StoreState {
  if (payload.highlightedFields && payload.highlightedFields.length > 0) {
    const index = keyBy(payload.highlightedFields);

    payload = { indexedHighlightedFields: index, ...payload };
  }

  if ('allFieldsLoaded' in payload) {
    document.body.dataset.allFieldsLoaded = payload.allFieldsLoaded;
  }

  return { ...previousState, ...payload };
}

function initStore(): StoreState {
  return {
    activeFormulaMode: null,
    activeFormulaNumber: null,
    badgeType: null,
    fontSize: null,
    highlightedFields: [],
    indexedHighlightedFields: {},
    showMedallions: false,
  };
}

export default createBasicReducer(operations);
