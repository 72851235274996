// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';

class ResponsesSidebarController extends Controller {
  static targets = ['statusGroup'];

  statusGroupTargets: HTMLElement[];

  update(event: { detail: { searchTerm: string; statusGroup: string } }): void {
    const { statusGroup: currentStatusGroup, searchTerm } = event.detail;
    this.#updateStatusGroups(currentStatusGroup, searchTerm);
  }

  #updateStatusGroups(currentStatusGroup: string, searchTerm: string): void {
    this.statusGroupTargets.forEach((statusGroupTarget) => {
      const statusGroupAttr = statusGroupTarget.getAttribute('data-status-group');
      const active = !searchTerm && currentStatusGroup === statusGroupAttr;

      if (active) {
        statusGroupTarget.classList.add('active');
      } else {
        statusGroupTarget.classList.remove('active');
      }
    });
  }
}

export default ResponsesSidebarController;
