// @ts-strict-ignore
import { createSelector } from 'reselect';

import { defaultPageSize } from 'src/constants';
import Feature from 'src/helpers/feature';

const getSelectedResponsesCount = createSelector(
  [_baseResponses],
  _selectedResponsesCount,
);

const getSelectedFormIds = createSelector([_responses], _selectedFormIds);

const areAllSelectedOnPage = createSelector([_responses], _areAllSelectedOnPage);

const canGeneratePdf = createSelector([getSelectedResponsesCount], _canGeneratePdf);

// private

function _baseResponses(state: State): ResponsesState {
  return state.responses;
}

function _responses(state) {
  return state.responses.responses;
}

function _selectedResponsesCount(responsesState: ResponsesState): number {
  const { responses, meta, areAllSelectedAcrossPages } = responsesState;

  if (!responses) { return 0; }
  if (areAllSelectedAcrossPages) { return meta.pagination.totalCount; }

  return _selectedResponses(responses).length;
}

function _areAllSelectedOnPage(responses: FormResponse[]) {
  if (!responses || responses.length === 0) { return false; }

  return responses.every((response) => { return response.isSelected; });
}

function _selectedFormIds(responses: FormResponse[]) {
  if (!responses) { return []; }

  return _selectedResponses(responses).map((response) => {
    return response.formId;
  });
}

function _selectedResponses(responses: FormResponse[]) {
  return responses.filter((response) => { return response.isSelected; });
}

function _canGeneratePdf(selectedResponsesCount: number) {
  const canBulkDownloadPdf = Feature.isActive('responses/bulk_download_pdf');

  return canBulkDownloadPdf || selectedResponsesCount <= defaultPageSize;
}

export {
  getSelectedResponsesCount,
  getSelectedFormIds,
  areAllSelectedOnPage,
  canGeneratePdf,
};
