// @ts-strict-ignore
import { InferThunkActionCreatorType } from 'react-redux';

import { updateResponsesQueryParams as updateQueryParams }
  from 'src/query_params/action_creators';

function updateMeta(meta: ResponsesMeta) {
  return { payload: meta, type: 'responses/UPDATE_META' };
}

export { updateMeta };

export type UpdateMeta = InferThunkActionCreatorType<typeof updateMeta>;
export type UpdateQueryParams =
  InferThunkActionCreatorType<typeof updateQueryParams>;
