import { Controller } from '@hotwired/stimulus';

class FeedbacksFormController extends Controller {
  toggle(event: DOMEvent): void {
    const button = event.currentTarget;
    const key = button.dataset.feedbackKey;
    const selector = `input[name='feedback[${key}]']`;
    const inputField = this.element.querySelector<HTMLInputElement>(selector);

    if (inputField) {
      inputField.value = inputField.value === 'false' ? 'true' : 'false';

      if (inputField.value === 'true') {
        button.classList.add('active');
      } else {
        button.classList.remove('active');
      }
    }
  }
}

export default FeedbacksFormController;
