// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';

class SanitizeLinksController extends Controller {
  connect(): void {
    this.element.querySelectorAll('a').forEach((link) => {
      link.target = '_blank';
      link.rel = 'noopener noreferrer';
    });
  }
}

export default SanitizeLinksController;
