// @ts-strict-ignore
import React from 'react';
import ReactDOM from 'react-dom';

import ContextMenuWrapper, { Options } from 'src/doc_editor/context_menu_wrapper';
import FormulaContextMenu from
  'src/doc_editor/fields/components/context_menus/formula';
import assertInclusion from 'src/helpers/assert_inclusion';

const MODE_VALUES: FormulaFieldMode[] = ['number', 'time'];

class FormulaContextMenuWrapper extends ContextMenuWrapper {
  updateDisplay(opts: Options): void {
    super.updateDisplay(opts);
    const { mode, number, content, errors, precision } = opts;

    const formulaMode = assertInclusion(MODE_VALUES, mode);

    ReactDOM.render(
      <React.StrictMode>
        <FormulaContextMenu
          allFieldsLoaded={this.allFieldsLoaded}
          content={content}
          errors={errors}
          getFields={this.getFields}
          mode={formulaMode}
          number={number}
          precision={precision}
          steps={this.steps}
          updateDocEditor={this.updateDocEditor}
          updateFields={this.updateFields}
        />
      </React.StrictMode>,
      this.fieldSpecificContainer,
    );
  }
}

export default FormulaContextMenuWrapper;
