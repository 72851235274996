// @ts-strict-ignore
import FieldConfigs from 'src/doc_editor/fields/config/defaults';

function isFieldRequireable(field: Partial<Field>): boolean {
  return FieldConfigs[field.type].requireable &&
    field.allowEdits &&
    field.mode !== 'auto_current';
}

export default isFieldRequireable;
