// @ts-strict-ignore
import classNames from 'classnames';
import React, { ReactNode } from 'react';

import { BoundUpdateFields } from 'src/chux/editable_fields/store';
import { fieldHeight, fieldWidth } from 'src/doc_editor/fields/helpers/sizing';
import serializedHtml from 'src/helpers/serialized_html';

type Props = {
  updateFields: BoundUpdateFields;
  content: string;
  fontSize: number;
  format: string;
  height: number;
  number: number;
  required: boolean;
  selected: boolean;
  width: number;
};

class Input extends React.Component<Props, never> {
  constructor(props: Props) {
    super(props);
    this.updateContent = this.updateContent.bind(this);
  }

  render(): ReactNode {
    const { required, selected } = this.props;

    return (
      <div
        className={
          classNames('requested-field', 'field-container', { required, selected })
        }
      >
        <div
          className='text-input input-field adjustable-font'
          contentEditable
          dangerouslySetInnerHTML={this.content()}
          style={this.inputStyle()}
          onBlur={this.updateContent}
        >
        </div>
      </div>
    );
  }

  inputStyle(): { height: number; width: number } {
    const { content, fontSize, width, height } = this.props;
    const defaultFontSize = fontSize || 10;
    const defaultHeight = fieldHeight(defaultFontSize);
    const defaultWidth = fieldWidth(defaultFontSize, content);

    return { height: height || defaultHeight, width: width || defaultWidth };
  }

  content(): { __html: string } {
    const { format } = this.props;
    let { content } = this.props;

    content = content ? content.replace(/\n/ug, '<br>') : '';
    return { __html: content || format };
  }

  updateContent(event: React.FocusEvent): void {
    const { number, updateFields } = this.props;
    const content = serializedHtml(event.target.textContent).replace(/\n/ug, '');
    const payload = { content, number };

    updateFields(payload);
  }
}

export type { Props };
export default Input;
