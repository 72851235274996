// @ts-strict-ignore
import { Json } from 'src/helpers/json';
import { getSelectedFormIds } from 'src/responses/selectors';

function formSetDataGenerator({ responses, campaign }) {
  const { areAllSelectedAcrossPages } = responses;
  const campaignId = campaign.id;
  const { formGroup } = responses.meta.queryParams;
  const baseFormSet = { campaignId, formGroup };

  if (areAllSelectedAcrossPages) {
    const {
      searchTerm,
      sortBy,
      direction,
      filters,
    } = responses.meta.queryParams;

    return {
      formSet: { ...baseFormSet, direction, filters, searchTerm, sortBy },
      formSetType: 'filtered',
    };
  }
  return {
    formSet: { ...baseFormSet, formIds: getSelectedFormIds({ responses }) },
    formSetType: 'selected',
  };
}

function generateFilteredFormSetData(
  campaignId: number,
  queryParams: Partial<ResponsesQueryParams>,
): Json {
  const { searchTerm, sortBy, direction, formGroup } = queryParams;
  const filters = searchTerm ? {} : queryParams.filters;

  return {
    formSet: { campaignId, direction, filters, formGroup, searchTerm, sortBy },
    formSetType: 'filtered',
  };
}

function generateSelectedFormSetData(
  campaignId: number,
  formIds: string[],
  queryParams: Partial<ResponsesQueryParams>,
): Json {
  const { formGroup } = queryParams;

  return {
    formSet: { campaignId, formGroup, formIds },
    formSetType: 'selected',
  };
}

export default formSetDataGenerator;
export { generateFilteredFormSetData, generateSelectedFormSetData };
