// @ts-strict-ignore
import React from 'react';
import ReactDOM from 'react-dom';

import ContextMenuWrapper, { Options } from 'src/doc_editor/context_menu_wrapper';
import ReferenceNumberContextMenu from
  'src/doc_editor/fields/components/context_menus/reference_number';

type ReferenceNumberOptions = Options & {
  referencePrefix: string;
  startingReferenceNumber: string;
  testing: boolean;
};

class ReferenceNumberContextMenuWrapper extends ContextMenuWrapper {
  constructor(opts: ReferenceNumberOptions) {
    super(opts);

    this.updateDisplay = this.updateDisplay.bind(this);
    this.updateDisplay(opts);
  }

  updateDisplay(opts: ReferenceNumberOptions): void {
    super.updateDisplay(opts);
    const {
      number,
      referencePrefix,
      startingReferenceNumber,
      editable,
      testing,
      errors,
    } = opts;

    ReactDOM.render(
      <React.StrictMode>
        <ReferenceNumberContextMenu
          editable={editable}
          errors={errors}
          number={number}
          referencePrefix={referencePrefix}
          startingReferenceNumber={startingReferenceNumber}
          testing={testing}
          updateFields={this.updateFields}
        />
      </React.StrictMode>,
      this.fieldSpecificContainer,
    );
  }
}

export default ReferenceNumberContextMenuWrapper;
export type { ReferenceNumberOptions };
