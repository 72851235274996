// @ts-strict-ignore
import React from 'react';

import DropdownButton from 'src/common/components/dropdown_button';
import { browserFormatDate, daysAgo } from 'src/helpers/date';
import { loadDialog } from 'src/helpers/dialog';

const DATE_STRING_TO_NUM_DAYS_AGO = {
  'Last 7 days': 6,
  'Last 30 days': 29,
  'Last 90 days': 89,
  Today: 0,
};

type Props = {
  filterKey: number | string;
  filterLabel: string;
};

function DateFilter(props: Props): React.JSX.Element {
  const { filterLabel, filterKey } = props;

  const modalUrl =
  `/date_ranges/new?filter_key=${filterKey}&filter_label=${filterLabel}`;

  return (
    <div>
      <DropdownButton
        buttonClassName='btn-link'
        label={filterLabel}
      >
        {relativeDateChoices(filterKey)}
        <li className='divider'></li>
        <li key='date-range'>
          <a
            href='#!'
            onClick={(): void => { loadDialog(modalUrl); }}
          >
            Custom range...
          </a>
        </li>
      </DropdownButton>
    </div>
  );
}

// private

function relativeDateChoices(filterKey: number | string): React.JSX.Element[] {
  return Object.keys(DATE_STRING_TO_NUM_DAYS_AGO).map((choice) => {
    const filterValue = relativeFilterValue(choice);

    return (
      <li key={choice} data-controller='responses-filter'>
        <a
          data-action='responses-filter#applyFilter'
          data-responses-filter-key-param={filterKey}
          data-responses-filter-value-param={JSON.stringify(filterValue)}
          href='#!'
        >
          {choice}
        </a>
      </li>
    );
  });
}

function relativeFilterValue(choice): { gte: string; lte: string } {
  const endDate = new Date();
  const startDate = daysAgo(DATE_STRING_TO_NUM_DAYS_AGO[choice]);

  return { gte: browserFormatDate(startDate), lte: browserFormatDate(endDate) };
}

export default DateFilter;
export type { Props };
