import validateEmail from 'src/helpers/validate_email';

export default function validateUrl(url: string): boolean {
  let parsedUrl: URL;

  try {
    parsedUrl = new URL(url);
  } catch {
    return false;
  }

  if (parsedUrl.protocol.toLowerCase() === 'mailto:') {
    return validateEmail(parsedUrl.pathname);
  } else if ((/^https?:$/i).test(parsedUrl.protocol)) {
    const re = /^[a-z\d][a-z\d-]*\.[a-z\d.-]+$/i;

    return re.test(parsedUrl.hostname);
  }
  return false;
}
