// @ts-strict-ignore
import FieldModel from 'src/doc_editor/fields/models/field';

const DEFAULTS = {
  content: 'mm/dd/yyyy',
  format: 'mm/dd/yyyy',
  mode: 'auto_current',
  required: false,
};

class Date extends FieldModel {
  constructor(opts) {
    super({ ...DEFAULTS, ...opts });
  }
}

export default Date;
