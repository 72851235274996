// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['checkbox', 'checkboxAll', 'startBulkApproval'];

  hasCheckboxAllTarget: boolean;
  checkboxTargets: HTMLInputElement[];
  checkboxAllTarget: HTMLInputElement;
  checkboxTarget: HTMLElement;
  startBulkApprovalTarget: HTMLButtonElement;

  get checked(): HTMLElement[] {
    return this.checkboxTargets.filter((checkbox) => { return checkbox.checked; });
  }

  get unchecked(): HTMLElement[] {
    return this.checkboxTargets.filter((checkbox) => { return !checkbox.checked; });
  }

  connect(): void {
    if (!this.hasCheckboxAllTarget) {
      return;
    }

    // When the user clicks back in the browser to the Inbox, checkboxes may be
    // checked, but they are not immediately registered as checked by Stimulus. The
    // Bulk Approval button will be disabled even if checkboxes are checked. The
    // issue is resolved after waiting a tick using setTimeout.
    // https://github.com/hotwired/stimulus/issues/328
    setTimeout(() => {
      this.refresh();
    });
  }

  toggleSelectAll(): void {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = this.checkboxAllTarget.checked;
    });

    this.toggleButtonState();
  }

  toggleButtonState(): void {
    this.startBulkApprovalTarget.disabled = this.checked.length === 0;
  }

  refresh(): void {
    this.toggleButtonState();

    this.checkboxAllTarget.indeterminate =
      this.checked.length > 0 && this.unchecked.length > 0;
  }
}
