type Json =
  { [key: string]: Json | number[] | string[] | boolean | number | string };

function stringifyValues(object: Json): Json {
  const stringifiedObject = { ...object };

  Object.keys(object).forEach((key) => {
    const item = object[key];

    if (Array.isArray(item)) {
      stringifiedObject[key] = JSON.stringify(object[key]);
    } else if (typeof item === 'object') {
      stringifiedObject[key] = JSON.stringify(snakeCaseKeys(item));
    }
  });

  return stringifiedObject;
}

function serializeParams(object: Json): string {
  return $.param(stringifyValues(snakeCaseKeys(object)));
}

function snakeCaseKeys(object: Json): Json {
  const snakeCasedObject: Json = {};

  Object.keys(object).forEach((key: string) => {
    let value = object[key];

    if (typeof value === 'object' && !Array.isArray(value)) {
      value = snakeCaseKeys(value);
    }

    snakeCasedObject[snakeCase(key)] = value;
  });

  return snakeCasedObject;
}

function snakeCase(string: string): string {
  return string.replace(/([A-Z])/g, '_$1').toLowerCase();
}

export { snakeCaseKeys, stringifyValues, serializeParams, snakeCase };
export type { Json };
