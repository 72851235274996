import Field from 'src/doc_editor/field';
import { assert } from 'src/helpers/assertion';
import $template from 'src/helpers/dollar_template';

class Time extends Field {
  shouldShowContextMenuOnInit(): boolean { return true; }

  $preview(): JQuery {
    const $previewElement = $template(
      'time-field-preview-template',
      { required: this.model.get('required') },
    );

    const $input = $previewElement.find('.text-input');

    $previewElement.css(this.$element.position());
    $input.css({
      height: this.$element.find('.requested-field').height(),
      width: this.$element.find('.requested-field').width(),
    }).html(this.$element.find('.text-input').html());

    return $previewElement;
  }

  previewOffset(): Coordinate {
    const outerWidth = assert(this.$element.outerWidth());
    const outerHeight = assert(this.$element.outerHeight());

    return { x: outerWidth, y: outerHeight };
  }
}

export default Time;
