import React, { ReactNode } from 'react';

import Icon from 'src/common/components/icon';

type Props = {
  text: string;
};

class Notes extends React.Component<Props, never> {
  private popoverRef = React.createRef<HTMLButtonElement>();

  componentDidMount(): void {
    const popoverElement = this.popoverRef.current;

    if (popoverElement) { $(popoverElement).popover(); }
  }

  render(): ReactNode {
    const { text } = this.props;

    const popoverDataAttributes = {
      'data-content': notes(text),
      'data-html': false,
      'data-placement': 'left',
      'data-toggle': 'popover',
      'data-trigger': 'hover | focus',
    };

    return (
      <button
        ref={this.popoverRef}
        className='link-text'
        type='button'
        {...popoverDataAttributes}
      >
        <span className='sr-only'>View Note</span>
        <Icon iconClassName='fa--actionable' name='fileText' />
      </button>
    );
  }
}

// private

function notes(text: string): string {
  const title = 'Notes: ';
  const noteText = `${text}`;

  return `${title}${noteText}`;
}

export default Notes;
export type { Props };
