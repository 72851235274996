// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';

import { assert } from 'src/helpers/assertion';

class SelectZipFilenameController extends Controller {
  static targets = [
    'defaultFormat',
    'dropdownMenu',
    'pillButtonTemplate',
    'pillButtonList',
  ];

  defaultFormatTarget: HTMLDivElement;
  dropdownMenuTarget: HTMLDivElement;
  pillButtonTemplateTarget: HTMLTemplateElement;
  pillButtonListTarget: HTMLDivElement;

  initialize(): void {
    this.disablePreselectedFilename();
  }

  disablePreselectedFilename(): void {
    const keys = [];
    Array.from(this.pillButtonListTarget.children)
      .forEach((item) => { return keys.push(item.id); });

    const dropdownItems = this.dropdownMenuTarget.querySelectorAll('button');
    dropdownItems.forEach((node) => {
      if (keys.includes(node.getAttribute('data-key'))) {
        node.disabled = true;
      }
    });
  }

  addFieldName(event: HTMLButtonActionEvent<{ key: string; type: string }>): void {
    const template =
      assert(this.pillButtonTemplateTarget.content.firstElementChild);
    const newPill = template.cloneNode(true) as HTMLLIElement;

    newPill.setAttribute('id', event.target.dataset.key);
    newPill.firstElementChild.insertAdjacentText(
      'afterbegin',
      event.target.textContent,
    );
    event.currentTarget.disabled = true;
    setInputValue(newPill, event.target.dataset.key, event.params.type);

    this.pillButtonListTarget.appendChild(newPill);

    if (this.pillButtonListTarget.childElementCount === 1) {
      this.defaultFormatTarget.classList.add('hide');
    }
  }

  removeFieldName(event: DOMEvent): void {
    const liElement = event.target.closest('li');
    liElement.remove();

    const dropdownItems = this.dropdownMenuTarget.querySelectorAll('button');
    dropdownItems.forEach((button) => {
      if (liElement.id === button.getAttribute('data-key')) {
        button.disabled = false;
      }
    });

    if (this.pillButtonListTarget.childElementCount === 0) {
      this.defaultFormatTarget.classList.remove('hide');
    }
  }
}

// private

function setInputValue(target: Element, key: string, type: string): void {
  const input = target.querySelector('input');
  input.value = JSON.stringify({ key, partType: type });
}

export default SelectZipFilenameController;
