// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';

class TableRowAccordionToggleController extends Controller<HTMLElement> {
  $element: JQuery;
  $toggleRow: JQuery;

  connect(): void {
    this.$element = $(this.element);
    this.$toggleRow = this.$element.closest('.wb-tr-accordion');
    this.toggleAccordion = this.toggleAccordion.bind(this);

    this.$element.on('click', this.toggleAccordion);
  }

  toggleAccordion(): void {
    this.$toggleRow.toggleClass('wb-tr-accordion--expand');
    this.$element.toggleClass('tr-accordion-toggle-button--expand');
  }
}

export default TableRowAccordionToggleController;
