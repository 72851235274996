// @ts-strict-ignore
type EventType =
  'ajax:complete' |
  'ajax:error' |
  'ajax:stopped' |
  'ajax:success' |
  'beforeunload' |
  'blur' |
  'change' |
  'click' |
  'close' |
  'drag' |
  'dragend' |
  'dragenter' |
  'draggableItemAdded' |
  'dragstart' |
  'input' |
  'keydown' |
  'keyup' |
  'load' |
  'mousedown' |
  'mouseenter' |
  'mouseover' |
  'mouseup' |
  'showLoader' |
  'submit' |
  'turbo:frame-missing' |
  'turbo:submit-start' |
  'turbo:before-fetch-request' |
  'turbo:frame-load' |
  'unload' |
  'wheel';
type EventHandler = (event?) => void;

function stopPropagation(event): void {
  event.stopPropagation();
}

function triggerEvent<T>(
  target: EventTarget,
  eventType: EventType,
  detail: T = null,
): void {
  const event = new CustomEvent(eventType, { bubbles: true, detail });

  target.dispatchEvent(event);
}

function onClick(element: Element, handler: EventHandler);
function onClick(element: Element, selector: string, handler: EventHandler);
function onClick(
  element: Element,
  selectorOrHandler: EventHandler | string,
  handler?: EventHandler,
): void {
  if (typeof selectorOrHandler === 'function') {
    element.addEventListener('click', selectorOrHandler);
  } else {
    element.addEventListener('click', (event: DOMEvent) => {
      const { target } = event;

      const matchedElement = target.closest(selectorOrHandler);

      if (matchedElement !== null) {
        handler(event);
      }
    });
  }
}

function captureUJSEvents(element: HTMLElement, handler: EventHandler): void {
  element.addEventListener('ajax:success', handler);

  // prevent rails UJS from re-enabling submit button
  element.addEventListener('ajax:complete', stopPropagation);
  element.addEventListener('ajax:stopped', stopPropagation);
}

export { triggerEvent, onClick, captureUJSEvents };
