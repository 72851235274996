// @ts-strict-ignore
import { FORM_SET_INTERVAL } from 'src/form_sets/config/timing';
import { fetchGet, fetchPost } from 'src/helpers/fetch';
import Location from 'src/helpers/location';
import notify from 'src/helpers/notify';

const REPORTS_PATH = '/api/form_sets/reports';
const FORM_SETS_PATH = '/api/form_sets';

function createReport(
  formSetParams: FormSetParams,
  reportParams: ReportParams,
  events: ReportCreationEvents,
): void {
  _createFormSet(formSetParams).then(({ data: formSet }) => {
    _createReport({ ...reportParams, formSetId: formSet.id }).then(() => {
      Location.addBeforeUnload(_beforeUnloadKey(formSet.id));
      _getFormSetFile(formSet, events);
    }, notify);
  }, notify);
}

// private

function _getFormSetFile(formSet: FormSet, events: ReportCreationEvents): void {
  const { onProcessing, onCompleted } = events;

  setTimeout(() => {
    fetchGet(`${FORM_SETS_PATH}/${formSet.id}`)
      .then(({ data: updatedFormSet }) => {
        if (updatedFormSet.status === 'completed') {
          onCompleted(updatedFormSet);
          Location.removeBeforeUnload(_beforeUnloadKey(formSet.id));
        } else {
          if (onProcessing) { onProcessing(updatedFormSet); }

          _getFormSetFile(updatedFormSet, events);
        }
      }, notify);
  }, FORM_SET_INTERVAL);
}

function _createFormSet(data: FormSetParams) {
  return fetchPost(FORM_SETS_PATH, data);
}

function _createReport(data: ReportParams & { formSetId: number }) {
  return fetchPost(REPORTS_PATH, data);
}

function _beforeUnloadKey(formSetId: number): string {
  return `form_set_download_${formSetId}`;
}

export default createReport;
