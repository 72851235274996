import React, { ReactNode } from 'react';

import { BoundUpdateFields } from 'src/chux/editable_fields/store';

type Props = {
  checked: boolean;
  number: number;
  updateFields: BoundUpdateFields;
};

class ApproverCheckbox extends React.Component<Props, never> {
  constructor(props: Props) {
    super(props);
    this.notifyOfChange = this.notifyOfChange.bind(this);
  }

  render(): ReactNode {
    const { checked } = this.props;

    return (
      <label className='approver-checkbox labeled-checkbox'>
        <input checked={checked} type='checkbox' onChange={this.notifyOfChange} />
        Editable by approvers?
      </label>
    );
  }

  notifyOfChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const { number, updateFields } = this.props;

    updateFields({ editableBySpecialApprover: event.target.checked, number });
  }
}

export default ApproverCheckbox;
export type { Props };
