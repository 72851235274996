import keyBy from 'lodash/keyBy';
import React, { ReactNode } from 'react';

import confusedPapelUrl from 'assets/images/papel/confused_with_question.png';
import { assert } from 'src/helpers/assertion';
import BodyRow from 'src/responses/components/table/body_row';
import HeaderRow from 'src/responses/components/table/header_row';
import { isFilterApplied } from 'src/responses/helpers/query_params';

type Props = {
  campaign: Campaign;
  headers: Responses.TableHeader[];
  queryParams: ResponsesQueryParams;
  steps: Step[];
  responses?: FormResponse[];
};

class ResponsesTable extends React.Component<Props, never> {
  public static defaultProps: Partial<Props> = {
    responses: [],
  };

  render(): ReactNode {
    const { responses, queryParams, headers } = this.props;
    const hasResponses = Boolean(assert(responses).length);
    const hasSearchTerm = Boolean(queryParams.searchTerm);
    const visibleHeaders = headers.filter((header) => { return !header.hidden; });

    if (!hasResponses && (isFilterApplied(queryParams.filters) || hasSearchTerm)) {
      return this.emptyMessage();
    }

    return (
      <div>
        <div className='responses-table'>
          <table className='table table-hover'>
            <thead>
              <HeaderRow headers={visibleHeaders} queryParams={queryParams} />
            </thead>
            <tbody>{this.bodyRows(visibleHeaders)}</tbody>
          </table>
        </div>
      </div>
    );
  }

  emptyMessage(): React.JSX.Element {
    const { userFormGroupDescriptions } = this.props.campaign;
    const formGroup = assert(this.props.queryParams.formGroup);
    const otherFormGroups = { ...userFormGroupDescriptions };

    delete otherFormGroups[formGroup];

    return (
      <div className='page-message text-center text-md'>
        <img alt='not found' className='page-message__img' src={confusedPapelUrl} />
        {groupNotFoundMessage(assert(userFormGroupDescriptions[formGroup]))}
        {Object.keys(otherFormGroups).length > 0 && groupLinks(otherFormGroups)}
      </div>
    );
  }

  bodyRows(headers: Responses.TableHeader[]): React.JSX.Element[] {
    const {
      campaign,
      responses,
      steps,
    } = this.props;

    const stepsById = keyBy(steps, 'id');
    const attributes = headers.map((header) => { return header.attribute; });

    return assert(responses).map((response) => {
      return (
        <BodyRow
          key={response.formId}
          attributes={attributes}
          campaign={campaign}
          response={response}
          step={stepsById[response.stepId]}
        />
      );
    });
  }
}

// private

function groupNotFoundMessage(currentGroupName: string): React.JSX.Element {
  return (
    <p>
      We could not find what you are looking for
      in <b className='text-capitalize'>{currentGroupName}</b>
    </p>
  );
}

function groupLinks(
  formGroupDescriptions: UserFormGroupDescriptions,
): React.JSX.Element {
  const url = new URL(window.location.href);

  return (
    <React.Fragment>
      <p>Try searching in the following:</p>
      { Object.entries(formGroupDescriptions).map(([formGroup, name]) => {
        return (
          <p key={formGroup}>
            <a
              className='text-capitalize'
              href={`${url.pathname}?form_group=${formGroup}`}
            >
              {name}
            </a>
          </p>
        );
      })}
    </React.Fragment>
  );
}

export default ResponsesTable;
export type { Props };
