// @ts-strict-ignore
import createBasicReducer from 'src/common/basic_reducer';

const operations = {
  '@@INIT': initStore,
  'flashes/ADD': addFlash,
  'flashes/REMOVE': removeFlash,
};

function initStore() {
  return [];
}

function addFlash(previousState, flash) {
  return [...previousState, flash];
}

function removeFlash(previousState, id: number) {
  return previousState.filter((flash) => { return flash.id !== id; });
}

export default createBasicReducer(operations);
