// @ts-strict-ignore
import maxBy from 'lodash/maxBy';
import React from 'react';
import ReactDOM from 'react-dom';

import Field, { Options } from 'src/doc_editor/field';
import DropdownComponent from 'src/doc_editor/fields/components/dropdown';
import { fieldHeight, fieldWidth } from 'src/doc_editor/fields/helpers/sizing';
import FieldModel from 'src/doc_editor/fields/models/field';
import $template from 'src/helpers/dollar_template';

class Dropdown extends Field {
  constructor(opts: Options) {
    super(opts);

    this.updateFieldSize = this.updateFieldSize.bind(this);

    if (!opts.initCallbacks) { return; }

    this.$element.data('title', 'Click to add options').tooltip();

    this.model.on('change:content', this.updateFieldSize);
  }

  updateFieldSize(): void {
    const fontSize = this.model.get('fontSize');
    const longestValue = getLongestValue(this.model);
    const width = fieldWidth(fontSize, longestValue);
    const height = fieldHeight(fontSize);

    this.model.set({ height, width });
  }

  $preview(): JQuery {
    const elPosition = {
      height: this.$element.height(),
      left: this.$element.position().left,
      top: this.$element.position().top,
      width: this.$element.width(),
    };

    const $previewElement = $template(
      'preview-template',
      { fieldType: 'Dropdown' },
    );

    $previewElement.css(elPosition);

    const attrs = {
      label: this.model.get('label'),
      required: this.model.get('required'),
    };

    const component = (
      <React.StrictMode>
        <DropdownComponent {...attrs} />
      </React.StrictMode>
    );

    ReactDOM.render(component, $previewElement[0]);

    const $select = $previewElement.find('select').html('');

    displayChoices(this.model).forEach((value) => {
      $select.append($(`<option>${value}</option>`));
    });

    return $previewElement;
  }

  shouldShowContextMenuOnInit(): boolean { return true; }

  showContextMenu(): void {
    if (this.model.get('dragging')) { return; }
    if (!this.contextMenu) { this.initContextMenu(); }

    const data = {
      ...this.model.toJSON(),
      $parent: this.$element.parent(),
      width: this.$element.width(),
    };

    this.contextMenu.render(data);
  }
}

// private

function getLongestValue(model: FieldModel): string {
  return maxBy(
    displayChoices(model),
    (choice) => { return choice.length; },
  );
}

function displayChoices(model: FieldModel): string[] {
  const choices = model.get('content').split(';');

  return ['Select...', ...choices];
}

export default Dropdown;
