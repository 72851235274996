function findEl<T extends keyof AllElementTagNameMap>(
  scope: Document | Element,
  elementType: T,
  selector = '',
): AllElementTagNameMap[T] {
  return findEls(scope, elementType, selector, { count: 1 })[0];
}

function findEls<T extends keyof AllElementTagNameMap>(
  scope: Document | Element,
  elementType: T,
  attributeSelector: string,
  { count }: { count: number | 'any' } = { count: 'any' },
): AllElementTagNameMap[T][] {
  const selector = `${elementType}${attributeSelector}`;
  const nodeList = scope.querySelectorAll<AllElementTagNameMap[T]>(selector);
  const elements: AllElementTagNameMap[T][] = Array.from(nodeList);
  const actual = elements.length;

  if (actual === count || (count === 'any' && actual > 0)) {
    return elements;
  }

  throw new Error(`expected ${count} matches for <${selector}>, got ${actual}`);
}

function closestEl<T extends keyof AllElementTagNameMap>(
  scope: Element,
  elementType: T,
  attributeSelector: string,
): AllElementTagNameMap[T] {
  const selector = `${elementType}${attributeSelector}`;
  const node = scope.closest<AllElementTagNameMap[T]>(selector);

  if (node) {
    return node;
  }

  throw new Error(`expected 1 match for <${selector}>, found nothing`);
}

export { closestEl, findEl, findEls };
