// @ts-strict-ignore
import Backbone from 'backbone';

import autoLabel from 'src/doc_editor/fields/helpers/auto_label';
import NumberGenerator from 'src/doc_editor/fields/helpers/number_generator';

const DEFAULTS = {
  allowedInNumberFormula: false,
  allowEdits: true,
  content: '',
  dropdownId: null,
  editable: true,
  editableBySpecialApprover: false,
  formatLocked: false,
  selected: false,
};

class Field extends Backbone.Model {
  static idAttribute = 'number';

  constructor(opts) {
    super({ ...DEFAULTS, ...opts });
  }

  initialize(opts): void {
    this.set('number', NumberGenerator.next(opts.number));

    if (!this.get('label')) {
      this.set('label', autoLabel(this));
    }
  }
}

export default Field;
