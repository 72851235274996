// @ts-strict-ignore
import bindAll from 'lodash/bindAll';

import Field from 'src/form_filler/fields/field';

class CheckboxField extends Field {
  $element: JQuery;

  constructor(element: HTMLElement, data) {
    super(element, data);

    bindAll(this, 'showHover', 'hideHover');
    this.$element.on('mouseenter', this.showHover);
    this.$element.on('mouseleave click', this.hideHover);
  }

  getValue() {
    return this.$element.find('input').prop('checked');
  }

  showHover(): void {
    this.$element.find('i').addClass('hover');
  }

  hideHover(): void {
    this.$element.find('i').removeClass('hover');
  }
}

export default CheckboxField;
