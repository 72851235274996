// @ts-strict-ignore
import createBasicReducer from 'src/common/basic_reducer';

const operations = { '@@INIT': initStore, 'recipients/SET': setRecipients };

function initStore() {
  return { byId: {}, meta: {}, orderedIds: [] };
}

function setRecipients(_previousState, recipientData: Partial<Recipient>) {
  const orderedIds = Object.keys(recipientData).map(Number).sort();

  return { byId: recipientData, meta: {}, orderedIds };
}

export default createBasicReducer(operations);
