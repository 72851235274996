// @ts-strict-ignore
import isNil from 'lodash/isNil';
import React, { ReactNode } from 'react';

import { UpdateMeta } from 'src/steps/action_creators';
import StepNode from 'src/steps/components/step_diagram/step_node';

type Props = {
  selectedStepId: number | undefined;
  userPermissions: UserPermission[];
  steps: Step[];
  updateMeta: UpdateMeta;
};

class StepDiagram extends React.Component<Props, never> {
  render(): ReactNode {
    const { userPermissions } = this.props;
    let viewOnlyClass = '';

    if (!userPermissions.includes('view_step_approver')) {
      viewOnlyClass = 'step-diagram--view-only';
    }

    return (
      <div className={`step-diagram ${viewOnlyClass}`}>{this.stepNodes()}</div>
    );
  }

  stepNodes(): React.JSX.Element[] {
    const { steps, updateMeta, selectedStepId, userPermissions } = this.props;

    const allFormsCount = _allFormsCount(steps);
    const stepsCount = steps.length;

    return steps.map((step) => {
      const {
        id,
        label,
        seq,
        formRequestsCount,
        recipients,
        question,
        type,
        sourceStep,
        ccEmails,
        stepsToNotify,
        emailColumns,
      } = step;

      return (
        <StepNode
          key={id}
          allFormsCount={allFormsCount}
          emailColumns={emailColumns}
          hasQuestion={Boolean(question)}
          id={id}
          label={label}
          notificationsCount={ccEmails.length + stepsToNotify.length}
          recipientsCount={recipients && recipients.length}
          selectedStepId={selectedStepId}
          seq={seq}
          sourceSeq={sourceStep ? sourceStep.seq : null}
          stepFormsCount={formRequestsCount}
          stepsCount={stepsCount}
          type={type}
          updateMeta={updateMeta}
          userPermissions={userPermissions}
        />
      );
    });
  }
}

// private

function _allFormsCount(steps: Step[]): number {
  const areCountsDefined = steps.some((step) => {
    return !isNil(step.formRequestsCount);
  });

  if (areCountsDefined) {
    return steps.reduce((total, step) => {
      return total + step.formRequestsCount;
    }, 0);
  }
  return null;
}

export default StepDiagram;
export type { Props };
