// @ts-strict-ignore
import at from 'lodash/at';
import sortBy from 'lodash/sortBy';
import { createSelector } from 'reselect';

const alphabetizedRecipientsByStepId = createSelector(
  [_indexedSteps, _indexedRecipients],
  _alphabetizedRecipientsByStepId,
);

// private

function _indexedSteps(state) {
  return state.steps.byId;
}

function _indexedRecipients(state) {
  return state.recipients.byId;
}

function _alphabetizedRecipientsByStepId(
  indexedSteps: IndexedSteps,
  indexedRecipients: IndexedRecipients,
) {
  const recipientsByStepId = {};

  Object.values(indexedSteps).forEach((step: Step) => {
    if (step.type === 'RecipientStep') {
      const stepRecipients = at(indexedRecipients, step.recipients);
      const recipients = sortBy(
        stepRecipients,
        (recipient) => { return recipient.name.toLowerCase(); },
      );

      recipientsByStepId[step.id] = recipients;
    } else {
      recipientsByStepId[step.id] = [];
    }
  });

  return recipientsByStepId;
}

export default alphabetizedRecipientsByStepId;
