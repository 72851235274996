// @ts-strict-ignore
import FieldModel from 'src/doc_editor/fields/models/field';

const DEFAULTS = {
  allowedInNumberFormula: true,
  allowEdits: false,
  content: 'Group Data',
  required: false,
};

class Prefill extends FieldModel {
  constructor(opts) {
    super({ ...DEFAULTS, ...opts });
  }

  validate(): string[] | null {
    if (this.get('content') === 'Group Data') {
      return ['You must choose from the list of columns.'];
    }

    return null;
  }
}

export default Prefill;
