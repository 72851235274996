// @ts-strict-ignore
import { findEl } from 'src/helpers/finders';

class ReviewerColumn {
  $element: JQuery;
  element: HTMLElement;
  $matchedHeader: JQuery;
  header: string;
  matchedHeader: string;

  constructor(element: HTMLElement) {
    this.$element = $(element);
    this.element = element;

    this.lock = this.lock.bind(this);
    this.unlock = this.unlock.bind(this);

    this.$matchedHeader = $(this.matchedHeaderContainer);
    this.header = this.$element.find('strong').text();
  }

  get matchedHeaderContainer(): HTMLElement {
    return findEl(this.element, 'div', '.reviewer-column-matched-header');
  }

  getHeader(): string {
    return this.header;
  }

  getMatchedHeader(): string {
    return this.matchedHeader;
  }

  isLocked(): boolean {
    return this.$element.hasClass('locked');
  }

  setMatchedHeader(header: string): void {
    if (this.isLocked()) { return; }
    if (header) {
      this.matchedHeader = header;
      this.$element.addClass('selected');
    } else {
      this.matchedHeader = '';
      this.$element.removeClass('selected');
    }

    this.$matchedHeader.text(this.matchedHeader);
  }

  lock(): void {
    if (!this.$element.hasClass('selected')) {
      throw new Error('Cannot lock a column that is not selected');
    }

    this.$element.removeClass('selected').addClass('locked');
    this.$element.attr('aria-disabled', 'true');
  }

  unlock(): void {
    if (!this.$element.hasClass('locked')) {
      throw new Error('Cannot unlock a column that is not locked');
    }

    this.$element.removeClass('locked').addClass('selected');
    this.$element.removeAttr('aria-disabled');
  }

  getSummaryHtml(): string {
    const $clone = this.$element.clone();

    if ($clone.hasClass('locked')) {
      $clone.removeClass('locked').addClass('highlighted');
    }
    return $clone[0].outerHTML;
  }
}

export default ReviewerColumn;
