// @ts-strict-ignore
import sortBy from 'lodash/sortBy';
import React from 'react';
import ReactDOM from 'react-dom';

import ContextMenuWrapper, { Options } from 'src/doc_editor/context_menu_wrapper';
import DropdownContextMenu from
  'src/doc_editor/fields/components/context_menus/dropdown';

type DropdownOptions = Options & {
  content: string;
  dropdownId: number | null;
  isSubsetField: boolean;
  isTriggerField: boolean;
  sortDropdownChoices: boolean;
};

class DropdownContextMenuWrapper extends ContextMenuWrapper {
  updateDisplay(opts: DropdownOptions): void {
    super.updateDisplay(opts);
    const {
      content,
      number,
      sortDropdownChoices,
      isSubsetField,
      isTriggerField,
      dropdownId,
    } = opts;
    const dropdowns = window.jsData.sharedDropdowns;
    const docId = document.getElementById('doc').dataset.id;

    ReactDOM.render(
      <React.StrictMode>
        <DropdownContextMenu
          choices={getChoices(content, sortDropdownChoices)}
          docId={docId}
          dropdownId={dropdownId}
          dropdowns={dropdowns}
          isSubsetField={isSubsetField}
          isTriggerField={isTriggerField}
          number={number}
          sortChoices={sortDropdownChoices}
          updateFields={this.updateFields}
        />
      </React.StrictMode>,
      this.fieldSpecificContainer,
    );
  }
}

// private

function getChoices(content: string, sortDropdownChoices: boolean): string[] {
  const contentArray = content ? content.split(';') : [];
  const shouldSort = sortDropdownChoices;

  return shouldSort ? smartSortStrings(contentArray) : contentArray;
}

function smartSortStrings(strings: string[]): string[] {
  return sortBy(strings.sort(), (string) => {
    const number = parseInt(string, 10);

    return Number.isNaN(number) ? Infinity : number;
  });
}

export default DropdownContextMenuWrapper;
export type { DropdownOptions };
