// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';
import { Subscription } from '@rails/actioncable';

import consumer from 'src/channels/consumer';
import { assert } from 'src/helpers/assertion';
import { captureUJSEvents } from 'src/helpers/event';
import Location from 'src/helpers/location';

export default class extends Controller {
  static targets = ['form', 'submitButton'];

  ajaxPromise: Promise<string>;
  formTarget: HTMLElement;
  resolve: (value: string) => void;
  submitButtonTarget: HTMLInputElement;
  subscription: Subscription;

  disconnect(): void {
    this.subscription.unsubscribe();
  }

  connect(): void {
    this.submitButtonTarget.disabled = true;
    this.subscription = consumer.subscriptions.create(
      { channel: 'JobsChannel', form: this.formTarget.id },
      {
        connected: this.subscriptionConnected.bind(this),
        received: this.messageReceived.bind(this),
      },
    );

    this.ajaxPromise = new Promise((resolve) => { this.resolve = resolve; });

    captureUJSEvents(this.formTarget, (event) => {
      const { jobId } = event.detail[0].data;

      assert(jobId);
      this.resolve(jobId);
    });
  }

  subscriptionConnected(): void {
    this.submitButtonTarget.disabled = false;
  }

  async messageReceived(data): Promise<void> {
    const jobId = await this.ajaxPromise;

    assert(jobId);
    if (data.jobId === jobId) {
      const { downloadPath, jobResultPath } = data;

      if (downloadPath) {
        Location.downloadFileAndRedirect(downloadPath, jobResultPath);
        this.reset();
      } else {
        Location.navigateTo(jobResultPath);
      }
    }
  }

  reset(): void {
    this.ajaxPromise = new Promise((resolve) => { this.resolve = resolve; });
    this.submitButtonTarget.disabled = false;

    document.querySelectorAll(
      `button[form="${this.formTarget.id}"]`,
    ).forEach((button) => {
      (button as HTMLButtonElement).disabled = false;
    });
  }
}
