// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';

import { findEl } from 'src/helpers/finders';
import { events, subscribe } from 'src/helpers/pub_sub';

class DescriptionFieldsController extends Controller {
  connect(): void {
    const selectButton = findEl(
      this.element,
      'button',
      '[data-a11y-dialog-show="add-description-fields-modal"]',
    );
    const submitButton = findEl(this.element, 'button', '[type="submit"]');

    subscribe(events.DRAG_REORDER_LIST_CHANGED, () => {
      selectButton.disabled = true;
      submitButton.disabled = false;
    });
  }
}

export default DescriptionFieldsController;
