// @ts-strict-ignore
import { createStore, Unsubscribe } from 'redux';

import docEditorReducer from 'src/doc_editor/reducer';

let unsubscribers: Callback[] = [];

type StoreState = {
  showMedallions: boolean;
  activeFormulaNumber: number | null;
  activeFormulaMode: 'number' | 'time' | null;
  highlightedFields?: number[];
  activeNumbers?: Set<number>;
  allFieldsLoaded?: boolean;
  badgeType: BadgeType | null;
  changesMade?: boolean;
  continuePath?: string;
  fieldsAtLastSave?: FieldsByNumber;
  fontSize: number | null;
  groupColumns?: string[];
  hasAttachments?: boolean;
  indexedHighlightedFields: FieldsByNumber;
  requireAttachments?: boolean;
};

function resetStore(): void {
  unsubscribers.forEach((unsubscriber) => { unsubscriber(); });
  unsubscribers = [];
}

const reduxStore = createStore(docEditorReducer);

type UpdateAction = { payload: Partial<StoreState>; type: 'doc_editor/UPDATE' };
type ResetAction = { type: 'RESET' };

type Action = ResetAction | UpdateAction;

const DocEditorSingleStore = {
  dispatch(action: Action): void {
    if (action.type === 'RESET') {
      resetStore();
      reduxStore.dispatch({ type: 'doc_editor/RESET' });
    } else {
      reduxStore.dispatch(action);
    }
  },

  getState(): StoreState {
    return reduxStore.getState();
  },

  subscribe(callback: () => void): Unsubscribe {
    const unsubscribe = reduxStore.subscribe(callback);

    unsubscribers.push(unsubscribe);
    return unsubscribe;
  },

  update(payload: Partial<StoreState>): void {
    this.dispatch({ payload, type: 'doc_editor/UPDATE' });
  },
};

function allFieldsLoaded(): boolean {
  return DocEditorSingleStore.getState().allFieldsLoaded;
}

function getActiveNumbers(): number[] {
  const storeState: StoreState = DocEditorSingleStore.getState();
  const { activeNumbers } = storeState;

  return activeNumbers ? Array.from(activeNumbers) : [];
}

function getGroupColumns(): string[] {
  return DocEditorSingleStore.getState().groupColumns;
}

DocEditorSingleStore.update =
  DocEditorSingleStore.update.bind(DocEditorSingleStore);

type BoundAllFieldsLoaded = typeof allFieldsLoaded;
type BoundGetGroupColumns = typeof getGroupColumns;
type BoundUpdateDocEditor = typeof DocEditorSingleStore.update;

export default DocEditorSingleStore;
export { allFieldsLoaded, getActiveNumbers, getGroupColumns };
export type {
  Action,
  BoundAllFieldsLoaded,
  BoundGetGroupColumns,
  BoundUpdateDocEditor,
  StoreState,
};
