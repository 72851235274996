import detectCycle from './dependency_cycle_validator';
import GenericFormulaValidator from './generic_formula_validator';

type FormulaErrors = {
  number: number;
  errors: string[];
};

const FormulaValidator = {
  detectCycle,
  validate: GenericFormulaValidator.validate,
  validateAll: GenericFormulaValidator.validateAll,
};

export type { FormulaErrors };
export default FormulaValidator;
