// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { setCampaign } from 'src/campaign/action_creators';
import store from 'src/common/store';
import { fetchFeedbacks, updateMeta } from 'src/feedbacks/action_creators';
import FeedbacksIndexContainer from 'src/feedbacks/containers/index';
import notify from 'src/helpers/notify';
import { updateFeedbacksQueryParams as updateQueryParams }
  from 'src/query_params/action_creators';

const FEEDBACKS_PAGE_SIZE = 25;
const BASE_QUERY_PARAMS: Partial<FeedbacksQueryParams> = {
  filters: { statusGroup: 'feedbacks' },
  page: 1,
  pageSize: FEEDBACKS_PAGE_SIZE,
};

type Props = {
  searchParams: Partial<FeedbacksQueryParams>;
};

class FeedbacksController extends Controller {
  static values = {
    campaignId: Number,
    props: Object,
  };

  campaignIdValue: number;
  propsValue: Props;

  connect(): void {
    const { searchParams } = this.propsValue;
    const queryParams = { ...BASE_QUERY_PARAMS, ...searchParams };

    store.dispatch(setCampaign({ id: this.campaignIdValue }));
    store.dispatch(updateMeta({ queryParams }));
    store.dispatch(updateQueryParams(queryParams));
    type ThunkType = ThunkDispatch<never, null, AnyAction>;
    (store.dispatch as ThunkType)(fetchFeedbacks(this.campaignIdValue)).then(() => {
      ReactDOM.render(wrapper(), this.element);
    }, notify);
  }

  applyPage(event: ActionEvent<{ pageNum: number }>): void {
    const { pageNum } = event.params;
    const { page: currentPageNum } = this.propsValue.searchParams;

    if (pageNum === currentPageNum) { return; }

    updateQueryParam('page', pageNum.toString());
  }
}

// private

function updateQueryParam(key: string, value: string): void {
  const url = new URL(window.location.href);

  url.searchParams.set(key, value);

  Turbo.visit(url.toString());
}

function wrapper(): React.JSX.Element {
  return (
    <Provider store={store}>
      <React.StrictMode>
        <FeedbacksIndexContainer />
      </React.StrictMode>
    </Provider>
  );
}

export default FeedbacksController;
