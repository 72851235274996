// @ts-strict-ignore
import { connect } from 'react-redux';

import { removeFlash } from 'src/flashes/action_creators';
import Flashes, { Props } from 'src/flashes/components/flashes';

function mapStateToProps(state: State): Props {
  return {
    flashes: state.flashes,
    removeFlash,
  };
}

export default connect(mapStateToProps)(Flashes);
