export default function validateEmail(email: string): boolean {
  const re = new RegExp([
    '^(([^<>()[\\]\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\"]+)*)',
    '|(\\".+\\"))@((\\[\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.',
    '\\d{1,3}\\])|(([a-zA-Z\\-\\d]+\\.)+',
    '[a-zA-Z]{2,}))$',
  ].join(''));

  return re.test(email);
}
