// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';

import ResponseRowController from 'controllers/response_row_controller';

class ResponsesListController extends Controller<HTMLElement> {
  $element: JQuery;

  connect(): void {
    this.$element = $(this.element);

    this.initResponses = this.initResponses.bind(this);

    this.$element.on('responsesLoad', this.initResponses);
    this.initResponses();
  }

  initResponses(): void {
    this.$element.find('.response').each((_index, responseRow) => {
      new ResponseRowController(responseRow);
    });
  }
}

export default ResponsesListController;
