import notify from 'src/helpers/notify';

function assert<T>(value: T | null | undefined): T {
  if (value === null || value === undefined) {
    throw new Error('value is null or undefined');
  }

  return value;
}

function assertInstanceOf<T>(
  value: any,
  expectedType: new(...args: any[]) => T,
): value is T {
  if (value instanceof expectedType) { return true; }

  notify(`value '${value}' was not expected type '${expectedType.name}'`);

  return false;
}

function narrow<T>(value: any, expectedType: new(...args: any[]) => T): T {
  if (!(value instanceof expectedType)) {
    const message = `value '${value}' was not expected type '${expectedType.name}'`;
    throw new TypeError(message);
  }

  return value;
}

export { assert, assertInstanceOf, narrow };
