import React from 'react';
import ReactDOM from 'react-dom';

import ContextMenuWrapper, { Options } from 'src/doc_editor/context_menu_wrapper';
import DateContextMenu from 'src/doc_editor/fields/components/context_menus/date';

type DateOptions = Options & {
  mode: DateFieldMode;
};

class DateContextMenuWrapper extends ContextMenuWrapper {
  constructor(opts: DateOptions) {
    super(opts);

    this.updateDisplay = this.updateDisplay.bind(this);
    this.updateDisplay(opts);
  }

  updateDisplay(opts: DateOptions): void {
    super.updateDisplay(opts);
    const { mode, number } = opts;

    ReactDOM.render(
      <React.StrictMode>
        <DateContextMenu
          mode={mode}
          number={number}
          updateFields={this.updateFields}
        />
      </React.StrictMode>,
      this.fieldSpecificContainer,
    );
  }
}

export default DateContextMenuWrapper;
export type { DateOptions };
