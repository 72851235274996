// @ts-strict-ignore
import React from 'react';
import ReactDOM from 'react-dom';

import { BoundAllFieldsLoaded, BoundUpdateDocEditor }
  from 'src/chux/doc_editor/single_store';
import { BoundGetFields, BoundUpdateFields } from 'src/chux/editable_fields/store';
import { SetContent } from 'src/doc_editor/field';
import ApproverCheckbox from
  'src/doc_editor/fields/components/common/approver_checkbox';
import EditableLabel from 'src/doc_editor/fields/components/common/editable_label';
import FieldRuleLabel from './fields/components/common/field_rule_label';
import PrefillSelector from
  'src/doc_editor/fields/components/common/prefill_selector';
import StepSelector from 'src/doc_editor/fields/components/common/step_selector';
import FieldConfigs, { FieldConfig } from 'src/doc_editor/fields/config/defaults';
import autoLabel from 'src/doc_editor/fields/helpers/auto_label';
import $template from 'src/helpers/dollar_template';
import { findEl } from 'src/helpers/finders';
import grab from 'src/helpers/grab';

type Options = {
  config: FieldConfig;
  content?: string;
  dataSourceColumn: string;
  editable: boolean;
  editableBySpecialApprover: boolean;
  errors: string[];
  format?: string;
  formatLocked: boolean;
  hasFieldRule: boolean;
  label: string;
  mode?: FieldMode;
  number: number;
  precision?: number;
  sortDropdownChoices: boolean;
  stepId: number;
  stepSeq: number;
  type: FieldType;
  allFieldsLoaded: BoundAllFieldsLoaded;
  getFields: BoundGetFields;
  steps: Step[] | null;
  dataSource: DataSource;
  setContent: SetContent;
  updateDocEditor: BoundUpdateDocEditor;
  updateFields: BoundUpdateFields;
};

type RenderData = {
  $parent: JQuery;
  config: FieldConfig;
  editableBySpecialApprover: boolean;
  hasFieldRule: boolean;
  label: string;
  mode?: FieldMode;
  number: number;
  type: FieldType;
  xPos: number;
  yPos: number;
  width: number;
};

abstract class ContextMenuWrapper {
  $element: JQuery;
  element: HTMLElement;
  opts: Options;
  steps: Step[] | null;
  dataSource: DataSource;
  updateFields: BoundUpdateFields;
  updateDocEditor: BoundUpdateDocEditor;
  allFieldsLoaded: BoundAllFieldsLoaded;
  getFields: BoundGetFields;

  constructor(opts: Options) {
    this.hide = this.hide.bind(this);
    this.show = this.show.bind(this);

    this.opts = opts;
    this.steps = opts.steps;
    this.dataSource = opts.dataSource;
    this.$element = $template('context-menu-template');
    this.element = this.$element.get(0);
    this.updateFields = opts.updateFields;
    this.updateDocEditor = opts.updateDocEditor;
    this.allFieldsLoaded = opts.allFieldsLoaded;
    this.getFields = opts.getFields;

    this.$element.on('mousedown', (event) => { event.stopPropagation(); });
    this.$element.find('.exit-context-menu').on('click', this.hide);
    $('#doc').append(this.$element);

    this.updateDisplay(opts);
  }

  get editableByApproverContainer(): HTMLElement {
    return this.$element.find('.editable-by-approver-container')[0];
  }

  get fieldSpecificContainer(): HTMLElement {
    return findEl(this.element, 'div', '.field-specific-container');
  }

  get fieldLabel(): HTMLElement {
    return findEl(this.element, 'div', '.field-label');
  }

  get stepSelectorContainer(): HTMLElement {
    return findEl(this.element, 'div', '.step-selector-container');
  }

  get fieldSpecificFooterContainer(): HTMLElement {
    return findEl(this.element, 'div', '.field-specific-footer-container');
  }

  get prefillSelectorContainer(): HTMLElement {
    return findEl(this.element, 'div', '.prefill-selector-container');
  }

  get fieldRuleLabelContainer(): HTMLElement {
    return findEl(this.element, 'div', '.field-rule-label-container');
  }

  hide(): void {
    this.$element.hide();
  }

  show(): void {
    this.$element.show();
  }

  updateDisplay(opts: Options | RenderData): void {
    opts.config = grab(FieldConfigs, opts.type);
    this.opts = {
      ...this.opts,
      ...opts,
    };

    if (opts.config.labelable) { this.renderFieldLabel(opts); }
    if (opts.config.editable) { this.renderEditableBySpecialApprover(opts); }
    if (opts.config.stepSelectable) { this.renderStepSelector(); }
    if (opts.config.prefillable) { this.renderPrefill(); }
    if (opts.config.fieldRuleShowable) { this.renderFieldRuleLabel(opts); }
  }

  render(data: RenderData): void {
    this.updateDisplay(data);
    this.setPosition(data);
    this.show();
  }

  setPosition(data: RenderData): void {
    this.$element.appendTo(data.$parent);
    const position = { left: data.xPos + data.width + 5, top: data.yPos };

    this.$element.css(position);
  }

  renderStepSelector(): void {
    const { steps } = this;

    if (!steps || steps.length <= 1) { return; }

    const options = {
      editable: this.opts.editable,
      number: this.opts.number,
      selectedStepId: this.opts.stepId || steps[0].id,
      steps,
      updateFields: this.updateFields,
    };

    ReactDOM.render(
      <React.StrictMode>
        <StepSelector {...options} />
      </React.StrictMode>,
      this.stepSelectorContainer,
    );
  }

  renderPrefill(): void {
    if (!this.dataSource) { return; }

    const options = {
      dataSource: this.dataSource,
      dataSourceColumn: this.opts.dataSourceColumn,
      editable: this.opts.editable,
      format: this.opts.format,
      mode: this.opts.mode,
      number: this.opts.number,
      stepSeq: this.opts.stepSeq,
      type: this.opts.type,
      updateFields: this.updateFields,
    };

    ReactDOM.render(
      <React.StrictMode>
        <PrefillSelector {...options} key={Math.random()} />
      </React.StrictMode>,
      this.prefillSelectorContainer,
    );
  }

  renderFieldLabel(opts: Options | RenderData): void {
    const labelOpts = {
      name: 'label',
      update: (attrs: Options): void => {
        let { label } = attrs;
        const { number, type } = this.opts;
        const field = new Map([['number', `${number}`], ['type', type]]);

        if (label === autoLabel(field)) {
          label = null;
        }

        const payload = { label, number: opts.number };

        this.updateFields(payload);
      },
      value: opts.label,
    };

    ReactDOM.render(
      <React.StrictMode>
        <EditableLabel {...labelOpts} />
      </React.StrictMode>,
      this.fieldLabel,
    );
  }

  renderEditableBySpecialApprover(opts: Options | RenderData): void {
    const { steps } = this;

    if (!steps || steps.length <= 1) { return; }

    if (opts.mode === 'auto_current') {
      ReactDOM.render(null, this.editableByApproverContainer);
      return;
    }

    const options = {
      checked: opts.editableBySpecialApprover,
      number: opts.number,
      updateFields: this.updateFields,
    };

    ReactDOM.render(
      <React.StrictMode><ApproverCheckbox {...options} /></React.StrictMode>,
      this.editableByApproverContainer,
    );
  }

  renderFieldRuleLabel(opts: Options | RenderData): void {
    const { hasFieldRule } = opts;

    if (!hasFieldRule) { return; }

    ReactDOM.render(
      <React.StrictMode>
        <FieldRuleLabel />
      </React.StrictMode>,
      this.fieldRuleLabelContainer,
    );
  }
}

export type { Options, RenderData };
export default ContextMenuWrapper;
