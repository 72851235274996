import { Controller } from '@hotwired/stimulus';

class SortableItemController extends Controller {
  enableDrag(): void {
    this.element.setAttribute('draggable', 'true');
  }

  disableDrag(): void {
    this.element.setAttribute('draggable', 'false');
  }
}

export default SortableItemController;
