import DocPage from 'src/doc_editor/doc_page';
import { findEl, findEls } from 'src/helpers/finders';
import { events, subscribe } from 'src/helpers/pub_sub';

class DocPreview {
  $element: JQuery;
  element: HTMLElement;
  $docPages: JQuery;
  $arrows: JQuery;
  $pages: JQuery;
  dataIndex: number;
  subjectData: SubjectData[];

  constructor(element: HTMLElement) {
    this.$element = $(element);
    this.element = element;
    this.cycleForward = this.cycleForward.bind(this);
    this.cycleBackward = this.cycleBackward.bind(this);

    this.$docPages = $(this.docPages);
    this.$pages = $(this.pages);
    this.dataIndex = 0;
    this.subjectData = [];
    this.$arrows = $(this.arrows);
    this.$element.find('#next').on('click', this.cycleForward);
    this.$element.find('#prev').on('click', this.cycleBackward);

    subscribe(events.GROUP_SELECTION_CHANGED, this.setSubjectData.bind(this));
  }

  get modalBody(): HTMLElement {
    return findEl(this.element, 'div', '.doc-preview-modal-body');
  }

  get docPages(): HTMLElement {
    return findEl(this.modalBody, 'div', '.doc-pages');
  }

  get pages(): HTMLElement[] {
    return findEls(this.docPages, 'div', '.page');
  }

  get arrows(): HTMLElement {
    return findEl(this.element, 'div', '.preview-arrows');
  }

  show(pages: DocPage[]): void {
    this.renderFields(pages);
    this.updatePrefillData();
  }

  renderFields(pages: DocPage[]): void {
    this.$docPages.find('.editor-field').remove();

    pages.forEach((page) => {
      page.getFields().forEach((field) => {
        if (field.isValid()) {
          this.$pages.eq(page.getIndex()).append(field.$preview());
        }
      });
    });

    if (hasValidPrefills(pages)) {
      this.showArrows();
    } else {
      this.hideArrows();
    }
  }

  hideArrows(): void { this.$arrows.hide(); }

  showArrows(): void { this.$arrows.show(); }

  setSubjectData(groupData: GroupData): void {
    this.subjectData = groupData.subjectData;
    this.dataIndex = 0;
  }

  // #next and #prev callbacks used to cycle through the previews
  cycleForward(): void {
    this.dataIndex += 1;
    if (this.dataIndex >= this.subjectData.length) { this.dataIndex = 0; }

    this.updatePrefillData();
  }

  cycleBackward(): void {
    this.dataIndex -= 1;
    if (this.dataIndex < 0) { this.dataIndex = this.subjectData.length - 1; }

    this.updatePrefillData();
  }

  updatePrefillData(): void {
    if (this.subjectData.length === 0) { return; }

    const $prefills =
      this.$docPages.find('.editor-field[data-field-type="Prefill"]');

    $prefills.each((_, prefill) => {
      const $prefill = $(prefill);
      const column = $prefill.data('column');
      const value = this.subjectData[this.dataIndex][column] || '';

      $prefill.text(value);
    });
  }
}

// private

function hasValidPrefills(pages: DocPage[]): boolean {
  return pages.some((page) => { return page.hasValidPrefills(); });
}

export default DocPreview;
