// @ts-strict-ignore
import createBasicReducer from 'src/common/basic_reducer';

const operations = {
  'fields/ADD': addFields,
  'fields/BULK_SET': bulkSetFields,
  'fields/REMOVE': removeFields,
  'fields/UPDATE': updateFields,
};

const fieldReducer = createBasicReducer(operations);

function updateFields(storage, payload): void {
  storage.set(payload, { add: false, remove: false });
}

function bulkSetFields(storage, payload): void {
  storage.models.forEach((model) => {
    model.set(payload);
  });
}

function removeFields(storage, payload): void {
  storage.remove(payload.number);
}

function addFields(storage, payload): void {
  storage.add(payload);
}

class ReduxTransitionalStore {
  storage: any;

  constructor(storage) {
    this.storage = storage;
  }

  getState(): FieldsByNumber {
    return this.storage.getState();
  }

  subscribe(listener): void {
    this.storage.subscribe(listener);
  }

  dispatch(action): void {
    fieldReducer(this.storage, action);
  }
}

export default ReduxTransitionalStore;
