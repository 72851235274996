// @ts-strict-ignore
function createBasicReducer(operations) {
  return function basicReducer(previousState, action) {
    if (previousState === undefined) { return operations['@@INIT'](); }

    const operation = operations[action.type];

    if (!operation) { throw new Error(`invalid action type: ${action.type}`); }

    return operation(previousState, action.payload);
  };
}

export default createBasicReducer;
