const DOLLAR_OPTIONS = { currency: 'USD', style: 'currency' };

function formatNumber(
  originalValue: string,
  { precision }: { precision: number | null },
): string {
  const formatOptions = originalValue.includes('$') ? DOLLAR_OPTIONS : {};
  const sanitizedValue = sanitizeNumber(originalValue);

  if (!isValidNumber(sanitizedValue)) { return originalValue; }

  precision ??= grabPrecision(sanitizedValue);

  const number = Number(sanitizedValue);

  return number.toLocaleString('en-US', {
    ...formatOptions,
    maximumFractionDigits: precision,
    minimumFractionDigits: precision,
  });
}

function sanitizeNumber(value: string): string {
  return value.trim().replace('$', '').replace(/,/g, '');
}

function isValidNumber(value: string): boolean {
  return !(Number.isNaN(Number(value)) || Number.isNaN(parseFloat(value)));
}

// private

function grabPrecision(value: string): number {
  const [, fraction] = value.split('.');

  return fraction ? fraction.length : 0;
}

export { formatNumber, sanitizeNumber, isValidNumber };
