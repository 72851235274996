// @ts-strict-ignore
import { normalize } from 'normalizr';
import { InferThunkActionCreatorType } from 'react-redux';

import { fetchGet } from 'src/helpers/fetch';
import notify from 'src/helpers/notify';
import setRecipients from 'src/recipients/action_creators';
import stepSchema from 'src/steps/schema';

function setSteps(steps) {
  return { payload: steps, type: 'steps/SET' };
}

function updateMeta(meta) {
  return { payload: meta, type: 'steps/UPDATE_META' };
}

function fetchSteps(campaignId: number) {
  return function fetchStepsAsync(dispatch): void {
    const stepsPath = `/api/campaigns/${campaignId}/steps`;

    dispatch(updateMeta({ isFetching: true }));
    fetchGet(stepsPath).then(({ data }) => {
      const { entities } = normalize(data, [stepSchema]);

      dispatch(setSteps(entities.steps));
      dispatch(setRecipients(entities.recipients || {}));
      dispatch(updateMeta({ isFetching: false }));
    }, notify);
  };
}

export { fetchSteps, setSteps, updateMeta };

export type UpdateMeta = InferThunkActionCreatorType<typeof updateMeta>;
