// @ts-strict-ignore
import { connect } from 'react-redux';

import alphabetizedRecipientsByStepId from 'src/recipients/selectors';
import { updateMeta } from 'src/steps/action_creators';
import StepCard from 'src/steps/components/step_card';
import { getSelectedStep } from 'src/steps/selectors';

function mapStateToProps(state: State) {
  const { campaign } = state;
  const step = getSelectedStep(state);
  const recipients = alphabetizedRecipientsByStepId(state)[step.id];

  return { campaign, recipients, step };
}

export default connect(mapStateToProps, { updateMeta })(StepCard);
