// @ts-strict-ignore
import FieldModel from 'src/doc_editor/fields/models/field';

const DEFAULTS = {
  allowedInNumberFormula: true,
  required: false,
  sortDropdownChoices: false,
};

class Dropdown extends FieldModel {
  constructor(opts) {
    super({ ...DEFAULTS, ...opts });
  }

  validate(): string[] | null {
    const dropdownId = this.get('dropdownId');
    const content = this.get('content');

    if (!dropdownId && !content) {
      return ['No choices have been entered.'];
    }
    return null;
  }
}

export default Dropdown;
