import React from 'react';

import DropdownButton from 'src/common/components/dropdown_button';

const CHOICES = [
  { text: 'Attached', value: 'yes' },
  { text: 'Not attached', value: 'no' },
];

type Props = {
  filterKey: number | string;
  filterLabel: string;
};

function AttachmentFilter(props: Props): React.JSX.Element {
  const { filterLabel, filterKey } = props;

  return (
    <DropdownButton
      buttonClassName='btn-link'
      label={filterLabel}
    >
      {attachmentChoices(filterKey)}
    </DropdownButton>
  );
}

// private

function attachmentChoices(filterKey: number | string): React.JSX.Element[] {
  return CHOICES.map((choice) => {
    return (
      <li key={choice.value.toString()} data-controller='responses-filter'>
        <a
          data-action='responses-filter#applyFilter'
          data-responses-filter-key-param={filterKey}
          data-responses-filter-value-param={choice.value}
          href='#!'
        >
          {choice.text}
        </a>
      </li>
    );
  });
}

export default AttachmentFilter;
