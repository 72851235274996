// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';
import A11yDialog from 'a11y-dialog';

import Location from 'src/helpers/location';

type RedirectResponse = {
  url: string;
};

class DialogController extends Controller {
  static targets = ['close', 'frame', 'loader', 'dialog', 'redirect'];

  dialog: A11yDialog;
  frameTarget: HTMLElement;
  loaderTarget: HTMLTemplateElement;
  dialogTarget: HTMLDivElement;
  redirectTarget: HTMLDivElement;

  get loaderContent(): Node {
    return this.loaderTarget.content.firstElementChild.cloneNode(true);
  }

  initialize(): void {
    this.dialog = new A11yDialog(this.dialogTarget);
  }

  disconnect(): void {
    this.dialog.destroy();
  }

  redirect(event: CustomEvent<{ response: RedirectResponse }>): void {
    Location.navigateTo(event.detail.response.url);
  }

  showLoader(): void {
    this.frameTarget.innerHTML = '';
    this.frameTarget.appendChild(this.loaderContent);

    this.show();
  }

  show(): void {
    this.dialog.show();
    document.body.style.overflow = 'hidden';

    this.dialog.on('hide', () => { this.close(); });
  }

  close(): void {
    this.dialog.hide();
    this.frameTarget.innerHTML = '';
    document.body.style.overflow = '';
  }

  frameTargetConnected(): void {
    const { displayImmediately } = this.frameTarget.dataset;

    if (displayImmediately === 'true') { this.show(); }
  }

  redirectTargetConnected(): void {
    Location.navigateTo(this.redirectTarget.dataset.redirectTo);
  }

  closeTargetConnected(): void {
    this.close();
  }
}

export default DialogController;
