import classNames from 'classnames';
import React from 'react';

type Props = {
  content: string;
  selected: boolean;
};

function Formula(props: Props): React.JSX.Element {
  const { selected } = props;

  return (
    <div
      className={classNames('adjustable-font', 'field-container', { selected })}
    >
      {displayText(props.content)}
    </div>
  );
}

// private

function displayText(content: string): string {
  if (content) {
    return `= ${content}`;
  }
  return 'No formula.';
}

export default Formula;
