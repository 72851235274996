import getCSRFToken from 'src/helpers/get_csrf_token';
import { Json, snakeCaseKeys } from 'src/helpers/json';
import Location from 'src/helpers/location';

function defaultOptions(): RequestInit {
  return {
    cache: 'no-store',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': getCSRFToken(),
    },
    mode: 'same-origin',
    referrerPolicy: 'same-origin',
  };
}

type SerializedPair = [
  key: string,
  value: Json | number[] | string[] | boolean | number | string,
];

function encodePair(key: string, value: number | string): string {
  return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
}

function serializePair([key, value]: SerializedPair): string[] | string {
  if (typeof value === 'string' || typeof value === 'number') {
    return encodePair(key, value);
  } else if (Array.isArray(value)) {
    return value.map((item) => {
      return encodePair(`${key}[]`, item);
    });
  }
  return Object.entries(value).map(([nestedKey, nestedValue]) => {
    return encodePair(`${key}[${nestedKey}]`, nestedValue);
  });
}

function serializeQueryParams(queryParams: Json): string {
  const snakeParams = snakeCaseKeys(queryParams);

  return Object.entries(snakeParams).flatMap(serializePair).join('&');
}

function fetchGet(url: string, queryParams?: Json) {
  let fetchUrl = url;

  if (queryParams) {
    const queryString = serializeQueryParams(queryParams);

    fetchUrl = `${url}?${queryString}`;
  }

  return fetchRequest(fetchUrl, { method: 'GET' });
}

function fetchPut(url: string, data: object) {
  return fetchRequest(url, { body: JSON.stringify(data), method: 'PUT' });
}

function fetchPost(url: string, data?: object) {
  return fetchRequest(url, { body: JSON.stringify(data), method: 'POST' });
}

function fetchDelete(url: string, data?: object) {
  return fetchRequest(url, { body: JSON.stringify(data), method: 'DELETE' });
}

async function fetchRequest(url: string, options: RequestInit = {}) {
  const response = await fetch(url, { ...defaultOptions(), ...options });
  const responseText = await response.text();
  const responseJson = responseText ? JSON.parse(responseText) : {};

  switch (response.status) {
    case 200:
      return Promise.resolve(responseJson);
    case 204:
      return Promise.resolve();
    case 401:
      if (responseJson.data && responseJson.data.redirectUrl) {
        Location.navigateTo(responseJson.data.redirectUrl);
      }
      return Promise.reject(responseJson);
    default:
      return Promise.reject(responseJson);
  }
}

export { fetchGet, fetchPut, fetchPost, fetchDelete };
