// @ts-strict-ignore
let numberIndex = 0;

const NumberGenerator = {
  next(number): number {
    if (number) {
      numberIndex = Math.max(numberIndex, number);
      return number;
    }
    numberIndex += 1;
    return numberIndex;
  },

  reset(): void {
    numberIndex = 0;
  },
};

export default NumberGenerator;
