import { Controller } from '@hotwired/stimulus';
import * as d3 from 'd3';

import { fetchGet } from 'src/helpers/fetch';

const LABEL_OFFSET = 25;
const WIDTH = 960;
const NODE_DIAMETER = 30;
const NODE_DISTANCE = 50;

type RoutingDiagramElement =
    Omit<JQuery, 'data'> & { data: (key: string) => string };

type D3SVG = d3.Selection<SVGSVGElement, unknown, null, undefined>;

type DiagramStep = Step & { x: number; y: number; r: number };

class RoutingDiagramController extends Controller<HTMLElement> {
  $element!: RoutingDiagramElement;
  svg!: D3SVG;

  connect(): void {
    this.$element = $(this.element);

    this.renderDiagram = this.renderDiagram.bind(this);

    this.fetchData();
  }

  fetchData(): void {
    fetchGet(this.url()).then(this.renderDiagram);
  }

  url(): string {
    return `/api/campaigns/${this.campaignId()}/steps`;
  }

  campaignId(): string {
    return this.$element.data('campaign-id');
  }

  renderDiagram({ data }: { data: Step[] }): void {
    const dataNodes = addGraphMetadataToSteps(data);

    this.appendSvg((dataNodes.length + 1) * NODE_DISTANCE);

    insertLines(dataNodes, this.svg);
    insertCircles(dataNodes, this.svg);
    insertCounts(dataNodes, this.svg);
    insertDeleteIcons(dataNodes, this.svg);
    insertLabels(dataNodes, this.svg);
  }

  appendSvg(height: number): void {
    this.svg = d3.select(this.element)
      .append('svg')
      .attr('width', WIDTH)
      .attr('height', height);
  }
}

// private

function addGraphMetadataToSteps(data: Step[]): DiagramStep[] {
  return data.map((step, index) => {
    return { ...step, r: NODE_DIAMETER / 2, x: 40, y: (index + 1) * NODE_DISTANCE };
  });
}

function insertLines(dataNodes: DiagramStep[], svg: D3SVG): void {
  const dataLinks = [{ source: 0, target: dataNodes.length - 1 }];

  svg.selectAll('line')
    .data(dataLinks)
    .enter()
    .append('line')
    .attr('class', 'routing-diagram-line')
    .attr('x1', (d) => { return dataNodes[d.source].x; })
    .attr('y1', (d) => { return dataNodes[d.source].y; })
    .attr('x2', (d) => { return dataNodes[d.target].x; })
    .attr('y2', (d) => { return dataNodes[d.target].y; });
}

function insertCircles(dataNodes: DiagramStep[], svg: D3SVG): void {
  svg.selectAll('circle')
    .data(dataNodes)
    .enter()
    .append('circle')
    .attr('class', (_d, index) => {
      let myClass = 'routing-diagram-circle';

      if (index === 0) { myClass += ' first'; }
      return myClass;
    })
    .attr('r', (d) => { return d.r; })
    .attr('cx', (d) => { return d.x; })
    .attr('cy', (d) => { return d.y; });
}

function insertCounts(dataNodes: DiagramStep[], svg: D3SVG): void {
  svg.selectAll('text.routing-diagram-number')
    .data(dataNodes)
    .enter()
    .append('text')
    .attr('class', (_d, index) => {
      let myClass = 'routing-diagram-number';

      if (index === 0) { myClass += ' first'; }
      return myClass;
    })
    .attr('x', (d) => { return d.x; })
    .attr('y', (d) => { return d.y; })
    .text((d) => { return d.formRequestsCount; })
    .style('pointer-events', 'none');
}

function getDeleteableNodes(nodes: DiagramStep[]): DiagramStep[] {
  return nodes.filter((node) => {
    return node.seq !== 1 && node.type !== 'SequentialGroupStep';
  });
}

function insertDeleteIcons(dataNodes: DiagramStep[], svg: D3SVG): void {
  const deletableNodes = getDeleteableNodes(dataNodes);

  svg.selectAll('text.step-delete-cross')
    .data(deletableNodes)
    .enter()
    .append('text')
    .attr('x', (d) => { return d.x; })
    .attr('y', (d) => { return d.y; })
    .attr('data-action', 'click->route-editor#confirmAndDeleteStep')
    .attr('data-route-editor-seq-param', (d) => { return d.seq; })
    .attr('data-route-editor-label-param', (d) => { return d.label; })
    .attr('data-route-editor-id-param', (d) => { return d.id; })
    .attr('height', '15px')
    .attr('dx', -40)
    .attr('dy', 10)
    .attr('class', 'step-delete-cross')
    .attr('fill', 'red')
    .html('×');
}

function insertLabels(dataNodes: DiagramStep[], svg: D3SVG): void {
  svg.selectAll('text.routing-diagram-label')
    .data(dataNodes)
    .enter()
    .append('text')
    .attr('class', 'routing-diagram-label')
    .attr('x', (d) => { return d.x + LABEL_OFFSET; })
    .attr('y', (d) => { return d.y; })
    .text((d) => { return `Step ${d.seq}: ${d.label}`; });
}

export default RoutingDiagramController;
