import React, { ReactNode } from 'react';

import { BoundUpdateFields } from 'src/chux/editable_fields/store';
import { events, publish } from 'src/helpers/pub_sub';

type Props = {
  selectedStepId: number;
  steps: Step[];
  updateFields: BoundUpdateFields;
  editable: boolean;
  number: number;
};

class StepSelector extends React.Component<Props, never> {
  constructor(props: Props) {
    super(props);
    this.updateStep = this.updateStep.bind(this);
  }

  render(): ReactNode {
    const { number, editable, selectedStepId, steps } = this.props;

    return (
      <div className='context-menu-steps col-md-12'>
        <label className='wb-u-font-bold' htmlFor={`field_step_${number}`}>
          Field for:
        </label>
        <select
          className='field-step-select'
          disabled={!editable}
          id={`field_step_${number}`}
          value={selectedStepId}
          onChange={this.updateStep}
        >
          {steps.map(renderStepOption)}
        </select>
      </div>
    );
  }

  updateStep(event: React.ChangeEvent<HTMLSelectElement>): void {
    const { number, updateFields } = this.props;

    updateFields({ number, stepId: parseInt(event.target.value, 10) });
    publish(events.STEP_ASSIGNMENT_CHANGED);
  }
}

// private

function renderStepOption(step: Step): React.JSX.Element {
  const { id, seq, label } = step;

  return <option key={id} value={id}>({seq}) {label}</option>;
}

export default StepSelector;
export type { Props };
