import classNames from 'classnames';
import React, { ReactNode } from 'react';

import Icon from 'src/common/components/icon';


type Props = {
  hasFieldRule: boolean;
};

class FieldLogic extends React.Component<Props, never> {
  render(): ReactNode {
    if (!this.props.hasFieldRule) { return null; }

    return (
      <div className={classNames('badge field-logic-badge')}>
        <Icon name='shareAlt' />
      </div>
    );
  }
}

export default FieldLogic;
