import classNames from 'classnames';
import React from 'react';
import ReactDOM from 'react-dom';

import Field from 'src/doc_editor/field';
import $template from 'src/helpers/dollar_template';

class Signature extends Field {
  $preview(): JQuery {
    const elPosition = {
      height: this.$element.height(),
      left: this.$element.position().left,
      top: this.$element.position().top,
      width: this.$element.width(),
    };

    const $previewElement = $template(
      'preview-template',
      { fieldType: 'Signature' },
    );

    $previewElement.css(elPosition);

    const className = classNames(
      'add-sig signature-btn btn btn-default',
      { required: this.model.get('required') },
    );
    const component = (
      <React.StrictMode>
        <button className={className} type='button'>Click to sign here</button>
      </React.StrictMode>
    );

    ReactDOM.render(component, $previewElement[0]);

    return $previewElement;
  }
}

export default Signature;
