// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';
import debounce from 'lodash/debounce';

import { fetchGet } from 'src/helpers/fetch';
import { findEl, findEls } from 'src/helpers/finders';
import { pluralizeWithCount } from 'src/helpers/pluralize';
import template from 'src/helpers/template';

class TestEmailsSearchController extends Controller<HTMLElement> {
  get searchInput(): HTMLInputElement {
    return findEl(this.element, 'input', '.test-emails__input-field');
  }

  get firstSubjectRadio(): HTMLInputElement {
    return findEls(this.element, 'input', '.test-emails__radio-button')[0];
  }

  get subjectOptionsContainer(): HTMLElement {
    return findEl(this.element, 'div', '.test-emails__options-container');
  }

  get screenReaderElement(): HTMLElement {
    return findEl(this.element, 'div', '.sr-only');
  }

  connect(): void {
    this.searchGroupMembers = debounce(this.searchGroupMembers.bind(this), 100);

    this.searchInput.addEventListener('keydown', this.searchGroupMembers);

    this.firstSubjectRadio.checked = true;
  }

  url(query: string): string {
    return `${this.element.dataset.subjectsPath}?search_term=${query}`;
  }

  async searchGroupMembers(): Promise<void> {
    const inputValue = this.searchInput.value;
    const result = await fetchGet(this.url(inputValue));

    this.renderSubjectRadioButtons(result.data);
  }

  createRadioButtons(subjects: object[]): string {
    return template('test-email-template', { subjects });
  }

  renderSubjectRadioButtons(subjects: object[]): void {
    if (subjects.length === 0) {
      this.searchInput.setCustomValidity('Please adjust your search text.');
      const output = template('test-email-no-subjects-template');

      this.subjectOptionsContainer.innerHTML = output;
    } else {
      this.searchInput.setCustomValidity('');
      const output = this.createRadioButtons(subjects);

      this.subjectOptionsContainer.innerHTML = output;
      this.firstSubjectRadio.checked = true;
    }
    this.screenReaderElement.innerHTML =
      pluralizeWithCount(
        subjects.length,
        'group member result',
        'group member results',
      );
  }
}

export default TestEmailsSearchController;
