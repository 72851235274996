// @ts-strict-ignore
import classNames from 'classnames';
import React, { ReactNode } from 'react';

import pickColor from 'src/helpers/pick_color';

type Props = {
  seq: number | null;
};

class StepNumber extends React.Component<Props, never> {
  render(): ReactNode {
    const { seq } = this.props;

    return (
      <div
        className={classNames('field-badge', { hide: seq === undefined })}
        style={{ background: pickColor(seq - 1) }}
      >
        {seq || ''}
      </div>
    );
  }
}

export default StepNumber;
