// @ts-strict-ignore
import FieldModel from 'src/doc_editor/fields/models/field';

const DEFAULTS = {
  allowedInNumberFormula: true,
  required: false,
};

class StaticText extends FieldModel {
  constructor(opts) {
    super({ ...DEFAULTS, ...opts });
  }

  validate(): string[] | null {
    return this.get('content') ? null : ['No content was entered into the input.'];
  }
}

export default StaticText;
