// @ts-strict-ignore
import FieldModel from 'src/doc_editor/fields/models/field';

const DEFAULTS = {
  content: 'Request E-Signature',
  required: false,
};

class Signature extends FieldModel {
  constructor(opts) {
    super({ ...DEFAULTS, ...opts });
  }
}

export default Signature;
