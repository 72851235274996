// @ts-strict-ignore
class ReviewerSummary {
  $element: JQuery;
  element: HTMLElement;

  constructor(element: HTMLElement) {
    this.$element = $(element);
    this.element = element;
  }

  hide(): void {
    this.$element.hide();
  }

  show(): void {
    this.$element.show();
  }

  highlightColumns(columns): void {
    let columnHTML = '';

    columns.forEach((column) => {
      columnHTML += column.getSummaryHtml();
    });
    this.$element.find('.reviewer-columns').empty().html(columnHTML);
  }
}

export default ReviewerSummary;
