import { Controller } from '@hotwired/stimulus';

class PopoverController extends Controller {
  connect(): void {
    const defaultParams: any = {
      html: 'true', placement: 'bottom', toggle: 'popover', trigger: 'hover focus',
    };

    $(this.element).popover(defaultParams);
  }
}

export default PopoverController;
