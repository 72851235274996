export default function updateWizardHeader(headerName: string): void {
  let headerUpdated = false;
  const $headers = $('.wizard-header h2');

  if ($headers.length <= 0) {
    return;
  }

  $headers.each((_index, header) => {
    const $header = $(header);

    if ($header.html().includes(headerName)) {
      if (headerUpdated) {
        throw new Error(`Two headers contain the string: ${headerName}`);
      }

      headerUpdated = true;
      $headers.removeClass('active');
      $header.addClass('active');
    }
  });

  if (!headerUpdated) {
    throw new Error(`Not a valid wizard header: ${headerName}`);
  }
}
