// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';

import { hide, show } from 'src/helpers/visibility';

class InputDropdown extends Controller {
  static targets = [
    'summary',
    'radioSection',
    'checkboxSection',
    'error',
  ];

  // Targets
  summaryTarget: HTMLElement;
  radioSectionTarget: HTMLElement;
  checkboxSectionTarget: HTMLElement;
  errorTarget: HTMLElement;

  // Target Helpers
  hasRadioSectionTarget: boolean;
  hasCheckboxSectionTarget: boolean;

  // Locals
  radios: HTMLInputElement[];
  checkboxes: HTMLInputElement[];
  radioSummary: string;
  checkboxSummary: string;
  hasError: boolean;

  get checkedCheckboxes(): HTMLInputElement[] {
    return this.checkboxes?.filter((checkbox) => { return checkbox.checked; });
  }

  get checkedRadio(): HTMLInputElement {
    return this.radios?.find((radio) => { return radio.checked; });
  }

  get hasRadioFilter(): number {
    return this.radioSummary?.length;
  }

  get hasCheckboxFilter(): number {
    return this.checkboxSummary?.length;
  }

  connect(): void {
    this.validateForm = this.validateForm.bind(this);

    if (this.hasRadioSectionTarget) {
      this.radios =
        Array.from(this.radioSectionTarget.getElementsByTagName('input'));
      this.updateRadioSummaryText();
    }

    if (this.hasCheckboxSectionTarget) {
      this.checkboxes =
        Array.from(this.checkboxSectionTarget.getElementsByTagName('input'));
      this.updateCheckboxSummaryText();
    }

    this.addFormValidation();
    hide(this.errorTarget);
  }

  updateCheckboxSummaryText(): void {
    if (this.checkedCheckboxes.length === this.checkboxes.length) {
      this.checkboxSummary = '';
      this.toggleErrorState(false);
    } else {
      const checkboxSelectedText = this.checkedCheckboxes.map((checkbox) => {
        return checkbox.parentNode.textContent.trim();
      });

      this.checkboxSummary = checkboxSelectedText.join(', ');
      this.toggleErrorState(!checkboxSelectedText.length);
    }
    this.updateSummaryText();
  }

  updateRadioSummaryText(): void {
    let radioSummary = '';

    if (this.checkedRadio) {
      const selected = this.checkedRadio.parentNode.textContent.trim();

      radioSummary = selected === 'All' ? '' : selected;
    }

    this.radioSummary = radioSummary;

    this.updateSummaryText();
  }

  updateSummaryText(): void {
    const summaries = [];

    if (this.hasRadioFilter) {
      summaries.push(this.radioSummary);
    }
    if (this.hasCheckboxFilter) {
      summaries.push(this.checkboxSummary);
    }

    if (summaries.length) {
      this.summaryTarget.textContent = summaries.join(', ');
    } else {
      this.summaryTarget.textContent = 'Apply Filter';
    }
  }

  toggleErrorState(showError: boolean): void {
    if (showError && !this.hasError) {
      this.summaryTarget.classList.add('wb-field--error');
      show(this.errorTarget);
      this.hasError = true;
    } if (!showError && this.hasError) {
      this.summaryTarget.classList.remove('wb-field--error');
      hide(this.errorTarget);
      this.hasError = false;
    }
  }

  addFormValidation(): void {
    const form = this.element.closest('form');

    if (form) {
      form.addEventListener('submit', this.validateForm);
    }
  }

  validateForm(event: Event): void {
    if (this.hasError) {
      event.stopPropagation(); // prevents button from becoming disabled.
      event.preventDefault();
    }
  }
}

export default InputDropdown;
