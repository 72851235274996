// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';

import { findEl } from 'src/helpers/finders';

class FieldIteratorController extends Controller {
  $nextButton: JQuery<HTMLButtonElement>;
  $prevButton: JQuery<HTMLButtonElement>;
  currentFieldIndex: number;
  focusFns: Callback[];

  get prevButton(): HTMLButtonElement {
    return findEl(this.element, 'button', '.prev-btn');
  }

  get nextButton(): HTMLButtonElement {
    return findEl(this.element, 'button', '.next-btn');
  }

  connect(): void {
    this.focusOnField = this.focusOnField.bind(this);

    this.focusFns = this.extractFocusFnsFromFields();
    this.currentFieldIndex = -1;
    this.$prevButton = $(this.prevButton);
    this.$nextButton = $(this.nextButton);

    this.$prevButton.on('click', this.focusOnField(-1));
    this.$nextButton.on('click', this.focusOnField(1));

    this.maybeDisableBtns();
  }

  /**
     * @returns {Array}, which describe what we should do when we
     *   want to focus on a particular field:
     *  - for $textareas, we do $textarea.trigger('focus')
     *  - for $buttons, $checkboxes, and $dropdowns, we scroll to the button
     */
  extractFocusFnsFromFields(): Callback[] {
    const extractedFns: Callback[] = [];

    // Iterate through the possible fields: if it is a valid field, then
    //   create a focusFn
    $('.view_container').find('.view_tag, a.add-sig').each((_index, field) => {
      const $field = $(field);
      let focusFn: () => void;
      const fieldIndex = extractedFns.length;

      // Let's see if we should create a focusFn for this element
      if ($field.hasClass('view_tag')) {
        const $formElement = $field.find('textarea, select, input[type="text"]');

        if ($formElement.length > 0) {
          focusFn = (): void => { $formElement.trigger('focus'); };
          this.addFocusBlurHandlers($formElement, fieldIndex);
        }
      } else if ($field.hasClass('add-sig')) {
        focusFn = (): void => { $field.trigger('focus'); };

        $field.on('click', () => {
          this.updateCurrentField(fieldIndex);
        });
      }

      if (focusFn) { extractedFns.push(focusFn); }
    });

    return extractedFns;
  }

  updateCurrentField(index: number): void {
    this.currentFieldIndex = index;
    this.maybeDisableBtns();
  }

  addFocusBlurHandlers($element: JQuery, fieldIndex: number): void {
    $element.on('focus', () => { this.updateCurrentField(fieldIndex); });
  }

  focusOnField(offset: number) {
    return (event: JQuery.ClickEvent<HTMLElement, null, HTMLElement>): void => {
      if (event.currentTarget.getAttribute('disabled')) { return; }

      const nextIndex = this.currentFieldIndex + offset;

      this.updateCurrentField(nextIndex);
      this.focusFns[nextIndex]();
    };
  }

  maybeDisableBtns(): void {
    if (this.currentFieldIndex <= 0) {
      this.$prevButton.prop('disabled', true);
    } else {
      this.$prevButton.prop('disabled', false);
    }

    if (this.currentFieldIndex >= this.focusFns.length - 1) {
      this.$nextButton.prop('disabled', true);
    } else {
      this.$nextButton.prop('disabled', false);
    }
  }
}

export default FieldIteratorController;
