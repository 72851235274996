// @ts-strict-ignore
import routie from 'routie';

import FileUploaderController from 'controllers/file_uploader_controller';
import DataReviewer from 'src/group_wizard/data_reviewer';
import ReviewerSummary from 'src/group_wizard/reviewer_summary';
import Dialog from 'src/helpers/dialog';
import Feature from 'src/helpers/feature';
import { findEl } from 'src/helpers/finders';
import { events, subscribe } from 'src/helpers/pub_sub';
import updateWizardHeader from 'src/helpers/update_wizard_header';

// We have two different paths for headers
const EXPECTED_HEADERS_FOR_PATH = {
  full: ['full name', 'email'],
  split: ['first name', 'last name', 'email'],
};

const VALID_GROUP_MESSAGE = 'Make sure you enter a group name and choose ' +
  'a valid CSV.';

class GroupUploaderController extends FileUploaderController {
  $element: JQuery;
  $multiEmailToggle: JQuery;
  $uploaderContainer: JQuery;
  currentPath: 'full' | 'split';
  dataReviewer: DataReviewer;
  errorMsg: string;
  reviewerSummary: ReviewerSummary;
  validExtensions: string[];

  connect(): void {
    super.connect();

    this.dataReviewer =
      new DataReviewer(findEl(this.element, 'div', '.data-reviewer'));
    this.$multiEmailToggle = $(this.multiEmailToggle);
    this.$uploaderContainer = $(this.uploaderContainer);
    this.currentPath = 'split';
    this.errorMsg = VALID_GROUP_MESSAGE;
    this.validExtensions = ['.csv'];

    this.multiEmailReviewRoute = this.multiEmailReviewRoute.bind(this);
    this.multiEmailRoute = this.multiEmailRoute.bind(this);
    this.reviewRoute = this.reviewRoute.bind(this);
    this.reviewSummaryRoute = this.reviewSummaryRoute.bind(this);
    this.rootRoute = this.rootRoute.bind(this);

    subscribe(events.SIMPLE_REVIEWER_STEP_SELECTED, (currentHeader) => {
      const expectedHeaders = EXPECTED_HEADERS_FOR_PATH[this.currentPath];
      const index = expectedHeaders.indexOf(currentHeader);

      if (index === -1) {
        throw new Error(`Current header not in expected: ${currentHeader}`);
      }

      if (index === expectedHeaders.length - 1) {
        routie('review_summary');
      } else {
        const formattedHeader = formatForRoute(expectedHeaders[index + 1]);

        routie(`review/${this.currentPath}/${formattedHeader}`);
      }
    });

    routie('', this.rootRoute);
    routie('review/:path/:header', this.reviewRoute);
    routie('review_summary', this.reviewSummaryRoute);
    routie('multi_email', this.multiEmailRoute);
    routie('multi_email_review', this.multiEmailReviewRoute);
  }

  get multiEmailToggle(): HTMLElement {
    return findEl(this.element, 'div', '.multiple-emails-toggle');
  }

  get uploaderContainer(): HTMLElement {
    return findEl(this.element, 'div', '.uploader-container');
  }

  maxFiles(): number {
    return 1;
  }

  multiEmailReview(): void {
    routie('multi_email_review');
  }

  reset(): void {
    const confirmed = Dialog.confirm('Are you sure you want to reset?');

    if (confirmed) {
      $(this.dataReviewer.element).find('.wizard-next').prop('disabled', true);
      this.resetMatching();
    }
  }

  submit(): void {
    this.dataReviewer.uploadSpreadsheet();
  }

  switchPath(event: Event): void {
    const path = $(event.currentTarget).data('path');
    const firstHeader = formatForRoute(EXPECTED_HEADERS_FOR_PATH[path][0]);

    routie(`review/${path}/${firstHeader}`);
  }

  switchToMultiEmail(): void {
    if (Feature.isActive('sequential_groups')) {
      routie('multi_email');
    } else {
      routie('multi_email_review');
    }
  }

  validateAndSubmit(): boolean {
    super.validateAndSubmit();

    const fileInput = window.document.getElementById('csv') as HTMLInputElement;
    const fullFilename = fileInput.value;

    const filenameWithEnding = fullFilename.substr(
      fullFilename.lastIndexOf('\\') + 1,
      fullFilename.length,
    );

    this.$element.find('.group-csv-title').text(filenameWithEnding);

    const { files } = fileInput; // FileList object

    this.dataReviewer.handleFileSelect(files, () => {
      this.resetMatching();
    });

    return true;
  }

  resetMatching(): void {
    const expectedHeaders = EXPECTED_HEADERS_FOR_PATH[this.currentPath];
    const formattedHeader = formatForRoute(expectedHeaders[0]);

    routie(`review/${this.currentPath}/${formattedHeader}`);

    this.dataReviewer.clearMatcher();
  }

  paramsValid(): boolean {
    return this.$element.find('#csv').val() && this.titleIsValid();
  }

  handleNewPathAndHeader(newPath, newHeader): void {
    if (!EXPECTED_HEADERS_FOR_PATH.hasOwnProperty(newPath)) {
      throw new Error(`The following path does not have headers: ${newPath}`);
    }

    if (newPath !== this.currentPath) {
      this.currentPath = newPath;
      this.dataReviewer.clearMatcher();
    }
    const expectedHeaders = EXPECTED_HEADERS_FOR_PATH[this.currentPath];
    let index: number;

    for (index = 0; index < expectedHeaders.length; index++) {
      if (newHeader === formatForRoute(expectedHeaders[index])) {
        break;
      }
    }

    if (index >= expectedHeaders.length) {
      throw new Error(`Route header is not in expected headers: ${newHeader}`);
    }

    if (this.dataReviewer.isValidState(expectedHeaders, index)) {
      this.dataReviewer.setExpectedHeader(expectedHeaders, index);

      const $dataReviewerElement = $(this.dataReviewer.element);

      $dataReviewerElement.find('.reviewer-show-on').each((_index, showOn) => {
        const $showOn = $(showOn);

        if ($showOn.data('show-on') === expectedHeaders[index]) {
          $showOn.show();
        } else {
          $showOn.hide();
        }
      });

      this.dataReviewer.show();

      updateWizardHeader('Review');
    } else {
      this.resetMatching();
    }
  }

  rootRoute(): void {
    this.dataReviewer.hide();
    this.$uploaderContainer.show();

    updateWizardHeader('Group Options');
  }

  reviewRoute(path, header): void {
    if (this.paramsValid()) {
      this.$uploaderContainer.hide();
      this.$multiEmailToggle.hide();
      if (this.reviewerSummary) { this.reviewerSummary.hide(); }

      this.dataReviewer.setMultiEmail(false);

      this.handleNewPathAndHeader(path, header);
    } else {
      routie('');
    }
  }

  reviewSummaryRoute(): void {
    if (this.paramsValid()) {
      this.dataReviewer.hide();

      if (!this.reviewerSummary) {
        this.reviewerSummary =
          new ReviewerSummary(findEl(document.body, 'div', '.reviewer-summary'));
      }
      this.reviewerSummary.highlightColumns(this.dataReviewer.getColumns());

      this.reviewerSummary.show();
    } else {
      routie('');
    }
  }

  multiEmailRoute(): void {
    if (this.paramsValid()) {
      this.dataReviewer.hide();
      this.$multiEmailToggle.show();
    } else {
      routie('');
    }
  }

  multiEmailReviewRoute(): void {
    if (this.paramsValid()) {
      this.$multiEmailToggle.hide();
      this.dataReviewer.setMultiEmail(true);
      this.dataReviewer.show();
    } else {
      routie('');
    }
  }
}

function formatForRoute(verboseHeader: string): string {
  return verboseHeader.toLowerCase().replace(/ /g, '_');
}

export default GroupUploaderController;
