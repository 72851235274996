// @ts-strict-ignore
import update from 'immutability-helper';

import createBasicReducer from 'src/common/basic_reducer';

const operations = {
  '@@INIT': initStore,
  'responses/UPDATE_META': updateMeta,
};

function initStore() {
  return { meta: { pagination: { totalCount: 0, totalPages: 0 } } };
}

function updateMeta(previousState: ResponsesState, meta) {
  return update(previousState, { meta: { $merge: meta } });
}

export default createBasicReducer(operations);
