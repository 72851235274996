// @ts-strict-ignore
import { InferThunkActionCreatorType } from 'react-redux';

function updateFields(payload) {
  payload = Array.isArray(payload) ? payload : [payload];
  return { payload, type: 'fields/UPDATE' };
}

function removeFields(payload) {
  return { payload, type: 'fields/REMOVE' };
}

function addFields(payload) {
  return { payload, type: 'fields/ADD' };
}

export { updateFields, removeFields, addFields };
export type UpdateFields = InferThunkActionCreatorType<typeof updateFields>;
