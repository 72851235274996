import AttachmentContextMenuWrapper from
  'src/doc_editor/fields/context_menus/attachment_wrapper';
import CheckboxContextMenuWrapper from
  'src/doc_editor/fields/context_menus/checkbox_wrapper';
import DateContextMenuWrapper from
  'src/doc_editor/fields/context_menus/date_wrapper';
import DropdownContextMenuWrapper from
  'src/doc_editor/fields/context_menus/dropdown_wrapper';
import FormulaContextMenuWrapper from
  'src/doc_editor/fields/context_menus/formula_wrapper';
import InputContextMenuWrapper from
  'src/doc_editor/fields/context_menus/input_wrapper';
import PrefillContextMenuWrapper from
  'src/doc_editor/fields/context_menus/prefill_wrapper';
import ReferenceNumberContextMenuWrapper from
  'src/doc_editor/fields/context_menus/reference_number_wrapper';
import SignatureContextMenuWrapper from
  'src/doc_editor/fields/context_menus/signature_wrapper';
import StaticTextContextMenuWrapper from
  'src/doc_editor/fields/context_menus/static_text_wrapper';
import TimeContextMenuWrapper from
  'src/doc_editor/fields/context_menus/time_wrapper';

const ContextMenus = {
  Attachment: AttachmentContextMenuWrapper,
  Checkbox: CheckboxContextMenuWrapper,
  Date: DateContextMenuWrapper,
  Dropdown: DropdownContextMenuWrapper,
  Formula: FormulaContextMenuWrapper,
  Input: InputContextMenuWrapper,
  Prefill: PrefillContextMenuWrapper,
  ReferenceNumber: ReferenceNumberContextMenuWrapper,
  Signature: SignatureContextMenuWrapper,
  StaticText: StaticTextContextMenuWrapper,
  Time: TimeContextMenuWrapper,
};

export default ContextMenus;
