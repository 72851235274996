// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';

class ClipboardController extends Controller {
  static targets = ['copySuccessMessage'];

  static values = {
    copyText: String,
  };

  copyTextValue: string;
  copySuccessMessageTarget: HTMLElement;

  copy(): void {
    navigator.clipboard.writeText(this.copyTextValue);
    this.copySuccessMessageTarget.classList.remove('text-transparent');

    setTimeout(() => {
      this.copySuccessMessageTarget.classList.add('text-transparent');
    }, 2000);
  }
}

export default ClipboardController;
