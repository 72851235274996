// @ts-strict-ignore
import FieldConfigs from 'src/doc_editor/fields/config/defaults';
import { fieldFormats } from 'src/fields/config/formats';

function isFieldResizable(field: Field): boolean {
  return FieldConfigs[field.type].resizable &&
    isFormatResizable(field.format);
}

function isFormatResizable(format: string): boolean {
  const fieldFormat = fieldFormats[format];

  return fieldFormat === undefined || fieldFormat.fixedLength() === false;
}

export default isFieldResizable;
