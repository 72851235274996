// @ts-strict-ignore
import mapValues from 'lodash/mapValues';

import math from 'src/config/math';
import { CONTENT_NUMBER_REGEX } from 'src/constants';
import FormulaField from 'src/form_filler/fields/formula';
import FormulaDecorator from 'src/decorators/formula';
import calculateTimeFormula from 'src/helpers/calculate_time_formula';
import notify from 'src/helpers/notify';
import { sanitizeNumber } from 'src/helpers/number';
import { parseTime, roundAndFormatTime } from 'src/helpers/time';

type FormulaResult = {
  formulaField: FormulaField;
  value: string;
};

class FormulaEvaluator {
  formulaFields: FormulaField[];

  constructor({ formulaFields }: { formulaFields: FormulaField[] }) {
    this.formulaFields = formulaFields;
    this.recalculateFields = this.recalculateFields.bind(this);
  }

  recalculateFields(fieldsByNumber): FormulaResult[] {
    const results = this.formulaFields.map((formulaField) => {
      return this.recalculateField(fieldsByNumber, formulaField);
    });

    return results.filter((result): result is FormulaResult => {
      return result !== null;
    });
  }

  recalculateField(fieldsByNumber, formulaField: FormulaField): FormulaResult {
    const displayValue = calculateFieldValue(formulaField, fieldsByNumber);

    if (displayValue === formulaField.value) { return null; }

    return { formulaField, value: displayValue };
  }
}

// private

function calculateFieldValue(formulaField, fieldsByNumber): string {
  const formulaString = formulaField.fieldContent;
  const formula = new FormulaDecorator(formulaString);
  const { mode } = formulaField;

  let result;
  let displayValue: string;
  let variableValueMap;

  try {
    const userInputs = getUserInputValues(formula, fieldsByNumber);

    if (noValidUserInputsPresent(userInputs, mode)) { return null; }

    variableValueMap = getVariableValueMap(formula, fieldsByNumber);

    result = calculateFormula(formula, mode, fieldsByNumber);
    displayValue = result.isNaN() ? 'Invalid' : result.toString();
  } catch (error) {
    displayValue = 'Invalid';
    const context = {
      formula: formula.getFormulaString(),
      variableMapping: variableValueMap,
    };

    notify(error, context);
  }
  return displayValue;
}

function calculateFormula(formula, mode, fieldsByNumber) {
  const formulaString = formula.getFormulaString();
  const variableValueMap = getVariableValueMap(formula, fieldsByNumber);

  if (mode === 'time') {
    const hiddenFields = Object.values(variableValueMap).filter((value) => {
      return value === null;
    });

    if (hiddenFields.length > 0) { return math.bignumber(0); }
    return calculateTimeFormula(formulaString, variableValueMap);
  }

  return math.evaluate(
    formulaString,
    initializeEmptyInputs(variableValueMap),
  );
}

function noValidUserInputsPresent(userInputValues, mode): boolean {
  if (mode === 'time') {
    return userInputValues.every((timeString) => {
      return 'error' in parseTime(timeString || '');
    });
  }

  return userInputValues.every((number) => {
    return typeof number !== 'number' || Number.isNaN(number);
  });
}

function initializeEmptyInputs(formulaVars) {
  return mapValues(formulaVars, (value) => {
    return math.bignumber(value === undefined ? 0 : value);
  });
}

function getVariableValueMap(formula, fieldsByNumber) {
  const variables = formula.getVariables();
  const mapping = {};

  variables.forEach((variable) => {
    const field = fieldsByNumber[getFieldNumberFromVariable(variable)];

    mapping[variable] = sanitizeFieldValue(field);
  });

  return mapping;
}

function getUserInputValues(formula, fieldsByNumber): number[] {
  const fieldNumbers = formula.getFieldNumbers();
  const values: (number | null)[] = [];

  const nonUserInputTypes = new Set(['CommonField']);

  fieldNumbers.forEach((fieldNumber) => {
    const field = fieldsByNumber[fieldNumber];

    if (nonUserInputTypes.has(field.type)) { return; }

    values.push(sanitizeFieldValue(field));
  });

  return values;
}

function getFieldNumberFromVariable(variable): number {
  return parseFloat(variable.slice(1));
}

function sanitizeFieldValue(field) {
  let { value } = field;
  const { type } = field;

  if (field.element?.hidden) { return null; }

  if (type === 'TimeField') { return sanitizeTime(field); }

  if (typeof value === 'string') { value = sanitizeNumber(value); }

  if (type === 'DropdownField' && value) {
    value = getNumberFromDropdown(value);
  }

  return value ? parseFloat(value) : null;
}

function sanitizeTime({ value, interval }) {
  const parsedTime = parseTime(value);

  // don't attempt to sanitize an invalid time
  if ('error' in parsedTime) { return value; }

  return roundAndFormatTime({ ...parsedTime, interval });
}

function getNumberFromDropdown(value) {
  return isFinite(value) ? value : grabFloatFromText(value);
}

function grabFloatFromText(text) {
  return text.match(CONTENT_NUMBER_REGEX)[1];
}

export default FormulaEvaluator;
