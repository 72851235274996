// @ts-strict-ignore
import Field from 'src/form_filler/fields/field';

class SignatureField extends Field {
  getValue(): string[] | number | string {
    return this.$element.find('.name').val();
  }
}

export default SignatureField;
