// @ts-strict-ignore
import FileUploaderController from 'controllers/file_uploader_controller';
import { docValidExtensions } from 'src/constants';

const VALID_DOC_MESSAGE = 'Make sure you enter a document title ' +
  'and choose a file.  If there are multiple files, they must all ' +
  'be PDF';

class DocUploaderController extends FileUploaderController {
  connect(): void {
    super.connect();

    this.errorMsg = VALID_DOC_MESSAGE;
    this.validExtensions = docValidExtensions;
  }

  validateFiles(filenames: string[]): boolean {
    if (filenames.length <= 1) { return true; }

    return filenames.every((filename) => {
      return filename.toLowerCase().includes('.pdf');
    });
  }

  attachEventListeners($fileSelect: JQuery): void {
    $('.toggle-doc-chooser').on('click', toggleDocChooser);
    super.attachEventListeners($fileSelect);
  }
}

// private

function toggleDocChooser(event): void {
  const $thisForm = $(event.currentTarget).parents('form');
  const $otherForm = $('form').not($thisForm);

  $thisForm.css('display', 'none');
  $otherForm.css('display', 'block');
}

export default DocUploaderController;
