// @ts-strict-ignore
import Field from 'src/form_filler/fields/field';
import { findEl } from 'src/helpers/finders';
import { removeAllTooltips } from 'src/helpers/tooltip';
import valToString from 'src/helpers/val_to_string';

class DateField extends Field {
  $datePicker: JQuery;

  constructor(element: HTMLElement, data) {
    super(element, data);

    if (this.element.classList.contains('locked-field')) { return; }

    this.toggleRequiredBorder = this.toggleRequiredBorder.bind(this);

    if (data.mode === 'user_input') {
      this.setupDatePicker();
    }

    this.render();
  }

  get datePicker(): HTMLElement {
    return findEl(this.element, 'input', '[type="date"]');
  }

  get $timestamp(): JQuery {
    return this.$element.find('.auto-current-timestamp');
  }

  setupDatePicker(): void {
    this.$datePicker = $(this.datePicker);

    this.toggleRequiredBorder();

    this.$datePicker
      .on('change', this.toggleRequiredBorder)
      .on('blur', this.storeValue);
  }

  getValue(): string | undefined {
    if (this.data.mode === 'user_input') {
      return valToString(this.$datePicker.val());
    }
    return undefined;
  }

  toggleRequiredBorder(): void {
    const value = this.getValue();

    this.$datePicker
      .toggleClass('empty', !value)
      .toggleClass('required-danger', !value && this.isRequired());

    if (value) { removeAllTooltips(); }
  }

  render(): void {
    if (this.$element.hasClass('completed')) { return; }

    if (this.data.mode === 'auto_current') {
      setAutoCurrentTimestamp(this.$timestamp);
    }
  }
}

// private

function setAutoCurrentTimestamp($timestamp: JQuery): void {
  $timestamp.text(new Date().toLocaleDateString('en-US')); // MM/DD/YYYY
  setTimeout(() => { setAutoCurrentTimestamp($timestamp); }, 10000);
}

export default DateField;
