import intersection from 'lodash/intersection';
import isEqual from 'lodash/isEqual';
import keys from 'lodash/keys';
import omit from 'lodash/omit';

const UPDATEABLE_ATTRS = [ // this list mirrors app/models/field.rb
  'required',
  'label',
  'link',
  'allowEdits',
  'editableBySpecialApprover',
  'format',
  'interval',
];

type NewVersionCheckerOptions = {
  oldFieldsData: FieldsByNumber;
  newFieldsData: FieldsByNumber;
};

const NewVersionChecker = {
  run(options: NewVersionCheckerOptions): boolean {
    if (hasMismatchingFields(options)) { return true; }
    if (criticalAttributesChanged(options)) { return true; }

    return false;
  },
};

// private

function hasMismatchingFields(options: NewVersionCheckerOptions): boolean {
  const oldNumbers = keys(options.oldFieldsData);
  const newNumbers = keys(options.newFieldsData);

  const sameFieldsCount = oldNumbers.length === newNumbers.length;
  const intersectingNumbers = intersection(oldNumbers, newNumbers);

  return !sameFieldsCount || intersectingNumbers.length !== oldNumbers.length;
}

function criticalAttributesChanged(options: NewVersionCheckerOptions): boolean {
  const jsAttrs = ['selected'];
  const allUpdateableAttrs = jsAttrs.concat(UPDATEABLE_ATTRS);

  return Object.values(options.newFieldsData).some((fieldData) => {
    const oldFieldData = options.oldFieldsData[fieldData.number];
    const newVersionedData = omit(fieldData, allUpdateableAttrs);
    const oldVersionedData = omit(oldFieldData, allUpdateableAttrs);

    return !isEqual(newVersionedData, oldVersionedData);
  });
}

export type { NewVersionCheckerOptions };
export default NewVersionChecker;
