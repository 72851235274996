// @ts-strict-ignore
import React, { ReactNode } from 'react';

import { BoundUpdateFields } from 'src/chux/editable_fields/store';
import PRECISION_OPTIONS from 'src/fields/config/number_precision';

type Props = {
  number: number;
  updateFields: BoundUpdateFields;
  precision?: number | null;
  showNoRounding?: boolean;
};

class NumberPrecisionDropdown extends React.Component<Props, never> {
  constructor(props: Props) {
    super(props);
    this.updatePrecision = this.updatePrecision.bind(this);
  }

  render(): ReactNode {
    const { precision, showNoRounding } = this.props;

    const defaultSelected = showNoRounding ? '' : PRECISION_OPTIONS[0].precision;
    const selected = Number.isInteger(precision) ? precision : defaultSelected;

    return (
      <div>
        <label>
          Decimal rounding:
          <select
            name='precision'
            value={selected}
            onChange={this.updatePrecision}
          >
            {showNoRounding && <option value=''>No rounding</option>}
            {renderOptions()}
          </select>
        </label>
      </div>
    );
  }

  updatePrecision(event: React.ChangeEvent<HTMLSelectElement>): void {
    const { updateFields, number } = this.props;
    const targetValue = Number.parseInt(event.target.value, 10);
    const precision = Number.isInteger(targetValue) ? targetValue : null;

    updateFields({ number, precision });
  }
}

// private

function renderOptions(): React.JSX.Element[] {
  return PRECISION_OPTIONS.map((option) => {
    const { label, precision } = option;

    return <option key={precision} value={precision}>{label}</option>;
  });
}

export default NumberPrecisionDropdown;
export type { Props };
