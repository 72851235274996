// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';
import A11yDialog from 'a11y-dialog';

import { findEl } from 'src/helpers/finders';
import flash from 'src/helpers/flash';

type AjaxResult = {
  successMessage?: string;
  errorMessage?: string;
};

class RemoteFormDialogController extends Controller {
  dialog: A11yDialog;

  connect(): void {
    const $element = $(this.element);
    const modal = findEl(
      document.body,
      'div',
      '[data-a11y-dialog="form-filler-link-modal"]',
    );

    this.dialog = new A11yDialog(modal);

    $element.on('ajax:error', () => {
      flash('error', 'Something went wrong, refresh and try again');
    });

    $element.on('ajax:success', (event) => {
      const results: AjaxResult = event.detail[0].data;

      if (results.successMessage) {
        flash('success', results.successMessage);
      }
      if (results.errorMessage) {
        flash('error', results.errorMessage);
      }
    });

    $element.on('ajax:complete', () => {
      this.dialog.hide();
      $element.trigger('reset');
    });
  }
}

export default RemoteFormDialogController;
