// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'formCreatorCheckbox',
    'formPublisherCheckbox',
    'formPublisherLabel',
  ];

  formCreatorCheckboxTarget: HTMLInputElement;
  formPublisherCheckboxTarget: HTMLInputElement;
  formPublisherLabelTarget: HTMLElement;

  connect(): void {
    if (!this.formCreatorCheckboxTarget.checked) {
      this.disablePublisherCheckbox();
    }
  }

  toggleFormCreatorCheckbox(): void {
    if (this.formCreatorCheckboxTarget.checked) {
      this.enablePublisherCheckbox();
    } else {
      this.disablePublisherCheckbox();
    }
  }

  disablePublisherCheckbox(): void {
    this.formPublisherCheckboxTarget.checked = false;
    this.formPublisherCheckboxTarget.disabled = true;
    this.formPublisherCheckboxTarget.classList.add('wb-checkbox--disabled');
    this.formPublisherLabelTarget.classList.add('wb-checkbox--disabled');
  }

  enablePublisherCheckbox(): void {
    this.formPublisherCheckboxTarget.disabled = false;
    this.formPublisherCheckboxTarget.classList.remove('wb-checkbox--disabled');
    this.formPublisherLabelTarget.classList.remove('wb-checkbox--disabled');
  }
}
