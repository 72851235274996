// @ts-strict-ignore
import React, { ReactNode } from 'react';

import PageMessage from 'src/common/components/page_message';
import PaginatorComponent from 'src/common/components/paginator';
import {
  FEEDBACK_SELECTIONS, FeedbackSelection,
} from 'src/feedbacks/config/feedback_selections';
import { pluralize } from 'src/helpers/pluralize';
import iconClasses from 'src/helpers/icon';

type Counts = {
  [key in FeedbackSelection]: number;
};

type Props = {
  feedbacks: Feedback[];
  queryParams: FeedbacksQueryParams;
  counts: Counts;
  paginationTotals: PaginationTotals;
};

class FeedbacksIndex extends React.Component<Props, never> {
  render(): ReactNode {
    if (!this.props.feedbacks) {
      return (
        <PageMessage
          icon={`${iconClasses('heart')} fa-4x`}
          subtitle='When people leave feedback for this form, it will show up here.'
          title="You currently don't have feedback."
        />
      );
    }

    const { queryParams, paginationTotals } = this.props;
    const { page, pageSize } = queryParams;
    const pagination: Pagination =
      { page, pageSize, ...paginationTotals };

    return (
      <div>
        <h1 className='sr-only'>Feedback</h1>
        <div className='feedbacks-container'>
          <h2 className='feedbacks-section-title wb-heading-3'>
            What people think about your e-form
          </h2>
          <div className='row'>
            {this.feedbackCountsByReason()}
          </div>
          <hr className='feedbacks-hr' />
          <h2 className='feedbacks-section-title wb-heading-3'>
            People who gave feedback
          </h2>
          <div className='row'>
            <div className='col-md-6'>
              {this.feedbackGivers()}
            </div>
          </div>
          <hr className='feedbacks-hr' />
        </div>
        <PaginatorComponent
          ariaLabel='Feedback Pagination'
          pagination={pagination}
        />
      </div>
    );
  }

  feedbackCountsByReason(): React.JSX.Element[] {
    return Object.keys(FEEDBACK_SELECTIONS).map((key) => {
      const selection = FEEDBACK_SELECTIONS[key];
      const count: number = this.props.counts[key];

      return (
        <div key={key} className='col-md-3 col-sm-6'>
          <div className='media feedbacks-media'>
            <div className='media-left'>
              <img
                alt=''
                className='media-object feedbacks-image'
                src={selection.image}
              />
            </div>
            <div className='media-body media-middle'>
              <div className='text-sm'>{selection.text}</div>
              <div className='text-lg'>
                {`${count} ${pluralize(count, 'person')}`}
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  feedbackGivers(): React.JSX.Element[] {
    return Object.values(this.props.feedbacks).map((feedback: Feedback) => {
      const { recipientEmail, id } = feedback;
      let { recipientName } = feedback;

      if (!recipientName && !recipientEmail) { recipientName = 'Anonymous'; }

      return (
        <div key={id} className='feedbacks-entry'>
          {recipientName && <div><strong>{recipientName}</strong></div>}
          {recipientEmail}
        </div>
      );
    });
  }
}

export default FeedbacksIndex;
