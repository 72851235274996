// @ts-strict-ignore
import { fontRatio } from 'src/constants';

const SIZE_BUFFER = 8; // to account for cross browser field rendering diffs
const MIN_WIDTH = 30;

function fieldWidth(fontSize, content?): number {
  if (typeof content !== 'string') { return MIN_WIDTH; }

  const width = Math.round((fontSize / fontRatio * content.length) + SIZE_BUFFER);

  return Math.max(width, MIN_WIDTH);
}

function fieldHeight(fontSize): number {
  return Math.round(fontSize * fontRatio);
}

export { fieldWidth, fieldHeight };
