// @ts-strict-ignore
import FieldModel from 'src/doc_editor/fields/models/field';

const DEFAULTS = {
  allowedInNumberFormula: true,
  format: 'number',
  mode: 'number',
  precision: 2,
};

class Formula extends FieldModel {
  constructor(opts) {
    super({ ...DEFAULTS, ...opts });
  }
}

export default Formula;
