import createBasicReducer from 'src/common/basic_reducer';

const operations = {
  '@@INIT': initStore,
  'campaign/SET': setCampaign,
};

function initStore() {
  return {};
}

function setCampaign(_previousState: State, campaign: Campaign): Campaign {
  return campaign;
}

export default createBasicReducer(operations);
