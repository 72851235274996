import classNames from 'classnames';
import React, { ReactNode } from 'react';

type Props = {
  number: number;
};

class FieldNumber extends React.Component<Props, never> {
  render(): ReactNode {
    const { number } = this.props;

    return (
      <div
        className={classNames('badge field-number-badge')}
      >
        F{number}
      </div>
    );
  }
}

export default FieldNumber;
