// @ts-strict-ignore
import Field from 'src/form_filler/fields/field';
import { formatNumber, isValidNumber } from 'src/helpers/number';

class FormulaField extends Field {
  constructor(element: HTMLElement, data) {
    super(element, data);

    this.handleChange = this.handleChange.bind(this);
  }

  get mode(): string { return this.data.mode; }

  getValue(): string {
    return this.$element.find('.display-text').text();
  }

  handleChange(value): void {
    const data = { ...this.data, value };

    this.markFieldIfInvalid(data);
    this.updateValue(data);
    this.storeValue();
  }

  markFieldIfInvalid(data): void {
    this.$element.toggleClass('invalid', !isValid(data));
  }

  updateValue(data): void {
    const fieldValue = isValid(data) ? roundAndPad(data) : 'Invalid';

    this.$element.find('.field-value').val(fieldValue);
    this.$element.find('.display-text').text(fieldValue);
  }
}

// private

function isValid(fieldData): boolean {
  const { value } = fieldData;

  const isEmpty = ['', undefined, null].includes(value);

  return isEmpty || (isValidNumber(value) && isFinite(value));
}

function roundAndPad(fieldData): string {
  const { value, precision } = fieldData;

  if (value === undefined || value === null) { return ''; }

  return formatNumber(value, { precision });
}

export default FormulaField;
