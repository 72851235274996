import React, { ReactNode } from 'react';

import Icon from 'src/common/components/icon';
import { pluralizeWithCount } from 'src/helpers/pluralize';

type Props = {
  count: number;
};

class AttachmentsIcon extends React.Component<Props, never> {
  private popoverRef = React.createRef<HTMLButtonElement>();

  componentDidMount(): void {
    const popoverElement = this.popoverRef.current;

    if (popoverElement) { $(popoverElement).popover(); }
  }

  render(): ReactNode {
    const { count } = this.props;

    const popoverDataAttributes = {
      'data-content': `${pluralizeWithCount(count, 'attachment')} on this form `,
      'data-html': true,
      'data-placement': 'left',
      'data-toggle': 'popover',
      'data-trigger': 'hover | focus',
    };

    return (
      <button
        ref={this.popoverRef}
        className='link-text'
        type='button'
        {...popoverDataAttributes}
      >
        <span className='sr-only'>View Attachments Count</span>
        <Icon name='paperclip' />
      </button>
    );
  }
}

export default AttachmentsIcon;
export type { Props };
