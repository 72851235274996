function timesMap<T>(n: number, iteratee: Function): T[] {
  const result: T[] = [];

  for (let index = 0; index < n; index++) {
    result[index] = iteratee(index);
  }
  return result;
}

export default timesMap;
