// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';
import bindAll from 'lodash/bindAll';

import { fetchPut } from 'src/helpers/fetch';
import { findEl } from 'src/helpers/finders';
import { hide, show } from 'src/helpers/visibility';

class FormNotesController extends Controller<HTMLElement> {
  connect(): void {
    bindAll(
      this,
      'saveNotes',
      'deleteNotes',
      'updateNotes',
      'showEditNotes',
      'showViewNotes',
    );

    this.showViewNotes();

    findEl(this.element, 'p', '#display-notes')
      .addEventListener('click', this.showEditNotes);
    findEl(this.element, 'div', '#hide-notes')
      .addEventListener('click', this.showViewNotes);
    findEl(this.element, 'div', '#delete-notes')
      .addEventListener('click', this.deleteNotes);
    findEl(this.element, 'div', '#save-notes')
      .addEventListener('click', this.saveNotes);
  }

  showEditNotes(): void {
    hide(findEl(this.element, 'p', '#display-notes'));
    show(findEl(this.element, 'div', '#edit-notes'));
  }

  showViewNotes(): void {
    show(findEl(this.element, 'p', '#display-notes'));
    hide(findEl(this.element, 'div', '#edit-notes'));
  }

  saveNotes(): void {
    const notes = findEl(this.element, 'textarea', '#edit-notes-text').value;

    this.updateNotes(notes);
  }

  deleteNotes(): void {
    this.updateNotes(null);
  }

  updateNotes(notes: string): void {
    const url = `/forms/${this.element.dataset.formId}`;

    fetchPut(url, { form: { notes } });

    findEl(this.element, 'span', '#notes-text').textContent = notes || 'Add a note';
    findEl(this.element, 'textarea', '#edit-notes-text').value = notes;

    this.showViewNotes();
  }
}

export default FormNotesController;
