import A11yDialog from 'a11y-dialog';

import { fetchPost } from 'src/helpers/fetch';
import { findEl } from 'src/helpers/finders';
import flash from 'src/helpers/flash';
import notify from 'src/helpers/notify';

class ReminderModal {
  $element: JQuery;
  element: HTMLElement;
  dialog: A11yDialog;

  constructor(element: HTMLElement) {
    this.$element = $(element);
    this.element = element;
    this.submitReminder = this.submitReminder.bind(this);
    this.displaySuccessMsg = this.displaySuccessMsg.bind(this);

    this.$element.find('#reminder-submit').on('click', this.submitReminder);
    this.dialog = new A11yDialog(this.element);
  }

  submitReminder(): void {
    const emailSubject = this.$element.find('#email_subject').val();
    const emailBody = this.$element.find('#email_body').val();
    const allowReply = findEl(this.element, 'input', '#allow_replies').checked;

    const id = this.$element.data('form-request-id');

    const reminderParams = { allowReply, emailBody, emailSubject, id };

    const url = this.$element.data('post-path');

    fetchPost(url, reminderParams).then(this.displaySuccessMsg, notify);
  }

  displaySuccessMsg(): void {
    this.dialog.hide();
    flash('success', 'You have successfully sent the reminder.');
  }
}

export default ReminderModal;
