// @ts-strict-ignore
import { fetchGet } from 'src/helpers/fetch';
import notify from 'src/helpers/notify';

function setCampaign(campaign: Partial<Campaign>) {
  return { payload: campaign, type: 'campaign/SET' };
}

function fetchCampaign(campaignId: number) {
  const url = `/api/campaigns/${campaignId}`;

  return (dispatch) => {
    return fetchGet(url).then(({ data: campaign }: { data: Campaign }) => {
      dispatch(setCampaign(campaign));
    }, notify);
  };
}

export { setCampaign, fetchCampaign };
