import React, { ReactNode } from 'react';

import { BoundUpdateFields } from 'src/chux/editable_fields/store';
import assertInclusion from 'src/helpers/assert_inclusion';

type UpdateParams = {
  dataSourceColumn?: null;
  number: number;
  mode: DateFieldMode;
  required?: boolean;
  updateFields?: BoundUpdateFields;
  editableBySpecialApprover?: boolean;
};

type Props = {
  mode: DateFieldMode;
  number: number;
  updateFields: BoundUpdateFields;
};

const MODE_VALUES: DateFieldMode[] = ['auto_current', 'user_input'];

const DATE_MODES = [
  { description: 'Automatically when submitted', name: 'auto_current' },
  { description: 'Selected from a calendar', name: 'user_input' },
];

class DateContextMenu extends React.Component<Props, never> {
  constructor(props: Props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  render(): ReactNode {
    return (
      <div>
        <label htmlFor='mode'>How is the date added?</label>
        <select name='mode' value={this.props.mode} onChange={this.handleChange}>
          {dateModeOptions()}
        </select>
      </div>
    );
  }

  handleChange(event: React.ChangeEvent<HTMLSelectElement>): void {
    const { number, updateFields } = this.props;
    const mode = assertInclusion(MODE_VALUES, event.target.value);

    const updateParams: UpdateParams = { mode, number };

    if (mode === 'auto_current') {
      updateParams.required = false;
      updateParams.dataSourceColumn = null;
      updateParams.editableBySpecialApprover = false;
    }

    updateFields(updateParams);
  }
}

// private

function dateModeOptions(): React.JSX.Element[] {
  return DATE_MODES.map((mode) => {
    const { name, description } = mode;

    return <option key={name} value={name}>{description}</option>;
  });
}

export type { Props, UpdateParams };
export default DateContextMenu;
