// @ts-strict-ignore
import A11yDialog from 'a11y-dialog';

class DocEditorValidator {
  resolve: (value: null) => void;
  $validatorModal: JQuery;
  dialog: A11yDialog;

  constructor() {
    this.$validatorModal = $('#validator-modal');
    this.dialog = new A11yDialog(this.$validatorModal[0]);

    this.$validatorModal.find('.wb-button--primary').on('click', () => {
      this.resolve(null);
    });
  }

  validate(fieldDatas: Field[]): Promise<null> {
    const promise = new Promise<null>((resolve) => {
      this.resolve = resolve;
    });

    const requiredFieldsMissing = areRequiredFieldsMissing(fieldDatas);
    const signaturesMissing = areSignaturesMissing(fieldDatas);

    if (!requiredFieldsMissing && !signaturesMissing) {
      this.resolve(null);
      return promise;
    }

    const missingList = this.$validatorModal.find('.missing-fields');

    missingList.empty();

    if (requiredFieldsMissing) {
      missingList.append(
        '<li>None of the form fields are marked as required</li>',
      );
    }
    if (signaturesMissing) {
      missingList.append(
        '<li>The form contains no signature fields for initiators or ' +
        'approvers</li>',
      );
    }
    this.dialog.show();

    return promise;
  }
}

// private

function areSignaturesMissing(fieldDatas: Field[]): boolean {
  return !fieldDatas.some((fieldData) => {
    return fieldData.type === 'Signature';
  });
}

function areRequiredFieldsMissing(fieldDatas: Field[]): boolean {
  return !fieldDatas.some((fieldData) => {
    return fieldData.required;
  });
}

export default DocEditorValidator;
