import React, { ReactNode } from 'react';

import { BoundUpdateFields } from 'src/chux/editable_fields/store';

type Props = {
  number: number;
  referencePrefix: string;
  startingReferenceNumber: string;
  updateFields: BoundUpdateFields;
  editable: boolean;
  testing: boolean;
  errors: string[];
};

class ReferenceNumberContextMenu extends React.Component<Props, never> {
  constructor(props: Props) {
    super(props);
    this.updateStore = this.updateStore.bind(this);
  }

  render(): ReactNode {
    const {
      editable, referencePrefix, startingReferenceNumber, testing,
    } = this.props;

    return (
      <div className='text-center ref-num-adder'>
        {this.warningMsg()}
        <div>
          <label htmlFor={`reference_prefix_${referencePrefix}`}>Prefix:</label>
          <input
            className='form-control'
            disabled={!testing && !editable}
            id={`reference_prefix_${referencePrefix}`}
            name='referencePrefix'
            type='text'
            value={referencePrefix}
            onChange={this.updateStore}
          />
        </div>
        <div>
          <label
            htmlFor={`starting_reference_number_${referencePrefix}`}
          >Starting Number:
          </label>
          <input
            className='form-control'
            disabled={!testing && !editable}
            id={`starting_reference_number_${referencePrefix}`}
            name='startingReferenceNumber'
            type='text'
            value={startingReferenceNumber}
            onChange={this.updateStore}
          />
          {this.renderValidationError()}
        </div>
        <div className='ref-num-preview'>
          <span className='ref-num-preview-label'>
            Preview:
          </span>
          <span>
            {referencePrefix}
            {startingReferenceNumber.toString().padStart(7, '0')}
          </span>
        </div>
      </div>
    );
  }

  renderValidationError(): React.JSX.Element | boolean {
    const { errors } = this.props;

    if (errors === undefined || errors.length === 0) { return false; }

    return (
      <div className='alert-danger'>
        {errors[0]}
      </div>
    );
  }

  warningMsg(): React.JSX.Element | boolean {
    let message: string;

    if (this.props.testing) {
      return false;
    }
    if (this.props.editable) {
      message = 'WARNING: Once saved, this field cannot be edited.';
    } else {
      message = 'You cannot make edits because the document has already been saved';
    }

    return (
      <div className='ref-num-warning'>
        {message}
      </div>
    );
  }

  updateStore(event: React.ChangeEvent<HTMLInputElement>): void {
    const { testing, editable, number, updateFields } = this.props;

    if (!testing && !editable) { return; }

    updateFields({ [event.target.name]: event.target.value, number });
  }
}

export type { Props };
export default ReferenceNumberContextMenu;
