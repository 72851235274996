import classNames from 'classnames';
import React, { ReactNode } from 'react';

type Props = {
  allowedInNumberFormula: boolean;
  type: string;
  activeFormulaMode: string;
  activeFormulaNumber: number;
  indexedHighlightedFields: FieldsByNumber;
  number: number;
  showMedallions: boolean;
};

class Medallion extends React.Component<Props, never> {
  render(): ReactNode {
    const {
      activeFormulaMode,
      activeFormulaNumber,
      allowedInNumberFormula,
      indexedHighlightedFields,
      number,
      showMedallions,
      type,
    } = this.props;

    const shouldRender = shouldRenderMedallion(
      activeFormulaMode,
      activeFormulaNumber,
      allowedInNumberFormula,
      number,
      showMedallions,
      type,
    );

    if (!shouldRender) { return null; }

    const highlight = indexedHighlightedFields.hasOwnProperty(number);

    return (
      <div className={classNames('field-medallion', { highlight })}>
        F{number}
      </div>
    );
  }
}

// private

function shouldRenderMedallion(
  activeFormulaMode: string,
  activeFormulaNumber: number,
  allowedInNumberFormula: boolean,
  number: number,
  showMedallions: boolean,
  type: string,
): boolean {
  return showMedallions &&
    typeAllowedInFormula(type, activeFormulaMode, allowedInNumberFormula) &&
    number !== activeFormulaNumber &&
    number !== undefined;
}

function typeAllowedInFormula(
  type: string,
  activeFormulaMode: string,
  allowedInNumberFormula: boolean,
): boolean {
  if (activeFormulaMode === 'time') {
    return type === 'Time';
  }
  return allowedInNumberFormula;
}

export default Medallion;
export type { Props };
