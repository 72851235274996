import React, { ReactNode } from 'react';

import { BoundUpdateFields } from 'src/chux/editable_fields/store';

type Props = {
  number: number;
  required: boolean;
  updateFields: BoundUpdateFields;
};

class RequiredCheckbox extends React.Component<Props, never> {
  constructor(props: Props) {
    super(props);
    this.notifyOfChange = this.notifyOfChange.bind(this);
  }

  render(): ReactNode {
    return (
      <div className='require-span'>
        <label>
          <input
            checked={this.props.required}
            className='required_check'
            name='required_check'
            type='checkbox'
            onChange={this.notifyOfChange}
          />
          Required?
        </label>
      </div>
    );
  }

  notifyOfChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const { number, updateFields } = this.props;

    updateFields({ number, required: event.target.checked });
  }
}

export default RequiredCheckbox;
export type { Props };
