// @ts-strict-ignore
import { connect } from 'react-redux';

import StepsIndex from 'src/steps/components/index';
import { getSelectedStep } from 'src/steps/selectors';

function mapStateToProps(state: State) {
  return {
    campaign: state.campaign,
    selectedStep: getSelectedStep(state),
  };
}

export default connect(mapStateToProps)(StepsIndex);
