import type { TemplateExecutor } from 'lodash';
import _template from 'lodash/template';
import unescape from 'lodash/unescape';

const templates: { [key: string]: TemplateExecutor } = {};
const templateSettings = {
  escape: /\{\{-(.+?)\}\}/g,
  evaluate: /\{\{(.+?)\}\}/g,
  interpolate: /\{\{=(.+?)\}\}/g,
};

type Opts = { [key: string]: unknown };

function template(id: string, opts?: Opts): string {
  templates[id] ||= loadTemplate(id);

  return templates[id](opts);
}

function templateWithSettings(templateString: string) {
  return _template(templateString, templateSettings);
}

// private

function loadTemplate(id: string): TemplateExecutor {
  const templateElement = document.getElementById(id);

  if (templateElement === null) {
    throw new Error(`Template <${id}> does not exist`);
  }

  return templateWithSettings(unescape(templateElement.innerHTML));
}

export { templateWithSettings };
export default template;
