// @ts-strict-ignore
import React from 'react';

import Icon from 'src/common/components/icon';

type Props = {
  prefillable: boolean;
};

function PrefillBadge(props: Props): React.JSX.Element {
  if (!props.prefillable) { return null; }

  return (
    <div className='field-badge editable-badge'>
      <Icon name='rocket' />
    </div>
  );
}

export default PrefillBadge;
export type { Props };
