// @ts-strict-ignore
import { InferThunkActionCreatorType } from 'react-redux';

function addFlash(flash) {
  return { payload: flash, type: 'flashes/ADD' };
}

function removeFlash(id: number) {
  return { payload: id, type: 'flashes/REMOVE' };
}

export { addFlash, removeFlash };

export type RemoveFlash = InferThunkActionCreatorType<typeof removeFlash>;
