import easyImageUrl from 'assets/images/papel/easy.png';
import ecoFriendlyImageUrl from 'assets/images/papel/ecofriendly.png';
import efficientImageUrl from 'assets/images/papel/efficient.png';
import otherImageUrl from 'assets/images/papel/other.png';

enum FeedbackSelection {
  easy = 'easy',
  ecofriendly = 'ecofriendly',
  efficient = 'efficient',
  other = 'other',
}

export const FEEDBACK_SELECTIONS = {
  easy: {
    image: easyImageUrl,
    text: 'Easy to use',
  },
  ecofriendly: {
    image: ecoFriendlyImageUrl,
    text: 'Environmentally friendly',
  },
  efficient: {
    image: efficientImageUrl,
    text: 'Saves time',
  },
  other: {
    image: otherImageUrl,
    text: 'Other',
  },
};

export { FeedbackSelection };
