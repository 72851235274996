// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';

import { browserFormatDate } from 'src/helpers/date';
import { hide, show } from 'src/helpers/visibility';
import { disable, enable } from 'src/helpers/availability';

class DateRangeController extends Controller {
  static targets = ['startDate', 'endDate', 'heading', 'reset', 'filter'];

  static values = {
    filterKey: String,
    filterLabel: String,
    filterValue: Object,
    from: String,
    headerRange: String,
    to: String,
  };

  filterKeyValue!: string;
  filterLabelValue!: string;
  filterValueValue: object;
  fromValue: string;
  toValue: string;
  headerRangeValue: string;
  startDateTarget: HTMLInputElement;
  endDateTarget: HTMLInputElement;
  headingTarget: HTMLElement;
  resetTarget: HTMLElement;
  filterTarget: HTMLButtonElement;

  get headingContent(): string {
    const start = this.startDateTarget.value;
    const end = this.endDateTarget.value;

    return `${headerFormatDate(start)} - ${headerFormatDate(end)}`;
  }

  connect(): void {
    this.updateDisplay();
  }

  updateDisplay(): void {
    if (this.fromValue && this.toValue) {
      this.headingTarget.textContent = this.headingContent;
      show(this.resetTarget);
      enable(this.filterTarget);
    } else {
      this.headingTarget.textContent = 'Select a range';
      hide(this.resetTarget);
      disable(this.filterTarget);
    }
  }

  setFrom(): void {
    this.fromValue = this.startDateTarget.value;

    if (this.toValue < this.startDateTarget.value) {
      this.endDateTarget.value = this.startDateTarget.value;
      this.toValue = this.startDateTarget.value;
    }
    this.fromValue = this.startDateTarget.value;

    this.setHeader();

    this.setFilterValue();

    this.updateDisplay();
  }

  setTo(): void {
    this.toValue = this.endDateTarget.value;

    this.setHeader();

    this.setFilterValue();

    this.updateDisplay();
  }

  setHeader(): void {
    this.headerRangeValue = this.headingContent;
  }

  reset(event: Event): void {
    event.preventDefault();

    this.fromValue = '';
    this.startDateTarget.value = '';
    this.toValue = '';
    this.endDateTarget.value = '';
    this.filterValueValue = {};

    this.updateDisplay();
  }

  setFilterValue(): void {
    const fromDate = formatDateToLocalTime(this.fromValue);
    const toDate = formatDateToLocalTime(this.toValue);

    this.filterValueValue = {
      gte: browserFormatDate(fromDate),
      lte: browserFormatDate(toDate),
    };

    this.filterTarget
      .setAttribute(
        'data-responses-filter-value-param',
        JSON.stringify(this.filterValueValue),
      );
  }
}

function headerFormatDate(dateString: string): string {
  const date = formatDateToLocalTime(dateString);
  const monthName = date.toLocaleString('en-us', { month: 'short' });
  const day = date.getDate();

  return `${monthName} ${day}`;
}

function formatDateToLocalTime(dateString: string): Date {
  const [year, month, day] = dateString.split('-').map(Number);

  return new Date(year, month - 1, day);
}

export default DateRangeController;
