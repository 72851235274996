// @ts-strict-ignore
import Backbone from 'backbone';
import keyBy from 'lodash/keyBy';

import autoLabel from 'src/doc_editor/fields/helpers/auto_label';
import FieldModel from 'src/doc_editor/fields/models/field';
import FieldModels from 'src/doc_editor/fields/models/index';

type FetchFields = {
  docId: number;
  opts: {
    cache?: boolean | null;
    success?: Callback;
    url?: string;
  };
};

type CollectionType = {
  fetchFields: (payload: FetchFields) => void;
  getState: () => FieldsByNumber;
  get: (number: number) => FieldModel;
  set: (attrs: Partial<Field>[]) => void;
  models: FieldModel[];
  add: (attrs: Partial<Field>[]) => FieldModel[];
  modelAttrs: () => Field[];
  reset: () => void;
  fetch: () => void;
  subscribe: (listener: () => void) => void;
};

const COLLECTION_EVENTS = 'change add remove reset';

const EditableFieldsCollection: CollectionType = new (Backbone.Collection.extend({
  cache: null,
  fetchFields({ opts, docId }: FetchFields) {
    opts.cache = false;
    opts.url = `/docs/${docId}/fields`;

    this.fetch(opts);
  },

  getState(): FieldsByNumber {
    this.cache ||= keyBy(this.modelAttrs(), 'number');

    return this.cache;
  },

  initialize() {
    this.on(COLLECTION_EVENTS, () => {
      this.cache = null;
      this.listeners.forEach((listener) => { listener(); });
    });
  },

  listeners: [],

  model(attrs: Partial<Field>): FieldModel {
    return new FieldModels[attrs.type]({ ...attrs });
  },

  modelAttrs() {
    return this.map((model: FieldModel) => {
      const viewAttrs = model.toJSON();

      return {
        ...viewAttrs,
        isValid: model.isValid(),
        label: viewAttrs.label || autoLabel(model),
      };
    });
  },

  modelId(attrs: Partial<Field>) { return attrs.number; },

  parse({ data }) { return data; },

  subscribe(listener) {
    this.listeners.push(listener);
  },

}))();

export default EditableFieldsCollection;
export type { CollectionType };
