import classNames from 'classnames';
import React from 'react';

type Props = {
  required: boolean | undefined;
  selected: boolean | undefined;
};

function Signature(props: Props): React.JSX.Element {
  return (
    <button
      className={
        classNames({
          'add-sig signature-btn btn btn-default field-container': true,
          required: props.required,
          selected: props.selected,
        })
      }
      type='button'
    >
      Request Signature
    </button>
  );
}

export default Signature;
