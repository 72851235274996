// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';

import { findEl } from 'src/helpers/finders';
import Location from 'src/helpers/location';
import createReport from 'src/report/create_report';
import iconClasses from 'src/helpers/icon';

class FullFormSetDownloadController extends Controller<HTMLElement> {
  $element: JQuery;
  $downloadStatus: JQuery;
  $downloadButton: JQuery;
  campaignId: number;
  fileType: ReportType;
  status: FormSetStatus;
  reportTime: string | null;
  fileUrl: string | null;

  get downloadStatus(): HTMLElement {
    return findEl(this.element, 'div', '.download-status');
  }

  get downloadButton(): HTMLElement {
    return findEl(this.element, 'button', '.download-button');
  }

  connect(): void {
    this.$element = $(this.element);

    this.$downloadStatus = $(this.downloadStatus);
    this.$downloadButton = $(this.downloadButton);

    this.campaignId = this.$element.data('campaign-id');
    this.fileType = this.$element.data('file-type');
    this.status = this.$element.data('status');
    this.reportTime = this.$element.data('report-time') || null;
    this.fileUrl = this.$element.data('file-url') || null;

    this.setStatus(this.status, this.fileUrl, this.reportTime);

    this.$downloadButton.on('click', (event) => {
      this.setProcessing(true);
      this.setStatus('processing', null, new Date().toLocaleString());
      this.prepareDownload(this.campaignId, this.fileType);
      event.preventDefault();
    });
  }

  setProcessing(processing: boolean): void {
    this.$downloadButton.prop('disabled', processing);
    const loader = `<i class="${iconClasses('circleNotch')} fa-spin fa-fw"></i>`;
    const buttonText = 'Download';

    this.$downloadButton.html(processing ? loader : buttonText);
  }

  setStatus(
    status: FormSetStatus,
    fileUrl: string | null,
    reportTime: string | null,
  ): void {
    this.$downloadStatus.html(formatStatus(status, fileUrl, reportTime));
  }

  prepareDownload(campaignId: number, fileType: ReportType): void {
    const formSetParams: FormSetParams = { campaignId, formSetType: 'full' };
    const reportParams = { fileType, report: { includeAttachments: false } };

    const reportCreationEvents = {
      onCompleted: (updatedFormSet: FormSet): void => {
        const { status, fileDownloadUrl } = updatedFormSet;

        this.setStatus(status, fileDownloadUrl, new Date().toLocaleString());
        this.setProcessing(false);
        Location.downloadFileAt(fileDownloadUrl);
      },
    };

    createReport(formSetParams, reportParams, reportCreationEvents);
  }
}

// private

function formatStatus(
  status: FormSetStatus,
  fileUrl: string | null,
  reportTime: string | null,
): string {
  let statusHtml = '';

  if (status === 'processing') {
    statusHtml = `In progress (${reportTime})`;
  } else if (status === 'completed') {
    statusHtml =
      `<a href='${fileUrl}'>Download previous (${reportTime})</a>`;
  }

  return statusHtml;
}

export default FullFormSetDownloadController;
