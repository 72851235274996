import { Controller } from '@hotwired/stimulus';

class TrackedLinkFormController extends Controller<HTMLElement> {
  connect(): void {
    const $element = $(this.element);
    const $confirmSubject = $element.find('.tracked-link-confirm-subject');
    const $recipientForm = $element.find('.tracked-link-recipient-form');

    $confirmSubject.find('.wb-button--primary').on('click', () => {
      $confirmSubject.hide();
      $recipientForm.show();
    });
  }
}

export default TrackedLinkFormController;
