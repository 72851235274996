import React, { ReactNode } from 'react';

type Props = {
  isChecked: boolean;
  label: string;
  onChange: (allowEdits: boolean) => void;
};

class Checkbox extends React.Component<Props, never> {
  constructor(props: Props) {
    super(props);
    this.notifyOfChange = this.notifyOfChange.bind(this);
  }

  render(): ReactNode {
    const { isChecked, label } = this.props;

    return (
      <label className='labeled-checkbox'>
        <input
          defaultChecked={isChecked}
          type='checkbox'
          onChange={this.notifyOfChange}
        />
        {label}
      </label>
    );
  }

  notifyOfChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const { onChange } = this.props;

    onChange(event.target.checked);
  }
}

export default Checkbox;
export type { Props };
