// @ts-strict-ignore
import math from 'src/config/math';
import { parseTime24Hour } from 'src/helpers/time';

function calculateTimeFormula(
  formulaString: string,
  variableValueMap,
): math.BigNumber {
  const variableHourMap = {};

  Object.keys(variableValueMap).forEach((variable) => {
    const parsedTime = parseTime24Hour(variableValueMap[variable]);

    if ('error' in parsedTime) {
      variableHourMap[variable] = NaN;
    } else {
      variableHourMap[variable] = parsedTime.hours + (parsedTime.minutes / 60);
    }
  });

  const result = math.evaluate(formulaString, variableHourMap);

  return math.bignumber(result < 0 ? result + 24 : result);
}

export default calculateTimeFormula;
