import { Controller } from '@hotwired/stimulus';

import Location from 'src/helpers/location';

class ConfirmPageLeaveController extends Controller {
  connect(): void {
    Location.addBeforeUnload('confirm-page-leave');
    document.addEventListener('submit', this.removeBeforeUnload);
    document.addEventListener('turbo:submit-start', this.removeBeforeUnload);
  }

  removeBeforeUnload(): void {
    Location.removeBeforeUnload('confirm-page-leave');
  }
}

export default ConfirmPageLeaveController;
