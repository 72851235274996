import { assert } from 'src/helpers/assertion';
import FieldComponent from 'src/doc_editor/field';
import { BeginPlacingField } from 'src/doc_editor/doc';

enum events {
  DRAG_REORDER_LIST_CHANGED = 'DRAG_REORDER_LIST_CHANGED',
  FIELD_CHANGED = 'FIELD_CHANGED',
  FIELD_DRAG_STARTED = 'FIELD_DRAG_STARTED',
  FIELD_DRAG_REVERTED = 'FIELD_DRAG_REVERTED',
  FIELD_MOVED = 'FIELD_MOVED',
  FIELD_ADDED = 'FIELD_ADDED',
  FIELD_REMOVED = 'FIELD_REMOVED',
  GROUP_SELECTION_CHANGED = 'GROUP_SELECTION_CHANGED',
  PAGE_MOUSEDOWN = 'PAGE_MOUSEDOWN',
  PAGE_MOUSEUP = 'PAGE_MOUSEUP',
  STEP_ASSIGNMENT_CHANGED = 'STEP_ASSIGNMENT_CHANGED',
  SIMPLE_REVIEWER_STEP_SELECTED = 'SIMPLE_REVIEWER_STEP_SELECTED',
}

type EventMap = {
  DRAG_REORDER_LIST_CHANGED: never;
  FIELD_CHANGED: never;
  FIELD_DRAG_STARTED: FieldComponent;
  FIELD_DRAG_REVERTED: FieldComponent;
  FIELD_MOVED: FieldComponent;
  FIELD_ADDED: FieldComponent;
  FIELD_REMOVED: FieldComponent;
  GROUP_SELECTION_CHANGED: GroupData;
  PAGE_MOUSEDOWN: BeginPlacingField;
  PAGE_MOUSEUP: never;
  STEP_ASSIGNMENT_CHANGED: never;
  SIMPLE_REVIEWER_STEP_SELECTED: string;
};

type EventCallback = (payload?: any) => void;
const callbacks: { [key: string]: EventCallback[] } = {};

resetCallbacks();

function subscribe<Name extends keyof EventMap>(
  event: Name,
  callback: (payload: EventMap[Name],
  ) => void,
): void {
  eventCallbacks(event).push(callback);
}

function publish<Name extends keyof EventMap>(
  event: Name,
  payload?: EventMap[Name],
): void {
  eventCallbacks(event).forEach((callback) => { callback(payload); });
}

function resetCallbacks(): void {
  Object.keys(events).forEach((event) => { callbacks[event] = []; });
}

// private

function eventCallbacks<Name extends keyof EventMap>(event: Name): Function[] {
  return assert(callbacks[event]);
}

export { subscribe, publish, events, resetCallbacks };
