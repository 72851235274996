import React from 'react';

type Props = {
  id: string;
  options: string[];
  selectClassName: string;
  selectedOption: string;
};

function SelectTag(props: Props): React.JSX.Element {
  const { selectClassName, options, id, selectedOption } = props;

  return (
    <select className={selectClassName} defaultValue={selectedOption} id={id}>
      <option>Select</option>
      {renderOptions(options)}
    </select>
  );
}

// private

function renderOptions(options: string[]): React.JSX.Element[] {
  return options.map((option) => {
    return <option key={option}>{option}</option>;
  });
}

export default SelectTag;
export type { Props };
