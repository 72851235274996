import notify from 'src/helpers/notify';

function valToString(val: string[] | number | string): string {
  if (typeof val === 'string') { return val; }

  notify(`Invalid val: ${JSON.stringify(val)}, type: ${typeof val}`);
  return val ? val.toString() : '';
}

export default valToString;
