type SSNParseError = { error: string };
type SSNParseResult = { ssn: string };

function parseSsn(
  input: string,
): SSNParseError | SSNParseResult {
  const cleanedSsn = input.replace(/\D/g, '');

  if (cleanedSsn.length !== 9) {
    return {
      error: 'Please enter a nine digit SSN, e.g. 222-33-4444.',
    };
  }

  const formattedSsn =
    cleanedSsn.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');

  return { ssn: formattedSsn };
}

export default parseSsn;
