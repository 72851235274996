// @ts-strict-ignore
interface Field {
  getBorderEl?(): JQuery;
  toggleRequiredBorder?(): void;
}

abstract class Field {
  $element: JQuery;
  element: HTMLElement;
  data: any;
  value: any;
  onChange: Callback;

  constructor(element: HTMLElement, data) {
    this.$element = $(element);
    this.element = element;
    this.storeValue = this.storeValue.bind(this);
    this.data = data;
    this.value = data.value;
  }

  get number(): number { return this.data.number; }

  get fieldContent(): string { return this.data.fieldContent; }

  get type(): string { return this.data.type; }

  isRequired(): boolean {
    return this.data.required;
  }

  hasValue(): boolean {
    return Boolean(this.getValue());
  }

  validateRequired(): boolean {
    return this.isRequired() && !this.hasValue();
  }

  storeValue(): void {
    if (this.value !== this.getValue()) {
      this.value = this.getValue();

      if (this.onChange) {
        this.onChange();
      }
    }
  }

  abstract getValue(): any;
}

export default Field;
