// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';

class IncludeArchivedToggleController extends Controller {
  toggle(event: HTMLInputEvent): void {
    const includeArchived = event.target.checked;
    const url = new URL(window.location.href);

    url.searchParams.set('include_archived', includeArchived.toString());
    Turbo.visit(url.toString());
  }
}

export default IncludeArchivedToggleController;
