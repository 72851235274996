import { Controller } from '@hotwired/stimulus';

class UrlInputController extends Controller {
  connect(): void {
    const $element = $(this.element);

    $element.on('click', () => { $element.trigger('select'); });

    if ($element.data('select-on-init')) { $element.trigger('select'); }
  }
}

export default UrlInputController;
