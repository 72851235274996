// @ts-strict-ignore
import Field from 'src/form_filler/fields/field';
import { setContext } from 'src/helpers/notify';
import { removeAllTooltips } from 'src/helpers/tooltip';
import valToString from 'src/helpers/val_to_string';

type DropdownChange = {
  fieldNumber: number;
  value: string;
};

const dropdownChanges: DropdownChange[] = [];

class DropdownField extends Field {
  constructor(element: HTMLElement, data) {
    super(element, data);

    this.toggleRequiredBorder();
    this.$element.on('change', () => {
      dropdownChanges.push({
        fieldNumber: this.data.number,
        value: this.getValue(),
      });
      setContext({ dropdownChanges });
      this.storeValue();
      this.toggleRequiredBorder();
    });
  }

  getValue(): string {
    return valToString(this.$element.val());
  }

  getBorderEl(): JQuery {
    return this.$element;
  }

  toggleRequiredBorder(): void {
    if (!this.isRequired()) { return; }

    const value = this.getValue();

    this.getBorderEl().toggleClass('empty', !value)
      .toggleClass('required-danger', !value);

    if (value) { removeAllTooltips(); }
  }
}

export default DropdownField;
