// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';

import { assert } from 'src/helpers/assertion';
import { onClick } from 'src/helpers/event';
import { findEl } from 'src/helpers/finders';
import valToString from 'src/helpers/val_to_string';

class NewCampaignController extends Controller {
  connect(): void {
    let docId = valToString(this.$docId.val());
    let groupId = valToString(this.$groupId.val());
    let linked = false;

    if (this.$docSelector.find('option:selected').val()) {
      docId = 'grapes';
    }

    this.$groupSelection.hide();
    this.$emailConfirmMessage.hide();
    this.$createCampaign.hide();
    this.validateSelection({ docId, groupId, linked });

    onClick(this.emailRadio, () => {
      this.$groupSelection.show();
      this.$emailConfirmMessage.show();
      linked = false;
      this.validateSelection({ docId, groupId, linked });
    });

    onClick(this.linkRadio, () => {
      this.$groupSelection.hide();
      this.$emailConfirmMessage.hide();
      linked = true;
      this.validateSelection({ docId, groupId, linked });
    });

    this.$groupSelector.on('change', () => {
      const value = assert(this.$groupSelector.val());

      this.$groupId.val(value);
      groupId = valToString(value);

      this.validateSelection({ docId, groupId, linked });
    });

    this.$docSelector.on('change', () => {
      const value = valToString(assert(this.$docSelector.val()));

      this.$docId.val(value);
      docId = value;
      this.validateSelection({ docId, groupId, linked });
    });
  }

  get emailRadio(): HTMLInputElement {
    return findEl(this.element, 'input', '#email');
  }

  get linkRadio(): HTMLInputElement {
    return findEl(this.element, 'input', '#link');
  }

  get $groupSelector(): JQuery {
    return $('#group_selector');
  }

  get $groupSelection(): JQuery {
    return $('#group-selection');
  }

  get $emailConfirmMessage(): JQuery {
    return $('#email-confirm-message');
  }

  get $createCampaign(): JQuery {
    return $('#create-campaign');
  }

  get $docId(): JQuery {
    return $('#doc_id');
  }

  get $groupId(): JQuery {
    return $('#group_id');
  }

  get $docSelector(): JQuery {
    return $('#doc_selector');
  }

  validateSelection(
    { docId, linked, groupId }:
    { docId: string; linked: boolean; groupId: string },
  ): void {
    if (docId && (linked || groupId)) {
      this.$createCampaign.show();
    } else {
      this.$createCampaign.hide();
    }
  }
}

export default NewCampaignController;
